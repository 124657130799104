import i18n from 'src/i18n';
import { NFT } from 'src/business/entities/NFT';
import { isMobile } from 'react-device-detect';

interface Props {
    nft: NFT;
    setShowViewer: (value: boolean) => void;
}



const NftInformationConfirmation = ({ nft, setShowViewer }: Props) => {
	return <div
		className="card p-0 mt-3 mx-auto"
		style={{
            backgroundImage: 'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
			width: `${isMobile ? '360px' : '100%'}`,
			overflow: 'hidden',
		}}
	>
		<div className="d-flex h-100 flex-wrap w-100">
			<div
				className="card p-0 d-flex justify-content-center align-items-center"
				style={{
					overflow: 'hidden',
					width: '40%',
					minWidth: `${isMobile ? '360px' : ''}`,
					maxHeight: `${isMobile ? '360px' : ''}`,
				}}
				onClick={() => setShowViewer(true)}
			>
				<img
					style={{
						objectFit: 'cover',
						minHeight: '100%',
						minWidth: '100%',
					}}
					src={nft.image} />
				<div
					className="card-inner d-flex align-items-center justify-content-center"
					style={{
						height: '100%',
						width: '100%',
						position: 'absolute',
						background: 'rgba(0,0,0,0.4)',
						cursor: 'pointer',
					}}
				>
					<i className="fas fa-eye fa-lg"></i>
				</div>
			</div>
			<div style={{ overflow: 'hidden', width: '60%' }}>
				<div style={{ marginLeft: 20 }}>
					<h3 >{nft.name}</h3>
					<h5 >
						{i18n.t<string>('payment.description').toString()} :{' '}
						<p
							className="w-75"
							style={{
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}
						>
							{' '}
							{nft.description}
						</p>
					</h5>
					<h5>
						{i18n.t<string>('nft_marketplace_details.address').toString()}{' '}
						: <p>{' '}{nft.token_address}</p>
					</h5>
					<h5>
						{i18n.t<string>('nft_marketplace_details.tokenId').toString()}{' '}
						: {Number(nft.token_id)-3500}
					</h5>
					<h5>
						{i18n.t<string>('nft_marketplace_details.network').toString()}{' '}
						:  {nft.status}
					</h5>
					<h5>
						{i18n.t<string>('payment.price').toString()} :{' '}
							{nft.priceCan} $CAD
							{/* {' ( '}
							<img
								style={{
									width: '25px',
									height: '25px',
									objectFit: 'contain',
								}}
								src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png" />
							{nft.price + 'ETH )'} */}
					</h5>
				</div>
			</div>
		</div>
	</div>;
}

    export default NftInformationConfirmation;

import i18n from "src/i18n";
import { getShortAdress } from "src/business/controller/logic/ShortWalletAdress";
import Wallet from "src/business/entities/Wallet";

interface Props {
    wallet: Wallet;
}

const ConfirmWallet = ({ wallet }: Props) => {
	return (
		<div
			className="card pb-auto w-100 h-100 d-flex m-2"
			style={{
				minWidth: '360px',
				maxWidth: '48%',
				height: '100%',
				backgroundImage:
					'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
			}}
		>
			<div className="card-title">
				<div className="p-0">
					<h3 className="mt-0">
						{i18n.t<string>('profil.tab_2.wallet').toString()}
					</h3>
				</div>
			</div>
			<div className="row justify-content-between align-items-center h-100">
				<div className="col">
					<p
						style={{
							margin: '1.5rem 0',
						}}
					>
						<>
							<img
								className="pr-2"
								style={{
									height: '40px',
								}}
								src={
									wallet.type === 'MetaMask'
										? 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
										: './img/tokies_wallet.png'
								}
							></img>
							{wallet.type}
						</>
					</p>
				</div>
				<div className="col">
					<h5>
						<>
							{wallet.solde} {i18n.t<string>('profil.tab_2.currency')}
						</>
					</h5>
				</div>
				<div style={{ color: '#28a745' }}></div>
			</div>
			<div className="row justify-content-between align-items-center h-100">
				<div className="col">{getShortAdress(wallet.publicKey)}</div>
			</div>
		</div>
	);
}

export default ConfirmWallet;
import { useEffect, useState } from "react";
import { storageService, walletService } from "src/ApplicationContext";
import Wallet from 'src/business/entities/Wallet';
import TokiesService from "src/service/TokiesService";
import { PaymentMethod } from "src/business/entities/PaymentMethod";
import { NFT } from "src/business/entities/NFT";

export interface SelectPaymentProps {
	history: {
		push: Function;
	};
	location: {
		key: string;
		pathname: string;
		state: {
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
	};
}

export function SelectPaymentWalletUIController(props:SelectPaymentProps) {
	const [loading, setLoading] = useState<boolean>(true);
	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [connectedWallet, setConnectedWallet] = useState<Wallet>();

	const getUserWallets = async () =>  {
		const userId = storageService.get_user_id();
		const wallets = await walletService.getWalletByUser(+userId);

		for (const wallet of wallets) {
			const solde = await walletService.getSolde(wallet.publicKey);
			wallet.solde = solde;
		}
		
		const connectedWallet = wallets.find((wallet) => wallet.connected);

		if (wallets.length > 0) {
			setConnectedWallet(connectedWallet);
			setWallets(wallets);
			setLoading(false);
		} else {
			createCustodialWallet(userId);
		}
	};

    const createCustodialWallet = async (userId:string) => {
		const user = await TokiesService.getUserById(userId);
		if (user && user.email) {
			const newWallet = await walletService.createCustodialWallet(
				user.id,
				user.email
			);
			storageService.set_wallet_id(newWallet.id.toString());
			storageService.set_wallet_address(newWallet.publicKey);
			setConnectedWallet(newWallet);
			setWallets([newWallet]);
			goToAdress(newWallet);
			setLoading(false);
		}
	}

	const goToAdress = async (wallet: Wallet) => {
        const nft = props.location.state?.nft;
        const paymentMethod = props.location.state?.paymentMethod;

		props.history.push('/paymentConfirmation', {
			// address,
			wallet,
			nft,
			paymentMethod,
		});
		// props.history.push('/address', {
		// 	wallet,
		// 	nft,
		// 	paymentMethod,
		// });
	};

	const onChooseWallet = (wallet: Wallet) => {
		goToAdress(wallet);
	}
	
	useEffect(() => {
		getUserWallets();
	}, []);

	return { loading, wallets, connectedWallet, onChooseWallet}
}
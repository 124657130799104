import axios, { AxiosRequestConfig } from "axios";
import { storageService } from "src/ApplicationContext";
import { applicationConfiguration } from "src/env/ApplicationConfiguration";
import { getBackEndUrl } from "src/env/SaasEnvironnement";
import NftMetadata from "src/business/entities/NftMetadata";

class CollectionService {
  marketId = applicationConfiguration.marketId;
  backendURL: string = getBackEndUrl();

  async getCollection() {
    const token = storageService.get_access_token();

    const config: AxiosRequestConfig = {
      method: "get",
      url: `${this.backendURL}/Mint/collection/${this.marketId}`,
      headers: {
        Authorization:
          `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);

      return response.data;
    } catch(e) {
      console.log(e);
    }

    return null;

  }

  async createNFTs(nfts: NftMetadata[]) {
    const token = storageService.get_access_token();
    const files = nfts.map((nft) => nft.image);

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      await axios.post(
        `${this.backendURL}/Mint/images/${this.marketId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

    } catch (e) {
      console.log(e);
      window.alert("Can't create NFTs");
    }

    const metadatas = nfts.map((nft) => ({
      name: nft.name,
      price: Number.parseFloat(nft.price),
      qte: nft.qte,
      imagePath: nft.image.name,
      description: nft.description,
    }));

    const body = {
      listMetadata: metadatas,
    };

    try {
      await axios.post(
        `${this.backendURL}/Mint/mint/${this.marketId}/mainnet`,
        body,
        {
          headers: {
            Authorization: `Bearer ${storageService.get_access_token()}`,
          },
        }
      );
    } catch (e) {
      window.alert("Can't create NFTs");
    }
  }
}

export default CollectionService;

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ChangePasswordUIController } from 'src/business/controller/ui/ChangePasswordUIController';

const ChangePassword = (props: RouteComponentProps) => {
	const {
		email,
		setConfirmPassword,
		setEmail,
		emailvalidationError,
		oldPassword,
		setOldPassword,
		newPassword,
		setNewPassword,
		confirmPassword,
		validationError,
		changePasswordSubmit,
		t,
	} = ChangePasswordUIController();

	const mt = isMobile ? 50 : 0;
	return (
		<section style={{ marginTop: mt }} className="author-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-8 col-lg-7">
						{/* Intro */}
						<div className="intro text-center">
							<span>{t<string>('password.messagePreHeading')}</span>
							<h3 style={{ color: 'black' }} className="mt-3 mb-0">
								{t<string>('password.messageHeading')}
							</h3>
						</div>
						{/* Item Form */}
						<form className="item-form card no-hover">
							<div className="row">
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="email"
											className="form-control"
											name="email"
											placeholder={t<string>('password.messageEmail')}
											required={true}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
										<span className="text-danger">{emailvalidationError}</span>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="password"
											className="form-control"
											name="oldPassword"
											placeholder={t<string>(
												'password.messagePreviousPassword'
											)}
											required={true}
											value={oldPassword}
											onChange={(e) => setOldPassword(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="password"
											className="form-control"
											name="newPassword"
											placeholder={t<string>('password.messageNewPassword')}
											required={true}
											value={newPassword}
											onChange={(e) => setNewPassword(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="password"
											className="form-control"
											name="confirmPassword"
											placeholder={t<string>('password.messageConfirmPassword')}
											required={true}
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
										/>
									</div>
								</div>
								<span className="text-danger text-center">
									{validationError}
								</span>
								<div className="col-12">
									<button
										className="btn w-100 mt-3 mt-sm-4"
										type="submit"
										onClick={(e) =>
											changePasswordSubmit(
												e,
												email,
												oldPassword,
												newPassword,
												confirmPassword,
												props
											)
										}
									>
										{t<string>('password.messageChangePassword')}{' '}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default withRouter(ChangePassword);

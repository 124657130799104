import { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storageService } from 'src/ApplicationContext';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import LoginModal from 'src/presentation/components/Modals/LoginModal/LoginModal';
import RegisterModal from 'src/presentation/components/Modals/RegisterModal/RegisterModal';
import SoldeETH from 'src/presentation/components/SoldeETH/SoldeETH';
import ForgotPasswordModal from 'src/presentation/components/Modal/ForgotPasswordModal';
import { useAdminContext } from 'src/context/useAdminContext';
import { useMarketContext } from 'src/context/useMarketContext';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import { useTranslation } from 'react-i18next';
import MessageModal from '../Modals/LoginModal/MessageModal';
export interface ModalMenuProps extends RouteComponentProps  {
}

const ModalMenu = (props:ModalMenuProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [showLogin, setShowLogin] = useState(false);
	const [showRegister, setShowRegister] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const userLogicController = new UserLogicController();

	const { isAdmin } = useAdminContext();
	const { marketId } = useMarketContext();

	return (
		<div id="menu" className="modal fade p-0">
			<div className="modal-dialog dialog-animated">
				<div className="modal-content h-100">
					<div className="modal-header" data-dismiss="modal">
						<a
							className="d-flex justify-content-center align-item-center"
							style={{
								cursor: 'pointer',
								fontSize: '1.5rem',
								fontWeight: 600,
							}}
							onClick={() => {
								if (!storageService.get_connexion()) {
									setShowMessage(true);
								} else {
									history.push('/profil');
								}
							}}
						>
							{' '}
							<i
								style={{ color: 'var(--menuBurgerColor)' }}
								className="icon-user mr-md-2"
							/>{' '}
						</a>

						<i className="far fa-times-circle icon-close" />
					</div>
					<div className="menu modal-body">
						<div className="row w-100">
							<ul className="navbar-nav items">
								<li className="nav-item">
									<a
										href="/marketplace"
										style={{ color: 'var(--menuBurgerColor)' }}
										className="nav-link"
									>
										{t<string>('header.boutique')}
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/contact"
										className="nav-link"
										referrerPolicy="origin"
										style={{ color: 'var(--menuBurgerColor)' }}
									>
										{t<string>('header.contact')}
									</a>
								</li>
								{isAdmin && marketId === applicationConfiguration.marketId && (
							<li className="nav-item dropdown">
								<a className="nav-link">
									{t('header.admin').toString()}
									<i className="ml-2 fas fa-angle-down" />
								</a>
								<ul className="dropdown-menu">
									<li className="nav-item">
										<a 
										className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:0})
											}}
										href="/zone-admin" >
												{t<string>('zone_admin.tableaux.title')}
										</a>

									</li>
								<a className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:1})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.tab_3.title')}
								</a>
								{/* <a className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:2})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.entente.title')}
								</a> */}
								{/* <a className="nav-link"  
										onClick={()=>{
												props.history.push("/zone-admin",{tab:3})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.gallerie.title')}
								</a>
								<a className="nav-link"  
										onClick={()=>{
												props.history.push("/zone-admin",{tab:4})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.artistProfile.title')}
								</a> */}
								</ul>
							</li>
						)}
								{storageService.get_connexion() && (
									<li className="nav-item dropdown">
										{/* <a href="#" className='nav-link dropdown'>Balance <i className="fas fa-angle-down ml-1" /></a> */}
										<a
											className="nav-link"
											style={{ color: 'var(--menuBurgerColor)' }}
										>
											{' '}
											{t<string>('header.balance')}{' '}
											<i className="ml-2 fas fa-angle-down" />
										</a>
										<ul className="dropdown-menu">
											<li className="mx-2 nav-item">
												<div className="nav-link">
													<SoldeETH />{' '}
												</div>
											</li>
											{storageService.get_wallet_address() !== 'null' ? (
												<li className="nav-item">
													<a href="/deposit" className="nav-link">
														{t<string>('header.depot')}
													</a>
												</li>
											) : (
												<a className="btn my-2 mx-4" href="/profil">
													{' '}
													{t<string>('header.balance.errorWallet')}
												</a>
											)}
										</ul>
									</li>
								)}
							</ul>
						</div>
					</div>
					<div className="menu container">
						<div className="row w-100 d-flex justify-content-center px-4 pb-4">
							<a
								className="text-secondary text-center"
								style={{ cursor: 'pointer' }}
								onClick={userLogicController.changeLanguage}
							>
								{sessionStorage.getItem('i18nLng')?.includes('fr')
									? 'English'
									: 'Français'}
							</a>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
			<ForgotPasswordModal
				show={showForgotPassword}
				onShowForgotPasswordModal={setShowForgotPassword}
			/>
			<LoginModal
				onShowForgotPasswordModal={() => {
					setShowForgotPassword(true);
				}}
				show={showLogin}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
				returnProfil={true}
				{...props}		/>
			<MessageModal
				{...props}
				show={showMessage}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				onShowMessage={(value) => {
					setShowMessage(value);
				}}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
			/>
			<RegisterModal
				show={showRegister}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
			></RegisterModal>
		</div>
	);
};

export default withRouter(ModalMenu);

import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import AdressForm from 'src/presentation/components/AdressForm';
import { NFT } from 'src/business/entities/NFT';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import Wallet from 'src/business/entities/Wallet';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';

export interface UserAdressProps extends RouteComponentProps  {
	location: {
		key: string;
		pathname: string;
		state: {
			wallet: Wallet;
			nft: NFT;
			paymentMethod: PaymentMethod;
		};
		search: string,
		hash: string
	};
	didntChange:boolean
}

const UserAdressScreen:FC<UserAdressProps> = (props: UserAdressProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<AdressForm {...props} />
			<Scrollup />
			<ModalMenu />
		</div>
	);
};

export default UserAdressScreen;

import { useEffect, useState } from 'react';
import {
	immutableProvider,
	storageService,
	userService,
	walletService,
} from 'src/ApplicationContext';
import User from 'src/business/entities/User';
import Wallet from 'src/business/entities/Wallet';

export function AuthorWalletUIController() {
	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [connectedWallet, setConnectedWallet] = useState<Wallet>();
	const [user, setUser] = useState<User>();
	const [loading, setLoading] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false);
	const [walletToDelete, setWalletToDelete] = useState<number | null>(null);

	useEffect(() => {
		setLoading(true);
		getUser();
	}, []);

	const getWallets = async (userId: number) => {
		let wallets: Wallet[] = [];
		if (userId) {
			wallets = await walletService.getWalletByUser(userId);
		}

		for (const wallet of wallets) {
			const solde = await walletService.getSolde(wallet.publicKey);
			wallet.solde = solde;
		}

		const connectedWallet = wallets.find((wallet) => wallet.connected);

		if (connectedWallet) {
			storageService.set_wallet_address(connectedWallet.publicKey);
			setWallets(wallets);
			setConnectedWallet(connectedWallet);
		}
		setLoading(false);
	};

	const changeConnectedWallet = async (walletId: number, user: User) => {
		await immutableProvider.init();
		if (user) {
			const wallets = await walletService.changeConnectedWallet(
				user.id,
				walletId
			);
			const connectedWallet = wallets.find((wallet) => wallet.connected);

			for (const wallet of wallets) {
				const solde = await walletService.getSolde(wallet.publicKey);
				wallet.solde = solde;
			}

			if (connectedWallet) {
				storageService.set_wallet_address(connectedWallet.publicKey);
				storageService.set_connected_wallet_type(connectedWallet.type)
				setWallets(wallets);
				setConnectedWallet(connectedWallet);

				immutableProvider.connectMetamask();
			}
		}
	};

	const getUser = async () => {
		const user: User = await userService.getUserByEmail(
			storageService.get_user_email(),
		);
		
		if (user) {
			setUser(user);
			await getWallets(user?.id);
		}
	};

	const onConfirmWalletDeletion = async () => {
		if (user && walletToDelete) {
			const userId = user.id;
			const newWallets = await walletService.deleteWallet(
				userId,
				walletToDelete
			);
			const newConnectedWallet = newWallets.find(
				(wallet: any) => wallet.connected
			);

			for (const wallet of newWallets) {
				const solde = await walletService.getSolde(wallet.publicKey);
				wallet.solde = solde;
			}
			setWallets(newWallets);
			setConnectedWallet(newConnectedWallet);
			setShowDeleteConfirmationModal(false);
			setWalletToDelete(null);

			if (newConnectedWallet) {
				storageService.set_wallet_address(newConnectedWallet.publicKey);
			} else {
				storageService.remove_wallet_information();
			}
		}
	};

	const removeWallet = (walletId: number) => {
		setShowDeleteConfirmationModal(true);
		setWalletToDelete(walletId);
	};

	return {
		getUser,
		setConnectedWallet,
		setWalletToDelete,
		removeWallet,
		changeConnectedWallet,
		onConfirmWalletDeletion,
		loading,
		setLoading,
		setShowDeleteConfirmationModal,
		showDeleteConfirmationModal,
		connectedWallet,
		wallets,
		user,
	};
}

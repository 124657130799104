import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyInput from 'react-currency-input-field';
import NumericInput from 'react-numeric-input';
import NftMetadata from 'src/business/entities/NftMetadata';
import i18n from 'src/i18n';

interface Props {
	show: boolean;
	handleClose: () => void;
	confirmClose: (nftMetadata: NftMetadata) => void;
	metadata: NftMetadata;
}

const EditNFTModal = ({ show, handleClose, confirmClose, metadata }: Props) => {
	const [displayImage, setDisplayImage] = useState<string>(metadata?.url);

	const [image, setImage] = useState<File>(metadata.image);
	const [name, setName] = useState<string>(metadata.name);
	const [description, setDescription] = useState<string>(metadata.description);
	const [price, setPrice] = useState<string>(metadata.price);
	const [quantity, setQuantity] = useState<number>(metadata.qte);

	const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const file = files[0];
		const image = URL.createObjectURL(file);

		setDisplayImage(image);
		setImage(file);
	};

	const editNFT = () => {
		const nftMetada: NftMetadata = {
			image,
			name,
			description,
			price,
			url: displayImage,
			id: metadata.id,
			qte: quantity,
		};

		confirmClose(nftMetada);
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title> {i18n.t<string>('zone_admin.mintage.edit')} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<form>
						<div>
							<h5> {i18n.t<string>('zone_admin.mintage.nftPicture')} </h5>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-around',
								}}
							>
								<div>
									<label htmlFor="edit-file-upload" className="custom-file-upload">
										<div className="btn btn-bordered-white btn-smaller">
											{i18n.t<string>('zone_admin.mintage.select')}
										</div>
									</label>
									<input
										onChange={handlePictureChange}
										accept="image/*"
										style={{ display: 'none' }}
										id="edit-file-upload"
										type="file"
									/>
								</div>

								{!!displayImage && (
									<div>
										<img
											style={{ height: 200, marginTop: -100, maxWidth: 200 }}
											className="preview"
											src={displayImage}
											alt=""
										/>
									</div>
								)}
							</div>
						</div>
						<div>
							<h5> {i18n.t<string>('zone_admin.mintage.name')} </h5>
							<input
								style={{
									borderRadius: 10,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
								type="text"
								className="w-50"
							/>
						</div>
						<div>
							<h5>{i18n.t<string>('zone_admin.mintage.description')}</h5>
							<textarea
								style={{
									borderRadius: 10,
								}}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>

						<div className="row">
							<div style={{ width: '50%' }}>
								<h5> {i18n.t<string>('zone_admin.mintage.price')} </h5>
								<CurrencyInput
									style={{
										borderRadius: 10,
									}}
									prefix="$"
									id="input-price"
									name="input-price"
									className="w-100"
									decimalsLimit={2}
									value={price}
									onValueChange={(value, _) => setPrice(value)}
								/>
							</div>
							<div style={{ width: '10%' }}> </div>
							<div style={{ width: '40%' }}>
								<h5> {i18n.t<string>('zone_admin.mintage.quantity')} </h5>
								<NumericInput
									onChange={(value) => setQuantity(value)}
									style={{
										input: {
											borderRadius: 10,
										},
									}}
									min={1}
									max={1000}
									value={quantity}
									step={1}
								/>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: 'space-around' }}>
				<Button variant="secondary" onClick={handleClose}>
					{i18n.t<string>('zone_admin.mintage.cancel')}
				</Button>
				<Button variant="primary" onClick={editNFT}>
					{i18n.t<string>('zone_admin.mintage.confirm')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditNFTModal;

import { Modal } from "react-bootstrap";
import i18n from "src/i18n";

interface Props {
	setShowDeleteConfirmationModal: (value: boolean) => void;
	setWalletToDelete: (value: number) => void;
	showDeleteConfirmationModal: boolean;
    onConfirmWalletDeletion:()=>void
}
const DeleteWalletModal = (props: Props) => {
    return(
    <Modal
        // size="md"
        contentClassName="rounded"
        centered
        show={props.showDeleteConfirmationModal}
        onHide={() =>{
                props.setShowDeleteConfirmationModal(false)
                props.setWalletToDelete(null)
            }
        }
        scrollable={true}
    >
        <Modal.Header>
            <Modal.Title className="ml-4">
                {i18n.t<string>('profil.wallet.deleteConfirmation')}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
            <button
                onClick={props.onConfirmWalletDeletion}
                className="btn w-100 m-4 mt-3 mt-sm-4"
                type="submit"
            >
                {i18n.t<string>('profil.wallet.yes')}
            </button>
            <button
                onClick={() =>{
                    props.setShowDeleteConfirmationModal(false)
                    props.setWalletToDelete(null)

                }
                    
                }
                className="btn m-4  w-100 mt-3 mt-sm-4"
                type="submit"
            >
                {i18n.t<string>('profil.wallet.no')}
            </button>
        </Modal.Body>
    </Modal>
);}
export default DeleteWalletModal;
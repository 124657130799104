import { RouteComponentProps } from 'react-router';
import { collectionService, immutableProvider } from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';

interface Props extends RouteComponentProps {}

export class ExploreLogicController {
	async getData(page_size: number): Promise<NFTAsset[]> {
		const collection = await collectionService.getCollection();
		if (collection?.address) {
			const collectionAddress = collection.address;
			const allAssets=immutableProvider.getAssets(
				applicationConfiguration.adminAddress,
				collectionAddress,
				page_size
			);
			let newAssets:NFTAsset[]=new Array() as NFTAsset[]
			(await allAssets).map((asset:NFTAsset)=>{
				if(asset.token_id>=3500){
					newAssets.push(asset)
				}
			})
			return newAssets
		}

		return [];
	}

    getrandomNumberInRange = (min:number, max:number) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };
	getFormattedPrice(price: number): string {
		const priceText = price.toString();
		const decimalIndex = priceText.indexOf('.');
		return priceText.substring(0, decimalIndex + 7);
	}


	goToRandomNft = (items: NFTAsset[], props: Props) => {
		const itemRandom = items[this.getrandomNumberInRange(0,items.length)]
		try {
			props.history.push(
				`marketplace/item-details/${itemRandom.token_address}/${itemRandom.token_id}`,
				{ image_url: itemRandom.image_url }
			);
			
		} catch (error) {
			this.goToRandomNft(items,props)
		}
	};

	goToNft = (item: NFTAsset, props: Props) => {
		props.history.push(
			`marketplace/item-details/${item.token_address}/${item.token_id}`,
			{ image_url: item.image_url }
		);
	};
	getProfit =  (item: NFTAsset) =>{
		let profit= item.metadata.price
		item.fees?.map((fee)=>{
			profit -= fee.percentage*item.metadata.price*0.01
		})
		return profit
	}
}

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { transactionService } from "src/ApplicationContext";
import NFTAsset from "src/business/entities/NFTAsset";
import NftTransactionTracking from "src/business/entities/NftTransactionTracking";
import { NFTMetadaProps } from "src/presentation/components/NFTMetadata";
import {
	collectionService,
} from 'src/ApplicationContext';


export function NFTMetadataUIController(props: NFTMetadaProps) {
	const [collection, setCollection] = useState(null);
	const [asset, setAsset] = useState<NFTAsset>();
	const [transactions, setTransactions] = useState<NftTransactionTracking[]>([]);
	const { t } = useTranslation();


	const getCollection = async () => {
		const collectionData = await collectionService.getCollection();
		setCollection(collectionData);
	};
	useEffect(() => {
		getTransactions();
		getCollection();
	}, []);

	const getTransactions = async () => {
		const propsAsset = props.location.state.asset;
		try {
			const transactions = await transactionService.getTransactionsForToken(
				propsAsset.token_id
			);

			const immutableTransactions =
				await transactionService.getImmutableTransaction(propsAsset);

			for (const transaction of transactions) {
				const imxOrder = immutableTransactions.find(
					(order) => order.order_id === transaction.orderId
				);
				transaction.transactionId = imxOrder?.sell.data.id;
			}

			setTransactions(transactions);
			setAsset(propsAsset);
		} catch {
			setTransactions([]);
			setAsset(propsAsset);
		}
	};

    const handlePurchasedDate = (asset: NFTAsset): string => {
		const purchase = asset.updated_at;

		const purchasedDate = new Date(purchase);

		const purchasedTime = purchasedDate
			.toDateString()
			.split(' ')
			.reduce((previousValue, currentValue, idx) => {
				if (idx === 0) {
					return '';
				}

				previousValue += `${currentValue} `;

				return previousValue;
			}, '');

		return purchasedTime;
	};

	return { handlePurchasedDate, getTransactions, transactions, collection, asset, t }

}
import axios from 'axios';
import { immutableProvider } from 'src/ApplicationContext';
import { getBackEndUrl } from 'src/env/SaasEnvironnement';
import NftTransactionTracking from 'src/business/entities/NftTransactionTracking';
import { storageService } from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';

interface ImmutableOrder {
	amount_sold: number;
	buy: {};
	expiration_timestamp: number;
	order_id: number;
	sell: {
		data: {
			id: string;
			token_id: string;
			token_address: string;
			quantity_with_fees: string;
		};
		type: string;
	};
	status: string;
	timestamp: string;
	updated_timestamp: string;
	user: string;
}

class TransactionService {
	backendURL: string = getBackEndUrl();

	async getTransactionsForToken(tokenId: number): Promise<NftTransactionTracking[]> {
		try {
			const result = await axios.get(
				`${this.backendURL}/SellOrder/transaction/${tokenId}`,
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			);

			return result.data as NftTransactionTracking[] || [];
		} catch {
			return [];
		}
	}

	async getTransactions() {
		return await axios.get(`${this.backendURL}/Transaction`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	async getImmutableTransaction(asset: NFTAsset): Promise<ImmutableOrder[]> {
		try {
			return immutableProvider.getOrders({
				sell_token_address: asset.token_address,
				sell_token_id: asset.token_id,
				status: 'filled',
			});
		} catch {
			return [];
		}
	}
}

export default new TransactionService();

import Masonry from '@mui/lab/Masonry';
import { isMobile } from 'react-device-detect';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { RouteComponentProps, withRouter } from 'react-router';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { ExploreAutoSellOrdersUIController } from 'src/business/controller/ui/ExploreAutoSellOrdersUIController';
import { ExploreLogicController } from 'src/business/controller/logic/ExploreLogicController';

interface Props extends RouteComponentProps {}

const ExploreAutoSellOrders = (props: Props) => {
	const exploreLogicController = new ExploreLogicController();

	const { loading, nftAssets, getFormattedPrice, t } =
		ExploreAutoSellOrdersUIController();

	if (loading) {
		return (
			<section className="explore-area mt-0 pt-0">
				<div className="container">
					<div className="d-flex justify-content-center mt-5">
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="explore-area mt-0 pt-0">
			<div className="container">
				{!isMobile ? (
					<>
						<Masonry columns={3} spacing={2}>
							{nftAssets?.slice(-1).map((item: any, idx) => {
								const price = item?.metadata?.price;
								if (item.metadata) {
									return (
										<div key={`edth_${idx}`} data-groups={item.group}>
											<div className="card p-0" style={{ height: '100%' }}>
												<div
													style={{
														width: '100%',
														position: 'relative',
													}}
												>
													<a
														onClick={(e) => {
															e.preventDefault();
															exploreLogicController.goToRandomNft(nftAssets, props);
														}}
														href="#"
													>
														<img
															className="card-img-top"
															style={{
																minHeight: '100%',
																minWidth: '100%',
																objectFit: 'cover',
															}}
															src={
																item?.image_url ??
																'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
															}
															alt=""
														/>
													</a>
												</div>
												<div className="card-caption p-0">
													<div className="card-body p-4">
														<a
															onClick={(e) => {
																e.preventDefault();
																exploreLogicController.goToRandomNft(item, props);
															}}
															href="#"
														>
															<h5 className="mb-0">
																{item?.name ? item?.name : 'null'}
															</h5>
														</a>
														<div className="seller d-flex align-items-center my-3">
															<span>{t<string>('explore.owner')}</span>
															<a>
																<h6 className="ml-2 mb-0">
																	{applicationConfiguration.saasName}
																</h6>
															</a>
														</div>
														<div className="card-bottom d-flex justify-content-between">
															<span className="text-center">
																{price
																	? '$' + getFormattedPrice(price) + ' CAD'
																	: 'No price'}
															</span>
															<span className="ml-2 text-center">
																{t<string>('explore.available')}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								}
							})}
						</Masonry>
					</>
				) : (
					<>
							{nftAssets?.slice(-1).map((item: any, idx) => {
								const price = item?.metadata?.price;
								if (item.metadata) {
									return (
										<div key={`edth_${idx}`} data-groups={item.group}>
											<div className="card p-0" style={{ height: '100%' }}>
												<div
													style={{
														width: '100%',
														position: 'relative',
													}}
												>
													<a
														onClick={(e) => {
															e.preventDefault();
															exploreLogicController.goToRandomNft(nftAssets, props);
														}}
														href="#"
													>
														<img
															className="card-img-top"
															style={{
																minHeight: '100%',
																minWidth: '100%',
																objectFit: 'cover',
															}}
															src={
																item?.image_url ??
																'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
															}
															alt=""
														/>
													</a>
												</div>
												<div className="card-caption p-0">
													<div className="card-body p-4">
														<a
															onClick={(e) => {
																e.preventDefault();
																exploreLogicController.goToRandomNft(item, props);
															}}
															href="#"
														>
															<h5 className="mb-0">
																{item?.name ? item?.name : 'null'}
															</h5>
														</a>
														<div className="seller d-flex align-items-center my-3">
															<span>{t<string>('explore.owner')}</span>
															<a>
																<h6 className="ml-2 mb-0">
																	{applicationConfiguration.saasName}
																</h6>
															</a>
														</div>
														<div className="card-bottom d-flex justify-content-between">
															<span className="text-center">
																{price
																	? '$' + getFormattedPrice(price) + ' CAD'
																	: 'No price'}
															</span>
															<span className="ml-2 text-center">
																{t<string>('explore.available')}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								}
							})}
					</>
				)}
			</div>
		</section>
	);
};

export default withRouter(ExploreAutoSellOrders);

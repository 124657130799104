import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Divider } from '@mui/material';
import { EnabledMFAController } from './EnabledMfaController';

export interface EnabledMFAModalProps {
	show: boolean;
	handleClose: () => void;
}

const EnableMFAModal = (props: EnabledMFAModalProps) => {
	const {
		code,
		enabled_mfa,
		confirm_enabled_mfa,
		password,
		t,
		hasSendFirstConfirmation,
        setCode,
        wrongMfaCodeError,
        setPassword,
        onClick,
        passwordValidationError
	} = EnabledMFAController(props);

	return (
		<Modal
			show={props.show}
			onHide={() => props.handleClose()}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
				<Modal.Title className="ml-4">
					{t<string>('profil.enabledMFA')}
				</Modal.Title>
				<button
					onClick={() => props.handleClose()}
					className="btn"
					style={{ padding: 16, color: "var(--primary-p-color)" }}
				>
					{t<string>('close')}
				</button>
			</Modal.Header>
			<Modal.Body className="pt-0">
				{hasSendFirstConfirmation ? (
					<div>
						<Form noValidate>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div>
									{' '}
									<span>{t<string>('login.mfaEnterCode')}</span>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginTop: 20,
									}}
								>
									<div>
										<input
											value={code}
											onChange={(e) => setCode(e.target.value)}
										/>
									</div>
									<div>
										<button
											onClick={async (e) => confirm_enabled_mfa(e)}
											className="btn"
											style={{ padding: 16, color: "var(--primary-p-color)", marginLeft: 20 }}
										>
											{t<string>('login.mfaConfirm')}
										</button>
									</div>
								</div>
								<div>
									<span className="text-danger">{wrongMfaCodeError}</span>
								</div>
							</div>

							<div
								className="mt-5"
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<span className="powerLink" onClick={onClick}>
									{t<string>('header.powered')}
								</span>
							</div>
						</Form>
					</div>
				) : (
					<div>
						<Form noValidate>
							<div className="row">
								<div className="col-12">
									<Divider
										light
										style={{
											height: 1,
											backgroundColor: 'var(--primary-color)',
											marginTop: 30,
										}}
									/>
								</div>

								<div className="col-12">
									<div className="form-group mt-3">
										<label>{t<string>('login.messagePassword')}</label>
										<input
											type="password"
											className="form-control mt-3"
											name="password"
											required={true}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<span className="text-danger">
											{passwordValidationError}
										</span>
									</div>
								</div>

								<div className="col-12">
									<button
										className="btn w-100 mt-3 mt-sm-4"
										type="submit"
										style={{ color: "var(--primary-p-color)" }}
										onClick={(
											e: React.MouseEvent<HTMLButtonElement, MouseEvent>
										) => enabled_mfa(e)}
									>
										{t<string>('login.mfaConfirm')}
									</button>
								</div>
							</div>

							<div
								className="mt-5"
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<span className="powerLink" onClick={onClick}>
									{t<string>('header.powered')}
								</span>
							</div>
						</Form>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};
export default EnableMFAModal;

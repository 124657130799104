import 'react-tabs/style/react-tabs.css';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import i18n from 'src/i18n';
import Receipts from 'src/presentation/components/Receipts/Receipts';
import { AuthorReceiptUIController } from 'src/business/controller/ui/AuthorReceiptUIController'; 


const AuthorReceipt = ()=> {
	const {loading, hasWalletAddress, markets, transactions} = AuthorReceiptUIController();

	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center mt-4">
				<DnaLoader />
			</div>
		);
	}
	return (
		<div>
			{hasWalletAddress ? (
				<Receipts transactions={transactions} markets={markets} />
			) : (
			<div>
				<h3 className="card text-center">{i18n.t<string>('profil.noWallet')}</h3>
			</div>
			)}
		</div>
	);
}

export default AuthorReceipt;

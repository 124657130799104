import { useEffect, useState } from 'react';
import i18n from 'src/i18n';
import NFTAsset from 'src/business/entities/NFTAsset';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { ExploreLittleUIController } from 'src/business/controller/ui/ExploreLittleUIController';
import { ExploreLogicController } from 'src/business/controller/logic/ExploreLogicController';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {}

const ExploreLittle = (props: Props) => {
	const [nftAssets, setNftAssets] = useState<NFTAsset[]>([]);
	const [loading, setLoading] = useState(false);

	const exploreUIController = new ExploreLittleUIController(
		setNftAssets,
		setLoading
	);
	const exploreLogicController = new ExploreLogicController();
	const soldout = false;

	useEffect(() => {
		setLoading(true);
		exploreUIController.getNftAssets();
	}, []);

	if (loading) {
		return (
			<section className="explore-area pb-0">
				<div className="container">
					<div className="d-flex justify-content-center">
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}
	return (
		<section className="explore-area pb-0">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="intro d-flex justify-content-between align-items-end m-0">
							<div className="intro-content">
								<span>{i18n.t<string>('marketplace.pre_heading')}</span>
								<h3 className="mt-3 mb-0">
									{i18n.t<string>('marketplace.heading')}
								</h3>
							</div>
							<div className="intro-btn">
								<a className="btn content-btn" href="/marketplace">
									{i18n.t<string>('marketplace.redirect_btn')}
								</a>
							</div>
						</div>
					</div>
				</div>
				{nftAssets ? (
					<div className="row items">
						{nftAssets.slice(-1).map((item: NFTAsset, idx) => {
							return (
								<div
									key={`edth_${idx}`}
									className="col-12 col-sm-6 col-lg-3 item explore-item justify-content-center"
									data-groups={item.group}
								>
									{item.metadata ? (
										<div className="card p-0">
											<a
												className="d-flex justify-content-center align-items-center"
												style={{
													height: '350px',
													width: '100%',
													overflow: 'hidden',
													position: 'relative',
													borderRadius: '8px',
												}}
												onClick={() =>
													exploreLogicController.goToRandomNft(nftAssets, props)
												}
											>
												<img
													className="card-img-top"
													style={{
														minHeight: '100%',
														minWidth: '100%',
														objectFit: 'cover',
													}}
													src={item.image_url}
													alt=""
												/>
											</a>
											{soldout ?? (
												<a
													onClick={() =>
														exploreLogicController.goToNft(item, props)
													}
													style={{
														position: 'absolute',
														top: '0',
														left: '0',
														height: '100%',
														maxHeight: '350px',
														maxWidth: '350px',
														width: '100%',
														overflow: 'hidden',
														opacity: '1',
														background: 'rgba(0,0,0,0.3)',
													}}
												>
													<img
														className="card-img-top"
														style={{
															width: '100%',
															objectFit: 'cover',
															height: 200,
														}}
														src={'/img/png_sold_out_53735.png'}
														alt=""
													/>
												</a>
											)}
											<div className="card-caption col-12 p-0">
												<div className="card-body p-4">
													<a
														onClick={() =>
															exploreLogicController.goToNft(item, props)
														}
													>
														<h5 style={{ color: "var(--primary-p-color)" }} className="mb-0">
															{item?.name ? item.name : 'null'}
														</h5>
													</a>
													<div
														style={{ color: "var(--primary-p-color)" }}
														className="seller d-flex align-items-center my-3"
													>
														<span>{i18n.t<string>('explore.owner')}</span>
														<a>
															<h6
																style={{ color: "var(--primary-p-color)" }}
																className="ml-2 mb-0"
															>
																{applicationConfiguration.saasName}
															</h6>
														</a>
													</div>
													<div className="card-bottom d-flex justify-content-between">
														<span style={{ color: "var(--primary-p-color)" }}>
															{item.metadata.price
																? '$' +
																  exploreUIController.getFormattedPrice(
																		item.metadata.price
																  ) +
																  ' CAD'
																: i18n.t<string>('explore.free')}
														</span>
														<span style={{ color: "var(--primary-p-color)" }}>
															{i18n.t<string>('explore.available')}
														</span>
													</div>
													<a
														style={{ color: "var(--primary-p-color)" }}
														className="btn btn-bordered-white btn-smaller mt-3"
														onClick={() =>
															exploreLogicController.goToNft(item, props)
														}
													>
														<i className="icon-handbag mr-2" />
														{'Acheter'}
													</a>
												</div>
											</div>
										</div>
									) : (
										<></>
									)}
								</div>
							);
						})}
					</div>
				) : (
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '50vh' }}
					>
						<div
							className="spinner-border"
							style={{ height: '3rem', width: '3rem' }}
							role="status"
						>
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default ExploreLittle;

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArtistProfileTabUIController } from "src/business/controller/ui/ArtistProfileTabUIController";
import CardGeneral from "src/presentation/components/Cards/CardGeneral"
import CreateArtistProfileModal from "src/presentation/components/GallerieTabs/CreateArtistProfileModal";
import DnaLoader from "src/presentation/components/loader/DNALoader";



const ArtistProfileTab = () => {
    const { artist, showModal, setShowModal, loading, artistIdNotSet, onClickArtistCreateButton}= ArtistProfileTabUIController();

	const { t } = useTranslation();	
    if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center mt-4">
				<DnaLoader />
			</div>
		);
    }


    if (artistIdNotSet){
        return <div>
            <h2>{t<string>('zone_admin.artistProfile.noProfile')}</h2>
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>{t<string>('zone_admin.artistProfile.createProfile')} </button>
            <CreateArtistProfileModal
                show={showModal}
                onShowIdleModal={(value) => {
                    setShowModal(value);
                }}
                onClickArtistCreateProfile={onClickArtistCreateButton}
            />

        </div>
    }

    return (
        artist ?
        <div>
            <CardGeneral
			index={1}
			titre={t<string>('zone_admin.artistProfile.profileCardTitle')} 
			imageUrl={{info:'round',url:'https://idsb.tmgrup.com.tr/ly/uploads/images/2022/06/13/211875.jpg'}}

			description={
				<>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.artistProfile.artistName')}<a><b>{artist.artistName}</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.artistProfile.realName')}<a><b>{artist.ownerName}</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.artistProfile.email')}<a><b>{artist.email}</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.artistProfile.phoneNumber')}<a><b>{artist.phoneNumber}</b></a>
						</span>
					</div>
					<Link className="nav-link" to={{
										pathname: "/zone-admin", 
										state:{tab:3}
										}} >
						<button className="btn btn-primary"> {t<string>('zone_admin.artistProfile.viewGalleries') }</button>
					</Link>
					<Link className="nav-link" to={{
										pathname: "/zone-admin", 
										state:{tab:2}
										}} >
						<button className="btn btn-primary"> {t<string>('zone_admin.artistProfile.viewDemands') }</button>
					</Link>
				</>
			}	
		/>
        </div>
        :
        <></>
    )
}
export default ArtistProfileTab;
import { NFTLogicController, NFTProps } from '../logic/NFTLogicController';
import TokiesService from 'src/service/TokiesService';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	collectionService,
	immutableProvider,
	storageService,
} from 'src/ApplicationContext';

export function NftMarketplaceDetailsUIController(props: NFTProps) {
	const nftLogicController = new NFTLogicController();

	const [showLogin, setShowLogin] = useState(false);
	const [showRegister, setShowRegister] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [tokenAddress] = useState(props.tokenAddress);
	const [tokenId] = useState(props.tokenId);
	const [isFree, setIsFree] = useState(false);
	const [soldOut] = useState(false);
	const [priceToCAD, setPriceToCAD] = useState(0.0);
	const [priceToETH, setPriceToETH] = useState(0.0);
	const [loading, setLoading] = useState(true);
	const [showViewer, setShowViewer] = useState(false);
	const [asset, setAsset] = useState(null);
	const [collection, setCollection] = useState(null);

	const { t } = useTranslation();

	const getCollection = async () => {
		const collectionData = await collectionService.getCollection();
		setCollection(collectionData);
	};

	useEffect(() => {
		getCollection();
		getAsset();
		window.scrollTo(0, 0);
	}, []);

	const proceedToPayment = () => {
		if (!storageService.get_connexion()) {
			setShowMessage(true);
		} else {
			nftLogicController.proceedToPayment(
				asset,
				priceToETH,
				priceToCAD,
				isFree,
				props
			);
		}
	};

	const getAsset = async () => {
		immutableProvider.init();
		const assetRequest = await immutableProvider.getAssetDetails(
			tokenAddress,
			tokenId
		);
		const conversionRateRequest: number =
			await TokiesService.getConversionRateCAD();

		setAsset(assetRequest);

		setPriceToCAD(assetRequest.metadata.price);
		setIsFree(
			assetRequest.metadata.promo === 'Free' && assetRequest.metadata.price == 0
				? true
				: false
		);

		if (isFree) {
			setPriceToETH(0);
		} else {
			setPriceToETH(assetRequest.metadata.price * conversionRateRequest);
		}

		setLoading(false);
	};

	return {
		asset,
		loading,
		proceedToPayment,
		showViewer,
		isFree,
		soldOut,
		priceToCAD,
		priceToETH,
		showLogin,
		showRegister,
		showMessage,
		setShowMessage,
		setShowLogin,
		setShowRegister,
		setShowViewer,
		tokenAddress,
		tokenId,
		collection,
		t,
	};
}

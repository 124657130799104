import i18n from "src/i18n";

interface Props {
    createCollection: () => void;
}


const NoCollection = ({ createCollection }: Props) => (
    <section className="container">
      <div>
        <div>
          <h4> {i18n.t<string>("zone_admin.mintage.noNFT")} </h4>
          <div onClick={createCollection} style={{ 'color': 'white'}} className="btn mt-4">
            {i18n.t<string>("zone_admin.mintage.createNFT")}
          </div>
        </div>
      </div>
    </section>
  );

  export default NoCollection;
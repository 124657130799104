import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { immutableProvider, storageService, walletService } from "src/ApplicationContext";
import { CreateCustodialWallet } from "src/presentation/components/Wallet/CreateTokiesWallet";
import TokiesService from "src/service/TokiesService";

interface WalletData {
	img: string;
	title: string;
	content: string;
	connect: () => void;
}

export function WalletUIController(props:RouteComponentProps) {
	const [loading, setLoading] = useState(true);
	const [showCustodial, setShowCustodial] = useState(true);
	const [showIosModal, setShowIosModal] = useState(false);

	const walletData: WalletData[]=[
		CreateCustodialWallet(() => createTokiesWallet()),
		{
			img: 'https://link.x.immutable.com/static/media/metamask-logo.45038d58.svg',
			title: 'Metamask',
			content:
				"Une extension de navigateur d'une grande souplesse. Le porte-monnaie le plus populaire du web",
			connect: () => {
				const promise = async () =>
					await immutableProvider
						.connectMetamask()
						.then(async () => saveData());
				toast.promise(promise, {
					pending: {
						render: 'Connexion...',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					success: {
						render: 'Metamask connecté !',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					error: {
						render( data:any ) {
							return `${data.message}`;
						},
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
				});
			},
		},
	]

	const walletDataMobile: WalletData[]=[
		CreateCustodialWallet(() => createTokiesWallet()),
		{
			img: 'https://link.x.immutable.com/static/media/metamask-logo.45038d58.svg',
			title: 'WalletConnect',
			content:
				'Fonctionne avec tous types de portefeuille. (Utilisez cette méthode de connexion si vous êtes sur le mobile)',
			connect: () => {
				const promise = async () => {
					await immutableProvider
						.connectMetamask(false)
						.then(async () => saveData());
				};
				toast.promise(promise, {
					pending: {
						render: 'Connexion...',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					success: {
						render: 'Wallet connecté !',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					error: {
						render(data:any) {
							return `${data.message}`;
						},
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
				});
			},
		},
	]

	const createTokiesWallet = async () => {
		const userId = storageService.get_user_id();
		const email = storageService.get_user_email();

		const custodialWallet = await walletService.createCustodialWallet(
			parseInt(userId),
			email
		);

		storageService.set_wallet_address(custodialWallet.publicKey);
		storageService.set_connection_type('TOKIES');

		props.history.push('/profil');
	};

	const saveData = async () => {
		const newWallet = {
			publicKey: storageService.get_wallet_address(),
			type: storageService.get_connection_type(),
			userId: storageService.get_user_id(),
			connected: true,
		};
		var wallet = await TokiesService.connectWallet(newWallet);
		storageService.set_wallet_id(wallet.data.id);

		props.history.push('/profil');
	}

	const showCustodialWallet = async () => {
		await immutableProvider.init();

		const userId = storageService.get_user_id();
		const hasCustodialWallet = await walletService.hasCustodialWallet(
			parseInt(userId)
		);

		const showCustodial = hasCustodialWallet ? false : true;
		setLoading(false)
		setShowIosModal(true)
		setShowCustodial(showCustodial)
	};

	useEffect(()=>{
		setLoading(true)
		showCustodialWallet()
	},[])

	return {
		loading,
		showCustodial,
		showIosModal,
		walletData,
		walletDataMobile,
		setShowIosModal
	}
}
import Header from 'src/presentation/components/Header/Header';
import Contact from 'src/presentation/components/Contact/Contact';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import { RouteComponentProps } from 'react-router';
import Footer from 'src/presentation/components/Footer/Footer';

export interface AboutScreenProps extends RouteComponentProps {}

const AboutScreen = (_props: AboutScreenProps): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<Contact />
				<Footer />
				<Scrollup />
				<ModalMenu />
			</div>
		);
}

export default AboutScreen;

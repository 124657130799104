export const getShortAdress = (walletAddress: string) => {
	return (
		walletAddress.substring(0, 5) +
		'...' +
		walletAddress.substring(walletAddress.length - 3, walletAddress.length)
	);
};

export const getMediumAddress = (walletAddress: string) => {
	return (
		walletAddress.substring(0, 8) +
		'...' +
		walletAddress.substring(walletAddress.length - 6, walletAddress.length)
	);
};

import NftTransaction from './NftTransactions';
import { Button } from 'react-bootstrap';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { isMobile } from 'react-device-detect';
import Certification from 'src/presentation/components/Certification/Certification';
import NFTAsset from 'src/business/entities/NFTAsset';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { NFTMetadataUIController } from 'src/business/controller/ui/NFTMetadataUIController';
import { AudioPlayer } from "./AudioPlayer/AudioPlayer";

export interface NFTMetadaProps {
	history: {
		push: Function;
	};
	location: {
		pathname: string;
		state: {
			asset: NFTAsset;
		};
	};
}

const NFTMetadata = (props: NFTMetadaProps): JSX.Element => {
	const { asset, transactions,collection, t, handlePurchasedDate } =
		NFTMetadataUIController(props);
		
	const audio = {
		url: "/music/Taktika - Le temps fly.mp3",
		title: "Le temps fly",
		author: "Taktika",
		thumbnail:
		  "/img/Taktika - Le temps fly.JPG",
	};
	if (!asset) {
		return (
			<section>
				<div
					className="container"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DnaLoader />
				</div>
			</section>
		);
	}

	const description = (): JSX.Element => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'wrap',
					width: isMobile ? undefined : '35%',
					marginTop: isMobile ? 20 : undefined,
				}}
			>
				<h3> {t<string>('payment.description')} </h3>
				<div style={{ height: 1, backgroundColor: 'var(--primary-color)' }} />
				<span className="mt-3"> {asset.description}</span>
			</div>
		);
	};

	const space = (): JSX.Element | undefined => {
		return <div style={{ width: '10%' }}> </div>;
	};

	const video = (): JSX.Element | undefined => {
		console.log(asset.token_id)
		return (
			<div
				className="container mx-auto text-center"
				style={{
					flexDirection: 'column',
					flexWrap: 'wrap',
					width: isMobile ? '100%' : '55%',
					marginTop: isMobile ? 50 : undefined,
				}}
			>
				<AudioPlayer
				  url={audio.url}
				  title={audio.title}
				  author={audio.author}
				  thumbnail={audio.thumbnail}
				/>
			</div>
		)
	};


	const transaction = (): JSX.Element => {
		return (
			<div  
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'wrap',
				width: isMobile ? '100%' : '55%',
				marginTop: isMobile ? 50 : undefined,
			}}
			>
				<h3> {t<string>('nft_marketplace_details.transaction')} </h3>
				<NftTransaction transactions={transactions} />
			</div>
		);
	};

	const presentation = () => {
		const price = asset.metadata.price;

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'wrap',
					width: isMobile ? undefined : '40%',
				}}
			>
				<h3> {t<string>('nft_marketplace_details.tab_2')} </h3>
				<div style={{ height: 1, backgroundColor: 'var(--primary-color)' }} />
				<div style={isMobile ? { marginLeft: '10%' } : undefined}>
					<h3> {asset.name}</h3>
					<div className="row">
						<span>
							{' '}
							{t<string>('nft_marketplace_details.purchaseOn')}{' '}
							{handlePurchasedDate(asset)}
						</span>{' '}
						<FileDownloadDoneIcon style={{ marginLeft: 10 }} />{' '}
					</div>
					<div className="row mt-3">
						<span>
							{' '}
							{t<string>('nft_marketplace_details.purchaseFor')} {price} ${' '}
						</span>
						<MonetizationOnIcon style={{ marginLeft: 10 }} />
					</div>
					<div className="row justify-content-between mt-3">
						<span
							style={{
								fontWeight: 600,
								fontSize: 20,
							}}
						>
							{t<string>('nft_marketplace_details.createdBy')}
						</span>
						<span style={{ marginRight: '15%', fontWeight: 600, fontSize: 20 }}>
							{t<string>('nft_marketplace_details.collection')}
						</span>
					</div>
					<div className="row justify-content-between mt-3">
						<div
							className="row"
							style={{
								border: '1px solid rgb(229, 232, 235)',
								borderRadius: 10,
								overflow: 'hidden',
								padding: 15,
							}}
						>
							<div
								className="rounded-circle"
								style={{
									height: '30px',
									width: '30px',
									overflow: 'hidden',
									marginRight: 5,
								}}
							>
								<img
									className="card-img-top"
									style={{
										height: '100%',
										width: '100%',
										objectFit: 'cover',
									}}
									src={applicationConfiguration.authorPicture}
									alt=""
								/>
							</div>
							<div
								style={{ color: 'var(--white-color)' }}
								className="col m-auto p-0"
							>
								{asset.createdBy
									? asset.createdBy
									: process.env.REACT_APP_VENDEUR
									? process.env.REACT_APP_VENDEUR
									: 'Tokies'}
							</div>
						</div>
						<div
							className="row"
							style={{
								border: '1px solid rgb(229, 232, 235)',
								borderRadius: 10,
								overflow: 'hidden',
								padding: 15,
							}}
						>
							<div
								className="rounded-circle"
								style={{
									height: '30px',
									width: '30px',
									overflow: 'hidden',
									marginRight: 5,
								}}
							>
								<img
									className="card-img-top"
									style={{
										height: '100%',
										width: '100%',
										objectFit: 'cover',
									}}
									src={asset.collection.icon_url}
									alt=""
								/>
							</div>
							<div
								style={{ color: 'var(--white-color)' }}
								className="col m-auto p-0"
							>
								{collection?collection.collectionName:''}
							</div>
						</div>
					</div>
					<div className="mt-5">
						<Button
							href={`https://immutascan.io/address/` + asset.token_address}
							style={{
								border: '1px solid rgb(229, 232, 235)',
								borderRadius: 10,
								overflow: 'hidden',
								padding: 15,
							}}
							variant="contained"
						>
							<img
								style={{ width: '30px', marginRight: 10 }}
								src={'../img/immutascan.png'}
							/>
							{t<string>('nft_marketplace_details.immutascan')}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const certification = () => {
		return (
			<div
				style={{
					width: isMobile ? undefined : '50%',
					marginTop: isMobile ? 50 : undefined,
				}}
			>
				<Certification asset={asset} collection={collection}/>
			</div>
		);
	};

	return (
		<section>
			<div className="container mt-3">
				<div
					style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
				>
					{description()}
					{space()}
					{asset.token_id >= 3500? video(): transaction()}
					<div style={{ width: '100%', height: 50 }}></div>
					{presentation()}
					{space()}
					{certification()}
				</div>
			</div>
		</section>
	);
};

export default NFTMetadata;

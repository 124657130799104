import { useEffect, useState } from 'react';
import { storageService } from 'src/ApplicationContext';
import TokiesService from 'src/service/TokiesService';

interface Markets {
	[key: number]: any;
}

export function AuthorReceiptUIController() {
	const [transactions, setTransactions] = useState<any>([]);
	const [markets, setMarkets] = useState<Markets>({});
	const [hasWalletAddress, setHasWalletAddress] = useState(false);
	const [loading, setLoading] = useState(false);

	const verificationWallet = async () => {
		const walletAddress = storageService.get_wallet_address();
		if (!walletAddress) {
			setHasWalletAddress(false);
			setLoading(false);
			return;
		}
		setHasWalletAddress(true);
		await getTransaction();
		setLoading(false);
	};

	const getTransaction = async () => {
		const transactions = await TokiesService.getTransactionsForUser(
			storageService.get_user_id()
		);
		const marketPlaces = await TokiesService.getAllMarket();
		let markets: Markets = {};
		for (let market in marketPlaces.data) {
			let marketId: number = marketPlaces.data[market].id;
			markets[marketId] = marketPlaces.data[market];
			let keyWalletMarket = await TokiesService.getWalletById(
				markets[marketId].walletId
			);
			markets[marketId].walletId = keyWalletMarket.data.publicKey;
		}
		setMarkets(markets);
		setTransactions(transactions.data);
	};
	useEffect(() => {
		setLoading(true);
		verificationWallet();
	}, []);

	return { loading, hasWalletAddress, markets, transactions };
}

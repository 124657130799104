import { ChangeEvent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18n from 'src/i18n';
import SettingButton from 'src/presentation/components/AuthorProfile/SettingButton';
import User from 'src/business/entities/User';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';

const AuthorProfile = (props: RouteComponentProps): JSX.Element => {
	const userController = new UserLogicController();

	const [user, setUser] = useState<User>(undefined);

	useEffect(() => {
		userController.loadDataUser(setUser);
	}, []);

	return (
		<div>
			<div className="text-center" style={{ padding: '10px' }}>
				{i18n.t<string>('profil.profil')}
			</div>
			<div className="card text-center my-3">
				<input
					type="file"
					accept="image/*"
					id="upload-input"
					style={{ display: 'none' }}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						userController.userLoadImage(e, user.id.toString())
					}
				/>
				<div className="card-caption col-12 p-0">
					<div className="card-body">
						<div className="d-flex flex-column align-items-center">
							<h4 className="ml-2 col-12  ">
								{user?.firstName
									? user.firstName
									: i18n.t<string>('profil.placeholder.firstName')}
								{user?.lastName
									? user.lastName
									: i18n.t<string>('profil.placeholder.lastName')}
							</h4>
							<span className="mb-4">
								{user?.email
									? user.email
									: i18n.t<string>('profil.placeholder.email')}
							</span>
						</div>
						<SettingButton googleAuth={user?.google_auth} {...props} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(AuthorProfile);

import DisconnectWallet from 'src/presentation/components/DisconnectWallet';
import ConnectedWallet from 'src/presentation/components/ConnectedWallet';
import Wallet from 'src/business/entities/Wallet';

interface Props {
	connectedWallet: Wallet | undefined;
	wallets: Wallet[];
	onChooseWallet: (wallet: Wallet) => void;
}

const ChooseWallet = ({
	wallets,
	onChooseWallet,
	connectedWallet,
}: Props): JSX.Element => (
	<div className='container'>
		<div style={{ marginTop: 30 }}>
			{connectedWallet && (
				<ConnectedWallet
					connectedWallet={connectedWallet}
					onChooseWallet={onChooseWallet}
				/>
			)}
		</div>
		<div>
			<DisconnectWallet wallets={wallets} onChooseWallet={onChooseWallet} />
		</div>
	</div>
);

export default ChooseWallet;

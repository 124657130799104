import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { adressService, storageService } from 'src/ApplicationContext';
import { Address } from 'src/business/entities/Address';
import { NFT } from 'src/business/entities/NFT';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import Wallet from 'src/business/entities/Wallet';
import { AdressFormProps } from 'src/presentation/components/AdressForm';
import { AddressDto } from 'src/service/dto/AddressDto';

export function AdressFormUIController(_props: AdressFormProps) {
	const userId = storageService.get_user_id();

	const [firstName, setFirstname] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [adress, setAdress] = useState<string>('');
	const [numeroAppartement, setNumeroAppartement] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [zip, setZip] = useState<string>('');
	const [country, setCountry] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [loading, setLoading] = useState(false);

	const [userAdress, setUserAdress] = useState<Address[]>([]);

	const createAdress = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		userId: string,
		numeroAppartement: string,
		adress: string,
		city: string,
		zip: string,
		country: string,
		firstName: string,
		lastName: string,
		phoneNumber: string,
		state: string,
		wallet: Wallet,
		nft: NFT,
		paymentMethod: PaymentMethod,
		props: RouteComponentProps
	): Promise<void> => {
		e.preventDefault();
		const addressDto: AddressDto = {
			userId: parseInt(userId),
			isDefault: true,
			appartmentNumber: numeroAppartement,
			address: adress,
			city: city,
			zip: zip,
			country: country,
			firstName: firstName,
			lastName: lastName,
			phone: phoneNumber,
			state: state,
		};

		await adressService.createAdress(addressDto);

		props.history.push('/paymentConfirmation', {
			address: addressDto,
			wallet,
			nft,
			paymentMethod,
		});
	};

	const confirmAdress = (
		address: Address,
		props: RouteComponentProps,
		wallet: Wallet,
		nft: NFT,
		paymentMethod: PaymentMethod
	): void => {
		props.history.push('/paymentConfirmation', {
			address,
			wallet,
			nft,
			paymentMethod,
		});
	};

	const verifyAddress = async (
		props: AdressFormProps,
		wallet: Wallet,
		nft: NFT,
		paymentMethod: PaymentMethod
	) => {
		setLoading(true);
		const userAdress = await adressService.getUserAdress();

		if (userAdress.length > 0 && props.didntChange) {
			confirmAdress(userAdress[0], props, wallet, nft, paymentMethod);
		} else {
			setUserAdress(userAdress);
		}

		setLoading(false);
	};

	return {
		userAdress,
		verifyAddress,
		createAdress,
		firstName,
		setFirstname,
		lastName,
		setLastName,
		adress,
		setAdress,
		numeroAppartement,
		setNumeroAppartement,
		city,
		setCity,
		zip,
		setZip,
		country,
		setCountry,
		phoneNumber,
		setPhoneNumber,
		state,
		setState,
		loading,
		confirmAdress,
		userId,
	};
}

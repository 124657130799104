
import { useEffect, useState } from "react";
import { immutableProvider, storageService } from "src/ApplicationContext";
import TokiesService from "src/service/TokiesService";


export function SoldeETHUIController() {
	const [balanceETH, setBalanceETH] = useState<number>(0);
	const [balanceCAD, setBalanceCAD] = useState<number>(0);
	const [loading, setLoading] = useState(false);

	const getData = async ()=> {
		await immutableProvider.init();
        setLoading(true);

		const wallet = storageService.get_wallet_address();

		if (
			storageService.get_connexion() &&
			wallet !== 'null' &&
			wallet !== 'undefined'
		) {
			const conversionRate = await TokiesService.getConversionRateCAD();
			const balanceETH = await immutableProvider.getBalance(wallet);
			const balanceCAD = ((1 / conversionRate) * balanceETH);
			const subBalance = balanceCAD;
			setBalanceETH(balanceETH)
			setBalanceCAD(subBalance)
		}
        setLoading(false);
	}

	useEffect(()=>{
		getData()
	},[])

	return {balanceETH, balanceCAD, loading}
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const languages = ['fr', 'en'];

i18n
	.use(Backend)
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ['localStorage'],
			lookupLocalStorage: 'i18nextLng',
			lookupFromSubdomainIndex: 0,
			caches: ['localStorage'],
		},
		defaultNS: 'translation',
		initImmediate: false,
		fallbackLng: ['fr', 'en'],
		debug: false,
		react: {
			useSuspense: false,
		},
		preload: languages,
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json`,
		},

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;

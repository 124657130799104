
import { useState } from "react";
import { NFT } from "src/business/entities/NFT";
import Viewer from "./Viewer/Viewer";

interface Props {
  nft: NFT;
}

export const NftPreviewSection = ({ nft }: Props): JSX.Element => {
  const [showViewer, setShowViewer] = useState<boolean>(false);

  const showImage = (value: boolean) => {
    setShowViewer(value);
  };

  return (
  <div className="col-12 col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center align-items-center">
    <div className="item-info my-auto d-flex align-items-center">
      <div
        className="card p-0 no-hover d-flex justify-content-center align-items-center"
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
        onClick={() => setShowViewer(true)}
      >
        <img
          className="card-img-top"
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
          src={
            nft.image ??
            'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
          }
          alt=""
        />
        <div
          className="card-inner d-flex align-items-center justify-content-center"
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            background: 'rgba(0,0,0,0.4)',
            cursor: 'pointer',
          }}
        >
          <i className="fas fa-eye fa-lg"></i>
        </div>
      </div>
    </div>
      <Viewer
        imageURL={nft.image}
        isShowed={showViewer}
        showViewer={showImage}
      />
  </div>
  );
};

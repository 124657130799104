import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { storageService } from 'src/ApplicationContext';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import { useAdminContext } from 'src/context/useAdminContext';
import { useMarketContext } from 'src/context/useMarketContext';

export function HeaderUIController(timeout: number) {
	const [showLogin, setShowLogin] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [showRegister, setShowRegister] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);

	const [showIdle, setShowIdle] = useState<boolean>(false);
	const [remaining, setRemaining] = useState<number>(timeout);

	const { isAdmin } = useAdminContext();
	const { marketId } = useMarketContext();

	const { t } = useTranslation();

	const userLogicController = new UserLogicController();

	const onIdle = (props: RouteComponentProps) => {
		if (!!storageService.get_connexion()) {
			userLogicController.logout(props);
		}
	};

	const handleLogout = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		props: RouteComponentProps
	) => {
		e.preventDefault();
		userLogicController.logout(props);
	};

	const changeLanguage = () => {
		userLogicController.changeLanguage();
	};

	const onActive = () => {
		setShowIdle(false);
	};

	const onPrompt = (reset: () => void) => {
		if (!!storageService.get_connexion()) {
			setShowRegister(false);
			setShowLogin(false);
			setShowIdle(true);
		} else {
			reset();
		}
	};

	const verifyInterval = (getRemainingTime: () => number, throttle: number) => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, throttle);
		return () => {
			clearInterval(interval);
		};
	};

	return {
		verifyInterval,
		onIdle,
		onActive,
		onPrompt,
		changeLanguage,
		handleLogout,
		showLogin,
		setShowLogin,
		showMessage,
		setShowMessage,
		showRegister,
		setShowRegister,
		showForgotPassword,
		setShowForgotPassword,
		showIdle,
		setShowIdle,
		remaining,
		setRemaining,
		isAdmin,
		marketId,
		t,
	};
}

import CryptoSelection from 'src/presentation/components/CryptoSelection';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import { NFT } from 'src/business/entities/NFT';
import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';

export interface CryptoPaymentProps extends RouteComponentProps{
	location: {
		key: string;
		pathname: string;
		state: {
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
		search: string,
		hash: string
	};
}

const CryptoPayment:FC<CryptoPaymentProps> = (props: CryptoPaymentProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<CryptoSelection {...props} />
			<Scrollup />
			<ModalMenu />
		</div>
	);
};

export default CryptoPayment;

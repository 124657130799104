import { RouteComponentProps } from "react-router";
import { BrowserRouter as Router, Route, Switch,Redirect, } from 'react-router-dom';
import { applicationConfiguration } from "src/env/ApplicationConfiguration";
import BuyNow, { BuyNowProps } from "src/presentation/components/BuyNow";
import CryptoPayment, { CryptoPaymentProps } from "src/presentation/components/CryptoPayment";
import NFTDetails, { NftDetailsProps } from "src/presentation/components/NftDetails";
import PaymentConfirmation, { PaymentConfirmationProps } from "src/presentation/components/PaymentConfirmation";
import SelectPaymentWalletPage, { SelectPaymentWalletPageProps } from "src/presentation/components/SelectPaymentWalletPage";
import AboutScreen from "src/presentation/screens/AboutScreen";
import HomePage from "src/presentation/screens/HomePage";
import NftMarketplaceDetailsScreen, { NftMarketplaceDetailsProps } from "src/presentation/screens/NftMarketplaceDetailsScreen";
import NftPeerToPeerDetailsScreen, { NftPeerToPeerDetailsProps } from "src/presentation/screens/NftPeerToPeerDetailsScreen";
import NftUserItemDetailsScreen, { NftUserItemDetailsProps } from "src/presentation/screens/NftUserItemDetailsScreen";
import PaymentConfirmationScreen from "src/presentation/screens/PaymentConfirmationScreen";
import ZoneAdminScreen, { ZoneAdminProps } from "src/presentation/screens/ZoneAdminScreen";
import ExploreMarketplaceScreen from "./screens/ExploreMarketplaceScreen";
import PeerToPeerScreen from "./screens/PeerToPeerScreen";
import ForgetPasswordScreen from "./screens/ForgetPasswordScreen";
import DepositsScreen from "./screens/DepositsScreen";
import PaymentWalletConnectScreen, { PaymentWalletConnectProps } from "./screens/PaymentWalletConnectScreen";
import UserAdressScreen, { UserAdressProps } from "./screens/UserAdressScreen";
import PaymentScreen, { PaymentProps } from "./screens/PaymentScreen";
import AuthorScreen from "./screens/AuthorScreen";
import WalletConnectScreen from "./screens/WalletConnectScreen";
import { useAdminContext } from "src/context/useAdminContext";
import { useMarketContext } from "src/context/useMarketContext";
import ChangePasswordScreen from "src/presentation/screens/ChangePasswordScreen";


const Routes = () => {

	const { marketId } = useMarketContext();
	const { isAdmin } = useAdminContext();

	const isUserAdmin = isAdmin &&
	marketId ===
	applicationConfiguration.marketId ;

	return (
		<div>
			<Router>
				<Switch>
					<Route exact path="/" render={(_) => <Redirect to="/home" />} />
					<Route path="/home" render={(props) => <HomePage {...props} />} />
					<Route path="/contact" render={(props: RouteComponentProps) => <AboutScreen {...props} />} />
					<Route
						exact
						path="/marketplace"
						render={(props: RouteComponentProps) => <ExploreMarketplaceScreen {...props} />}
					/>
					<Route exact path="/peer-to-peer" component={PeerToPeerScreen} />
					<Route path="/changePassword" component={ChangePasswordScreen} />
					<Route path="/forgetPassword" component={ForgetPasswordScreen} />
					<Route
						path="/peer-to-peer/item-details/:tokenAddress/:tokenId"
						render={(props:RouteComponentProps) => <NftPeerToPeerDetailsScreen {...props as NftPeerToPeerDetailsProps} />}
					/>
					<Route
						path="/profil/item-details/:tokenAddress/:tokenId"
						render={(props:RouteComponentProps) => <NftUserItemDetailsScreen {...props as NftUserItemDetailsProps} />}
					/>
					<Route
						path="/nft/details"
						render={(props:RouteComponentProps) => <NFTDetails {...props as NftDetailsProps} />}
					/>
					{isUserAdmin && (
							<Route
								path="/zone-admin"
								render={(props:RouteComponentProps) => <ZoneAdminScreen {...props as ZoneAdminProps} />}
							/>
						)}

					{isUserAdmin && (
							<Route path="/Stripe/validate/:id" render={(props:RouteComponentProps) => <ZoneAdminScreen {...props as ZoneAdminProps} />} />
						)}
					<Route path="/deposit" component={DepositsScreen} />
					<Route path="/payment" render={(props:RouteComponentProps) => <PaymentScreen {...props as PaymentProps} />} />
					<Route
						path="/cryptoPayment"
						render={(props:RouteComponentProps) => <CryptoPayment {...props as CryptoPaymentProps} />}
					/>
					<Route
						path="/paymentWalletConnect"
						render={(props:RouteComponentProps) => <PaymentWalletConnectScreen {...props as PaymentWalletConnectProps} />}
					/>
					<Route
						path="/address"
						render={(props:RouteComponentProps) => <UserAdressScreen {...props as UserAdressProps} />}
					/>
					<Route
						path="/paymentConfirmation"
						render={(props:RouteComponentProps) => <PaymentConfirmation {...props as PaymentConfirmationProps} />}
					/>

					<Route
						path="/paymentConfirmationPage"
						render={(props:RouteComponentProps) => <PaymentConfirmationScreen {...props as PaymentConfirmationProps} />}
					/>

					<Route
						path="/select-wallet-payment"
						render={(props:RouteComponentProps) => <SelectPaymentWalletPage {...props as SelectPaymentWalletPageProps} />}
					/>

					<Route path="/buyNow" render={(props:RouteComponentProps) => <BuyNow {...props as BuyNowProps} />} />

					<Route
						path="/marketplace/item-details/:tokenAddress/:tokenId"
						render={(props:RouteComponentProps) => <NftMarketplaceDetailsScreen {...props as NftMarketplaceDetailsProps} />}
					/>

					<Route path="/profil" render={(props:RouteComponentProps) => <AuthorScreen {...props} />} />
					<Route path="/wallet-connect" component={WalletConnectScreen} />
				</Switch>
			</Router>
		</div>
	);
};
export default Routes;

import Header from 'src/presentation/components/Header/Header';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import NftPeerToPeerDetails from 'src/presentation/components/NftPeerToPeerDetails/NftPeerToPeerDetails';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

export interface NftPeerToPeerDetailsProps extends RouteComponentProps {
	match: {
		params: {
			tokenId: string;
			tokenAddress: string;
		},
		isExact: boolean;
		path: string;
		url: string;
	};
}

const NftPeerToPeerDetailsScreen: FC<NftPeerToPeerDetailsProps> = (props: NftPeerToPeerDetailsProps) => {
		return (
			<div className="main">
				<Header />
				<NftPeerToPeerDetails
					{...props}
					tokenAddress={props.match.params.tokenAddress}
					tokenId={props.match.params.tokenId}
				/>
				<Footer />
				<ModalMenu />
				<Scrollup />
			</div>
		);
}

export default NftPeerToPeerDetailsScreen;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { authenticationService } from 'src/ApplicationContext';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import i18n from 'src/i18n';

export function ChangePasswordUIController() {
	const { t } = useTranslation();
	const [emailvalidationError, setEmailvalidationError] = useState<string>();
	const [validationError, setValidationError] = useState<string>();
	const [confirmPassword, setConfirmPassword] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [oldPassword, setOldPassword] = useState<string>();
	const [newPassword, setNewPassword] = useState<string>();
	const userController = new UserLogicController();

	const emailValidation = (email: string): boolean => {
		if (!userController.emailIsValid(email)) {
			setEmailvalidationError(i18n.t<string>('password.messageEmailNotValid'));
			return false;
		} else {
			setEmailvalidationError('');
			return true;
		}
	};

	const passwordValidation = (
		newPassword: string,
		confirmPassword: string
	): boolean => {
		if (newPassword !== confirmPassword) {
			setValidationError(i18n.t<string>('password.messageNotSamePassword'));
			return false;
		} else {
			setValidationError('');
			return true;
		}
	};

	const changePasswordSubmit = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		email: string,
		newPassword: string,
		confirmPassword: string,
		oldPassword: string,
		props: RouteComponentProps
	) => {
		if (emailValidation(email) && passwordValidation(newPassword, confirmPassword)) {
			e.preventDefault();

			const results = await authenticationService.change_password({
				email: email,
				oldPassword: oldPassword,
				newPassword: newPassword,
			});

			if (results) {
				setValidationError(
					i18n.t<string>('password.messagePasswordChangeCorrectly')
				);
				setTimeout(() => userController.logout(props), 1000);
			} else {
				setValidationError(
					i18n.t<string>('password.messageIncorrectEmailOrPassword')
				);
			}
		}
	};

	return {
		email,
		setEmail,
		oldPassword,
		setOldPassword,
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		emailvalidationError,
		validationError,
		changePasswordSubmit,
		t
	};
}

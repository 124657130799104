import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NFTAsset from 'src/business/entities/NFTAsset';
import { CertificationUIController } from 'src/business/controller/ui/CertificationUIController';

interface Props {
	asset: NFTAsset;
	collection:any;
}

const Certification = ({ asset,collection }: Props): JSX.Element => {
	const { handleCreatedDate, handlePurchasedDate, t } =
		CertificationUIController(asset);

	const walletId = asset.user;
	const createdDate = handleCreatedDate();
	const purchasedDate = handlePurchasedDate();

	return (
		<div style={{ padding: 0 }}>
			<div className="tab-content">
				<div className="tab-pane fade show active">
					<div
						style={{
							border: '10px solid var(--secondary-color)',
							padding: 20,
						}}
					>
						<div
							style={{
								padding: 25,
								border: '5px solid var(--secondary-color)',
								color: '#ad9510',
							}}
						>
							<div className="item-info-list mt-4 intro text-center">
								<a id="nav-tab2" data-toggle="pill" href="#nav-content2">
									<div
										style={{
											flexDirection: 'row',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<h4 style={{ color: 'var(--white-color)' }} className="m-0">
											{t<string>('user_item_details.certificate')}
										</h4>

										<div style={{ paddingLeft: 10 }}>
											{' '}
											<WorkspacePremiumIcon />{' '}
										</div>
									</div>
								</a>
							</div>
							<ul className="list-unstyled">
								<li className="price d-flex justify-content-between">
									<span
										style={{ color: 'var(--white-color)' }}
										className="my-auto"
									>
										{t<string>('nft_marketplace_details.collection')}
									</span>
									<div className="container row">
										<div
											style={{ color: 'var(--white-color)' }}
											className="col m-auto p-0 text-right"
										>
											{collection?collection.collectionName:''}
										</div>
										<div
											className="rounded-circle ml-2"
											style={{
												height: '30px',
												width: '30px',
												overflow: 'hidden',
											}}
										>
											<img
												className="card-img-top"
												style={{
													height: '100%',
													width: '100%',
													objectFit: 'cover',
												}}
												src={asset.collection.icon_url}
												alt=""
											/>
										</div>
									</div>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.address')}
									</span>
									<a
										href={'https://etherscan.io/address/' + asset.token_address}
										target="_blank"
										rel="noopener noreferrer"
									>
										<span style={{ color: 'var(--white-color)' }}>
											{asset.token_address.substring(0, 8) +
												'...' +
												asset.token_address.substring(
													asset.token_address.length,
													asset.token_address.length - 4
												)}
										</span>
									</a>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.tokenId')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{Number(asset.token_id)-3500}
									</span>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.network')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{asset.status}
									</span>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.name')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{asset.name}
									</span>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.owner')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{walletId.substring(0, 8) +
											'...' +
											walletId.substring(walletId.length, walletId.length - 4)}
									</span>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.created')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{createdDate}
									</span>
								</li>
								<li className="price d-flex justify-content-between">
									<span style={{ color: 'var(--white-color)' }}>
										{t<string>('nft_marketplace_details.purchased')}
									</span>
									<span style={{ color: 'var(--white-color)' }}>
										{purchasedDate}
									</span>
								</li>
							</ul>
							<div
								className="mt-4"
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'black',
								}}
							>
								<span style={{ color: 'var(--secondary-color)' }}>
									{' '}
									{t<string>('header.powered')}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Certification;

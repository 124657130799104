import i18n from 'src/i18n';
import DnaLoader from '../loader/DNALoader';
import { StripeAccountUIController } from 'src/business/controller/ui/StripeAccountUIController';
import ReceiptsAdmin from '../ReceiptsAdmin/ReceiptsAdmin';

interface Props {
    validationId:string
}

const StripeAccount = (props: Props) => {
    const {loading, connected, balanceAvailable, balancePending, currencyAvailable, currencyPending, createStripeAccount, goToAccount} = StripeAccountUIController(props);
    if (loading) {
		return (
				<div className="d-flex justify-content-center align-items-center mt-4">
					<DnaLoader />
				</div>
		);
    }
    return (
        <>
        <section className="activity-area load-more pt-4">
            <div className="container">
                <div className='row'>
                    <div className='col'>
                        <h4 className='px-4'>
                            {i18n.t<string>("zone_admin.tab_3.stripeAccount")}
                        </h4>
                        <div className='card'>
                            {
                                !connected ? 
                                <div>
                                    <div className='card-body d-flex justify-content-center pt-2'>
                                        {i18n.t<string>("zone_admin.tab_3.accountWarning")}
                                    </div>
                                    <div className='card-body d-flex justify-content-center'>
                                        <div>
                                            <button className='btn' onClick={createStripeAccount}>{i18n.t<string>("zone_admin.tab_3.createAccount")}</button> 
                                        </div>
                                    </div>
                                </div>:
                                <div className='card-body container-fluid pt-2'>
                                    <h5>{i18n.t<string>("zone_admin.tab_3.balanceAvailable")} : {balanceAvailable + " " +currencyAvailable}</h5>
                                    <h5>{i18n.t<string>("zone_admin.tab_3.balancePending")} :  {balancePending + " " +currencyPending}</h5>
                                    <div className='row align-items-center pt-4'>
                                        <div className='col text-center'>{i18n.t<string>("zone_admin.tab_3.connected")} <i className="fas fa-check-circle text-success"></i></div>
                                        <div className='col text-center'><a className='btn' onClick={goToAccount}>{i18n.t<string>("zone_admin.tab_3.seeAccount")}</a></div>
                                    </div>
                                    
                                </div>
                            }
                        </div> 
                    </div>
                    <div className='col my-auto intro-content'>
                    </div>
                </div>
            </div>
        </section>
        <ReceiptsAdmin />
        </>
    );
    

}

export default StripeAccount;
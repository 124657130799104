import axios, { AxiosRequestConfig } from 'axios';
import { getBackEndUrl } from 'src/env/SaasEnvironnement';
import { SignatureResponse, SignatureRequest } from './dto/Signature';
import { ethers } from 'ethers';
import { CreateMetaMaskUserRequest } from './dto/request/CreateMetamaskUserRequest';
import { LoginMetamaskRequest } from './dto/request/LoginMetamaskRequest';
import { LoginMetamaskResponse } from './dto/response/LoginMetamaskResponse';
import { LoginReponse } from './dto/response/LoginResponse';

class MetamaskAuthenticationService {
	private readonly backend_url = getBackEndUrl();

	public isMetaMaskInstalled() {
		return Boolean(
			(window as any).ethereum && (window as any).ethereum.isMetaMask
		);
	}

	public async get_public_key() {
		if (!window.ethereum) {
			return;
		}
		const accounts: any = await window.ethereum.send('eth_requestAccounts');
		return accounts.result[0];
	}

	public async create_signature_request(
		public_key: string
	): Promise<SignatureResponse> {
		const data = JSON.stringify({
			public_key,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backend_url}/signature`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios.request(config);
			return request.data as SignatureResponse;
		} catch {
			return null;
		}
	}

	public async signMessage(message: string) {
		try {
			if (window.ethereum) {
				const provider = new ethers.providers.Web3Provider(
					window.ethereum as any
				);
				const signer = provider.getSigner();
				const signature = await signer.signMessage(message);
				const address = await signer.getAddress();
				return {
					signature,
					address,
				};
			}
		} catch (err) {
			console.log(err);
		}
	}

	public async verifySignature(signature: SignatureRequest): Promise<boolean> {
		const data = JSON.stringify({
			signature: signature.signature,
			address: signature.public_key,
			message: signature.message,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backend_url}/signature/verify-signature`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios.request(config);
			return request.data as boolean;
		} catch {
			return false;
		}
	}

	public async loginWithMetamask(
		loginMetamaskRequest: LoginMetamaskRequest
	): Promise<LoginMetamaskResponse> {
		const data = JSON.stringify(loginMetamaskRequest);

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backend_url}/signature/login`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request: { data: LoginMetamaskResponse } = await axios.request(
				config
			);
			return request.data;
		} catch (e) {
			throw new Error('Can not login with metamask');
		}
	}

	public async createMetaMaskAccount(
		createMetaMaskAccount: CreateMetaMaskUserRequest
	): Promise<LoginReponse> {
		const data = JSON.stringify(createMetaMaskAccount);

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backend_url}/signature/registerAccount`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios.request(config);
			return request.data as LoginReponse;
		} catch {
			return null;
		}
	}
}

export default MetamaskAuthenticationService;

import { isMobile } from 'react-device-detect';
import RedirectIosModal from 'src/presentation/components/Modals/RedirectIosModal/RedirectIosModal';
import { DepositUIController } from 'src/business/controller/ui/DepositUIController';

const Deposit = () => {
	const {
		connectMetamask,
		t,
		type,
		content,
		title,
		showIosModal,
		image,
		setShowIosModal,
	} = DepositUIController();

	return (type === 'MetaMask' ? (
			<section className="wallet-connect-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-8 col-lg-7">
						{/* Intro */}
						<div className="intro text-center">
							<span>{t<string>('deposit.preHeading')}</span>
							<h3  className="mt-3 mb-0">
								{t<string>('deposit.heading')}
							</h3>
							<p>{t<string>('deposit.content')}</p>
						</div>
					</div>
				</div>
				<div className="row justify-content-center items">
					<div className="col-12 col-md-6 col-lg-4 item pe-auto">
							<div className="card single-wallet">
								<a
									className="d-block text-center"
									href="#"
									onClick={connectMetamask}
								>
									<img className="avatar-lg" src={image} alt="" />
									<h4 className="mb-0">{title}</h4>
									<p>{content}</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			{ isMobile && <RedirectIosModal show={showIosModal} onShowChange={setShowIosModal} />}
			</section>
						) : (
			<section className="wallet-connect-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-8 col-lg-7">
						{/* Intro */}
						<div className="intro text-center">
							<span>{t<string>('deposit.preHeadingNoMetamask')}</span>
							<h3  className="mt-3 mb-0">
								{t<string>('deposit.headingNoMetamask')}
							</h3>
							<p>{t<string>('deposit.contentNoMetamask')}</p>
						</div>
					</div>
				</div>
			</div>
			</section>))
	}

export default Deposit;

import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { RouteComponentProps } from 'react-router';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';

export interface InstructionModalProps extends RouteComponentProps {
	onShowMessage: (value: boolean) => void;
	show: boolean;
	goToHome: () => void;
	goToProfil: () => void;
}

const InstructionModal = (props: InstructionModalProps) => {

	const handleQuitMessage = () => {
		props.onShowMessage(false);
	};
	const handleGoProfil = () => {
		props.onShowMessage(false);
		props.goToProfil()
	};
	const color =
		applicationConfiguration.saasName === 'Boutet' ? 'black' : 'white';

	return (
		<Modal
			show={props.show}
			onHide={handleQuitMessage}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header  className="mx-3 pb-0">
				<Modal.Title>
					{'Pour écouter la musique de Taktika'}
				</Modal.Title>
				<button
					onClick={() => handleQuitMessage()}
					className="btn"
					style={{ padding: 16, color: "var(--primary-p-color)" }}
				>
					{'X'}
				</button>
			</Modal.Header>
			<Modal.Body  className="mx-1 pt-0">
				<div className="container">
				<div>
					<h5>Votre actif va être transféré dans 1 à 2 min.</h5>
					<h5>Il sera disponible dans votre profil <i aria-label="Login-Icon" style={{ color: color }} className="icon-user mx-md-1" /> dans la section NFT.</h5>
					<h5>La musique est accessible en cliquant sur l'image de l'album.</h5>
					<h5>Si vous ne voyez pas votre achat, simplement attendre quelques minutes et rafraîchir.</h5>
				</div>
				<button
					className="btn"
					type="submit"
					onClick={handleGoProfil}
					style={{margin:'0 auto',display:'block'}}
				>
					Aller au profil
				</button>

				</div>
			</Modal.Body>
		</Modal>
	);
};
export default InstructionModal;

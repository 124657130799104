import Header from 'src/presentation/components/Header/Header';
import ChangePassword from 'src/presentation/components/ChangePassword/ChangePassword';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';


const ChangePasswordScreen:FC<RouteComponentProps> = (props:RouteComponentProps): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<ChangePassword {...props}/>
				<Scrollup />
				<ModalMenu />
			</div>
		);
}

export default ChangePasswordScreen;

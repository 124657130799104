import NFTAsset from "src/business/entities/NFTAsset";
import { RouteComponentProps } from "react-router";
import { NFT } from "src/business/entities/NFT";

export interface NFTProps extends RouteComponentProps {
	tokenAddress: string;
	tokenId: string;
}

export class NFTLogicController {

	proceedToPayment(asset:NFTAsset, priceToETH:number,
					 priceToCAD:number, isFree:boolean, props: RouteComponentProps): void {
		const nftAsset: NFTAsset = asset;
		const price = priceToETH;
		const canPrice = priceToCAD;

		const nft: NFT = {
			token_id: nftAsset.token_id.toString(),
			image: nftAsset.image_url,
			seller: nftAsset.user,
			price: price.toFixed(7),
			token_address: nftAsset.token_address,
			id: nftAsset.id,
			name: nftAsset.name,
			status: nftAsset.status,
			uri: nftAsset.uri,
			description: nftAsset.description,
			priceCan: canPrice.toString(),
			updated_at: nftAsset.updated_at,
			fees: nftAsset.fees,
			free: isFree,
		};

		props.history.push('/buyNow', {
			nft,
		});
	};
}
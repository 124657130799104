import axios, { AxiosRequestConfig } from 'axios';
import { getBackEndUrl } from 'src/env/SaasEnvironnement';
import { Address } from 'src/business/entities/Address';
import { AddressDto } from 'src/service/dto/AddressDto';
import { storageService } from 'src/ApplicationContext';

class AdressService {
	backendUrl: string = getBackEndUrl();

	async createAdress(addressDto: AddressDto) {
		const data = JSON.stringify(addressDto);

		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${this.backendUrl}/address`,
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			await axios(config);
		} catch (e) {
			console.log(e);
		}
	}

	async getUserAdress(): Promise<Address[]> {
		const userId = storageService.get_user_id();

		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${this.backendUrl}/address/users/${userId}`,
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		};

		const result = await axios(config);

		return result.data;
	}
}

export default AdressService;


import { Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';

interface Props {
	show: boolean;
    remaining:number;
    onShowIdleModal: (value: boolean) => void;
}


const FiltersModal = (props:Props)=> {
	const [value, setValue] = useState<number[]>([20, 37]);
  
	const handleChange = (_event: Event, newValue: number | number[]) => {
		setValue(newValue as number[]);
	  };

	const valuetext = (value: number) => {
		return `${value} `;
	  }

    const handleClick = () =>{
		props.onShowIdleModal(false);
	}
	return (
		<Modal
			size="lg"
			show={props.show}
			onHide={handleClick}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
                <Modal.Title className="ml-4">
					Filters
				</Modal.Title>
			</Modal.Header>
			<Modal.Body >
				<div className='col-6'>
    				<Box sx={{ width: 300 }} >
    				  <Slider
    				    getAriaLabel={() => 'Temperature range'}
    				    value={value}
						min={10}
						max={200}
    				    onChange={handleChange}
    				    valueLabelDisplay="auto"
    				    getAriaValueText={valuetext}
    				  />
    				</Box>
				</div>
				<div className='col-4'>
      				<FormControlLabel control={<Checkbox  />} label="Beauchamps" />
      				<FormControlLabel control={<Checkbox  />} label="imx" />
				</div>
            </Modal.Body>
		</Modal>
	);
}

export default FiltersModal;

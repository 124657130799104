import axios from 'axios';
import User from 'src/business/entities/User';
import { storageService } from '../ApplicationContext';
import { getBackEndUrl } from '../env/SaasEnvironnement';

class UserService {
	backendURL: string = getBackEndUrl();

	async getUserByEmail(email: string): Promise<User> {
		const request = await axios.get(`${this.backendURL}/Users`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
			params: { email: email },
		});

		return request.data[0];
	}
}

export default UserService;

import React,{ useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import NumericInput from 'react-numeric-input';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { immutableProvider } from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import i18n from 'src/i18n';
import CancelCreationConfirmation from 'src/presentation/components/CancelCreationConfirmation';
import CollectionNfts from 'src/presentation/components/CollectionNfts';
import EditNFTModal from 'src/presentation/components/EditNftModal';
import ModalLoader from 'src/presentation/components/ModalLoader';
import CollectionService from 'src/service/CollectionService';
import NftMetadata from 'src/business/entities/NftMetadata';
import CollectionTitle from '../components/CollectionTitle';
import CollectionLine from '../components/CollectionLine';
import { RouteComponentProps } from 'react-router';

const CollectionScreen = (props:RouteComponentProps): JSX.Element => {
	const collectionService = new CollectionService();
	const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
	const [showEditNft, setShowEditNFT] = useState<boolean>(false);
	const [nftToEdit, setNFTToEdit] = useState<NftMetadata>();

	const [displayImage, setDisplayImage] = useState<string>();

	const [image, setImage] = useState<File>();
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [price, setPrice] = useState<string>('');
	const [quantity, setQuantity] = useState<number>(1);
	const [loader, setLoader] = useState(false);
	const [screenLoader, setScreenLoader] = useState(false);
	const [assets, setAssets] = useState<NFTAsset[]>();
	const [assets2, setAssets2] = useState<NFTAsset[]>();

	const [isCreatingCollection, setIsCreatingColleciton] =
		useState<boolean>(false);

	const [metadatas, setMetadatas] = useState<NftMetadata[]>([]);

	const getCollection = async () => {
		setScreenLoader(true);
		const collection = await collectionService.getCollection();

		let assets:any[] = [];
		let assets2:any[] = [];
		let assetsAll:any[] = [];

		if (collection) {
			const collectionAddress = collection.address;
			assets = await immutableProvider.getAssets(applicationConfiguration.adminAddress,collectionAddress);
		}		
		if (collection) {
			const collectionAddress = collection.address;
			assetsAll = await immutableProvider.getAssets("",collectionAddress);
		}
		assetsAll.map((item:any,_:any) =>{
			let found=false;
			for (var i = 0; i < assets.length; i++) {
				if (assets[i].id==item.id){
					found=true;
					break
				}
			}
			if(!found){
				assets2.push(item);
			}

		});

		setAssets(assets);
		setAssets2(assets2);
		setScreenLoader(false);
	};

	useEffect(() => {
		getCollection();
	}, []);

	const createCollection = () => {
		setIsCreatingColleciton(true);
	};

	const cancelCollectionCreation = () => {
		setIsCreatingColleciton(false);
		setShowCancelModal(false);
	};

	const handleOpenModal = () => {
		setShowCancelModal(true);
	};

	const handleCloseModal = () => {
		setShowCancelModal(false);
	};

	const openEditModal = (nft: NftMetadata) => {
		setNFTToEdit(nft);
		setShowEditNFT(true);
	};

	const closeEditModal = () => {
		setNFTToEdit(undefined);
		setShowEditNFT(false);
	};

	const confirmEditNft = (nftMetadata: NftMetadata) => {
		const newMetadata = metadatas.map((metada) =>
			metada.id === nftMetadata.id ? nftMetadata : metada
		);

		setMetadatas(newMetadata);
		closeEditModal();
	};

	const addAnotherNFT = () => {
		if (name === '') {
			window.alert('Please enter a name');
			return;
		}

		if (price === '') {
			window.alert('Please enter a price');
			return;
		}

		if (!image) {
			window.alert('Please select a image');
			return;
		}

		const nftMetadatas: NftMetadata = {
			name,
			description,
			price,
			qte: quantity,
			image,
			url: displayImage,
			id: uuidv4(),
		};

		setMetadatas((value) => [...value, nftMetadatas]);

		resetValue();
	};

	const resetValue = () => {
		setName('');
		setDescription('');
		setQuantity(1);
		setImage(undefined);
		setDisplayImage(undefined);
	};

	const createNft = async () => {
		setLoader(true);
		let createCurrent = true;

		if (name === '') {
			createCurrent = false;
		}

		if (price === '') {
			createCurrent = false;
		}

		if (!image) {
			createCurrent = false;
		}

		if (createCurrent === false && metadatas.length === 0) {
			window.alert(i18n.t<string>('zone_admin.mintage.error'));
			setLoader(false);
			return;
		}

		const nftToCreate: any[] = createCurrent
			? [
					...metadatas,
					{
						name,
						description,
						price,
						qte: quantity,
						image: image,
						url: displayImage,
						id: uuidv4(),
					},
			  ]
			: metadatas;

		await collectionService.createNFTs(nftToCreate);

		setIsCreatingColleciton(false);
		resetValue();
		setMetadatas([]);
		await getCollection();
		setLoader(false);
	};

	const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const file:any = files[0];
		const image = URL.createObjectURL(file);

		setDisplayImage(image);
		setImage(file);
	};

	const deletePendingNft = (nft: NftMetadata) => {
		setMetadatas(metadatas.filter((metadata) => metadata.id !== nft.id));
	};

	const NftForm = () => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<form>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<h5> {i18n.t<string>('zone_admin.mintage.nftPicture')} </h5>
							<label htmlFor="file-upload" className="custom-file-upload">
								<div className="btn btn-bordered-white btn-smaller">
									{i18n.t<string>('zone_admin.mintage.select')}
								</div>
							</label>
							<input
								accept="image/*"
								onChange={(e) => handlePictureChange(e)}
								style={{ display: 'none' }}
								id="file-upload"
								type="file"
							/>
							{!!displayImage && (
								<div>
									<img
										style={{ height: 200, marginTop: 30 }}
										className="preview"
										src={displayImage}
										alt=""
									/>
								</div>
							)}
						</div>
						{isMobile === false && (
							<div>
								<h5 style={{ textAlign: 'end' }}>
									{' '}
									{i18n.t<string>('zone_admin.mintage.pendingNFT')}{' '}
								</h5>
								<div className="row">
									{metadatas.map((metadata, idx) => {
										if (idx > 2) {
											return null;
										}

										return (
											<div className="card" style={{ marginLeft: 10 }}>
												<div>
													<img
														style={{ height: 120 }}
														className="preview"
														src={metadata.url}
														alt=""
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'space-between',
														marginTop: 10,
													}}
												>
													<EditIcon
														htmlColor="var(--primary-color)"
														onClick={() => openEditModal(metadata)}
													/>
													<DeleteIcon
														color="error"
														onClick={() => deletePendingNft(metadata)}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
					<div
						style={{
							marginTop:
								metadatas.length > 0 && !displayImage && isMobile === false
									? -120
									: 0,
						}}
					>
						<h5> {i18n.t<string>('zone_admin.mintage.name')} </h5>
						<input
							style={{
								borderRadius: 10,
							}}
							value={name}
							onChange={(e) => setName(e.target.value)}
							type="text"
							className="w-50"
						/>
					</div>
					<div>
						<h5> {i18n.t<string>('zone_admin.mintage.description')} </h5>
						<textarea
							style={{
								borderRadius: 10,
							}}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>

					<div className="row">
						<div style={{ width: '50%' }}>
							<h5> {i18n.t<string>('zone_admin.mintage.price')} </h5>
							<CurrencyInput
								style={{
									borderRadius: 10,
								}}
								prefix="$"
								id="input-price"
								name="input-price"
								className="w-100"
								placeholder={i18n.t<string>('zone_admin.mintage.nftPrice')}
								decimalsLimit={2}
								onValueChange={(value, _) => setPrice(value)}
								value={price}
							/>
						</div>
						<div style={{ width: '10%' }}> </div>
						<div style={{ width: '40%' }}>
							<h5> {i18n.t<string>('zone_admin.mintage.quantity')} </h5>
							<NumericInput
								onChange={(value) => setQuantity(value)}
								style={{
									input: {
										borderRadius: 10,
									},
								}}
								min={1}
								max={1000}
								value={quantity}
								step={1}
							/>
						</div>
					</div>

					<div onClick={addAnotherNFT} className="btn text-truncate mt-5">
						{i18n.t<string>('zone_admin.mintage.addAnotherNFT')}
					</div>

					<div
						onClick={createNft}
						style={{ marginLeft: 10 }}
						className="btn text-truncate mt-5"
					>
						{i18n.t<string>('zone_admin.mintage.createNFT')}
					</div>
				</form>
			</div>
		);
	};

	const creatingCollection = () => (
		<section className="container">
			<div>
				<div>
					<CollectionTitle cancelCollectionCreation={handleOpenModal} />
					<CollectionLine />
					{NftForm()}
				</div>
			</div>
		</section>
	);

	const display = () => {
		if (screenLoader) {
			return (
				<section>
					<div className="d-flex justify-content-center align-items-center mt-4">
						<DnaLoader />
					</div>
				</section>
			);
		}

		if (isCreatingCollection) {
			return creatingCollection();
		}

		return (
			<CollectionNfts {...props} assets={assets} assets2={assets2} createCollection={createCollection} />
		);
	};
	return (
		<>
			{display()}
			<ModalLoader
				title={i18n.t<string>('zone_admin.mintage.creatingNFT')}
				show={loader}
				handleClose={() => setLoader(false)}
			/>
			<CancelCreationConfirmation
				show={showCancelModal}
				handleClose={handleCloseModal}
				confirmClose={cancelCollectionCreation}
			/>
			{nftToEdit && (
				<EditNFTModal
					show={showEditNft}
					handleClose={closeEditModal}
					confirmClose={confirmEditNft}
					metadata={nftToEdit}
				/>
			)}
		</>
	);
};

export default CollectionScreen;

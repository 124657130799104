import i18n from 'src/i18n';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import Viewer from 'src/presentation/components/Viewer/Viewer';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { NftUserItemDetailsUIController } from 'src/business/controller/ui/NftUserItemDetailsUIController';

interface Props {
	tokenAddress: string;
	tokenId: string;
	history: {
		push: Function;
	};
}

const NftUserItemDetails = (props:Props)=> {
	const {asset, handlePurchasedDate, setShowViewer, onViewDetails, showViewer } = NftUserItemDetailsUIController(props);
	if (asset === null) {
		return (
			<section>
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '50vh' }}
				>
					<DnaLoader />
				</div>
			</section>
		);
	}
	return (
		<>
			<section>
				<div className="container mt-3">
					<div className="d-flex flex-wrap justify-content-center">
						<div
							className="card d-flex justify-content-center align-items-center p-0"
							style={{
								overflow: 'hidden',
								maxHeight: '500px',
								maxWidth: '600px',
							}}
						>
							<img
								className="card-img-top"
								onClick={()=>{}}   //todo
								style={{
									width: isMobile ? undefined : '400px',
									height: '100%',
									objectFit: 'cover',
									borderRadius: 'initial',
								}}
								src={
									asset.image_url ??
									'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
								}
								alt=""
							/>
							<div
								className="card-inner d-flex align-items-center justify-content-center"
								style={{
									height: '100%',
									width: '100%',
									position: 'absolute',
									background: 'rgba(0,0,0,0.4)',
									cursor: 'pointer',
								}}
								onClick={() => setShowViewer(true)}
							>
								<i className="fas fa-eye fa-lg"></i>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								margin: '5%',
								marginTop: 30,
							}}
						>
							<div>
								<h1>
									{asset.name}{' '}
								</h1>
							</div>
							<div style={{ height: 1, backgroundColor: 'var(--primary-color)' }} />
							<div className="row mt-3">
								<span
									style={{
										fontSize: 20,
									}}
								>
									{i18n.t<string>('nft_marketplace_details.purchaseOn')}{' '}
									{handlePurchasedDate()}
								</span>
								<FileDownloadDoneIcon style={{ marginLeft: 10 }} />{' '}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									marginTop: 20,
								}}
							>
								<h4> {i18n.t<string>('nft_marketplace_details.createdBy')} </h4>
								<h4 style={{ marginRight: '15%' }}>
									{' '}
									{i18n.t<string>('nft_marketplace_details.collection')}{' '}
								</h4>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									padding: 5,
								}}
							>
								<div
									className="row"
									style={{
										border: '1px solid rgb(229, 232, 235)',
										borderRadius: 10,
										overflow: 'hidden',
										padding: 15,
									}}
								>
									<div
										className="rounded-circle"
										style={{
											height: '30px',
											width: '30px',
											overflow: 'hidden',
											marginRight: 5,
										}}
									>
										<img
											className="card-img-top"
											style={{
												height: '100%',
												width: '100%',
												objectFit: 'cover',
											}}
											src={
												'https://cdn.shopify.com/s/files/1/0033/6491/3198/files/Boutet_480.jpg?v=1610121638'
											}
											alt=""
										/>
									</div>
									<div
										style={{ color: 'var(--white-color)' }}
										className="col m-auto p-0"
									>
										{asset.createdBy || applicationConfiguration.saasName}
									</div>
								</div>
								<div
									className="row"
									style={{
										border: '1px solid rgb(229, 232, 235)',
										borderRadius: 10,
										overflow: 'hidden',
										padding: 15,
									}}
								>
									<div
										className="rounded-circle"
										style={{
											height: '30px',
											width: '30px',
											overflow: 'hidden',
											marginRight: 5,
										}}
									>
										<img
											className="card-img-top"
											style={{
												height: '100%',
												width: '100%',
												objectFit: 'cover',
											}}
											src={asset.collection.icon_url}
											alt=""
										/>
									</div>
									<div
										style={{ color: 'var(--white-color)' }}
										className="col m-auto p-0"
									>
										{asset.collection.name}
									</div>
								</div>
							</div>
							<div
								className="d-flex justify-content-center mt-5"
								style={{ marginBottom: 10 }}
							>
								<Button
									onClick={onViewDetails}
									style={{
										backgroundColor: 'var(--primary-color)',
										borderRadius: 20,
										width: '100%',
									}}
									variant="contained"
								>
									{i18n.t<string>('nft_marketplace_details.viewDetails')}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<Viewer
					imageURL={asset?.image_url ?? ''}
					isShowed={showViewer}
					showViewer={setShowViewer}
				/>
			</section>
		</>
	);
	
}

export default NftUserItemDetails;

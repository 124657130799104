import i18n from 'src/i18n';
import ChooseWallet from 'src/presentation/components/ChooseWallet';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import { NFT } from 'src/business/entities/NFT';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import NoWallet from 'src/presentation/components/NoWallet';
import CryptoSelectionController from 'src/business/controller/ui/CryptoSelectionController';

export interface CryptoSelectionProps {
	history: {
		push: Function;
	};
	location: {
		key: string;
		pathname: string;
		state: {
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
	};
}

const CryptoSelection = (props: CryptoSelectionProps): JSX.Element => {
	const {
		loading,
		hasWallet,
		wallets,
		onChooseWallet,
		connectWallet,
		connectedWallet,
	} = CryptoSelectionController(props);

	if (loading) {
		return (
			<section className="author-area explore-area popular-collections-area">
				<div className="container">
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<h1> {i18n.t<string>('payment.ChooseYourWallet')} </h1>

						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="author-area explore-area popular-collections-area">
			<div className="container">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div>
						<h1> {i18n.t<string>('payment.ChooseYourWallet')} </h1>
						<div
							style={{ backgroundColor: 'white', height: 3, color: "var(--primary-p-color)" }}
						></div>
					</div>
					{hasWallet() ? (
						<ChooseWallet
							connectedWallet={connectedWallet}
							wallets={wallets}
							onChooseWallet={onChooseWallet}
						/>
					) : (
						<NoWallet connectWallet={connectWallet} {...props} />
					)}
				</div>
			</div>
		</section>
	);
};

export default CryptoSelection;

import Wallet from 'src/business/entities/Wallet';
import { getShortAdress } from 'src/business/controller/logic/ShortWalletAdress';
import i18n from 'src/i18n';

interface Props {
	onChooseWallet: (wallet: Wallet) => void;
	wallets: Wallet[];
}

export default function DisconnectWallet({
	wallets,
	onChooseWallet,
}: Props): JSX.Element {
	return (
		<div>
			{wallets
				.filter((wallet) => wallet.connected === false)
				.map((wallet) => (
					<div
					className="container justify-content-center card my-4 p-4"
					style={{
						width: "100%",
						maxWidth: "600px",
						height: '250px',
						backgroundImage: 'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )'
					}}
				>
					<div className="card-title">
						<h5 className="m-0 d-flex align-items-center" style={{ 'color': 'white'}}>
							{i18n.t<string>('profil.tab_2.wallet')}
						</h5>
					</div>
					<div className="d-flex flex-wrap justify-content-between align-items-center m-2">
						<p style={{ margin: '1.5rem 0' }}>
							<>
								<img
									className="pr-2"
									style={{ height: '40px' }}
									src={
										wallet.type === 'MetaMask'
											? 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
											: './img/tokies_wallet.png'
									}
								></img> 
								<span style={{ 'color': 'white'}}> {wallet.type} </span>
							</>
						</p>
						<h5 style={{ 'color': 'white'}}>
								{wallet.solde} {i18n.t<string>('profil.tab_2.currency')}
						</h5>
						<div className="d-flex justify-content-center">
							<a
							style={{ 'color': 'white'}}
								className="btn btn-bordered-white btn-smaller m-2"
								onClick={() => onChooseWallet(wallet)}
							>
								<>{i18n.t<string>('payment.select')}</>
							</a>
						</div>
					</div>
					<div style={{ 'color': 'white'}} className="m-0 d-flex justify-content-between">
						{getShortAdress(wallet.publicKey)}
					</div>
				</div>
				))}
		</div>
	);
}

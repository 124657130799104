import { isMobile } from 'react-device-detect';
import { RouteComponentProps, withRouter } from 'react-router';
import RedirectIosModal from 'src/presentation/components/Modals/RedirectIosModal/RedirectIosModal';
import { PaymentWalletConnectUIController } from 'src/business/controller/ui/PaymentWalletConnectUIController';


const PaymentWalletConnect = (props: RouteComponentProps): JSX.Element => {
	
	const {walletData, walletDataMobile, showIosModal, setShowIosModal} = PaymentWalletConnectUIController(props);

	const data = {
		preHeading: 'CONNEXION DU PORTEFEUILLE',
		heading: 'Connecte ton portefeuille',
		content:
			'Choisissez une méthode de connexion pour vous connecter au marché',
	};


	if (isMobile) {
		return (
			<section className="wallet-connect-area">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 col-lg-7">
							{/* Intro */}
							<div className="intro text-center">
								<span>{data.preHeading}</span>
								<h3 className="mt-3 mb-0">{data.heading}</h3>
								<p>{data.content}</p>
							</div>
						</div>
					</div>
					<div className="row justify-content-center items">
						{walletDataMobile.map((item, idx) => {
							return (
								<div
									key={`wd_${idx}`}
									className="col-12 col-md-6 col-lg-4 item pe-auto"
								>
									<div className="card single-wallet">
										<a
											className="d-block text-center"
											href="#"
											onClick={item.connect}
										>
											<img className="avatar-lg" src={item.img} alt="" />
											<h4 className="mb-0">{item.title}</h4>
											<p>{item.content}</p>
										</a>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<RedirectIosModal
					show={showIosModal}
					onShowChange={(value: boolean) => {
						setShowIosModal(value);
					}}
				/>
			</section>
		);
	} else {
		return (
			<section className="wallet-connect-area">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 col-lg-7">
							{/* Intro */}
							<div className="intro text-center">
								<span>{data.preHeading}</span>
								<h3 className="mt-3 mb-0">{data.heading}</h3>
								<p>{data.content}</p>
							</div>
						</div>
					</div>
					<div className="row justify-content-center items">
						{walletData.map((item, idx) => {
							return (
								<div
									key={`wd_${idx}`}
									className="col-12 col-md-6 col-lg-4 item pe-auto"
								>
									<div className="card single-wallet">
										<a
											className="d-block text-center"
											href="#"
											onClick={item.connect}
										>
											<img className="avatar-lg" src={item.img} alt="" />
											<h4 className="mb-0">{item.title}</h4>
											<p>{item.content}</p>
										</a>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
		);
	}
};

export default withRouter(PaymentWalletConnect);

import i18n from 'src/i18n';
import { Modal } from 'react-bootstrap';

interface Props {
	show: boolean;
    remaining:number;
    onShowIdleModal: (value: boolean) => void;
}

const IdleModal = (props:Props)=> {

	const registerStillHere = async (e: any) => {
		e.preventDefault();
        handleClick();
	};


    const handleClick = () =>{
		props.onShowIdleModal(false);
	}
	return (
		<Modal
			size="lg"
			show={props.show}
			onHide={handleClick}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
                <Modal.Title className="ml-4">
					{i18n.t<string>('idle_modal.header').toString()}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
                <div className="col-12">
                    {i18n.t<string>("idle_modal.body") + " " +  props.remaining + " " + i18n.t<string>("idle_modal.secondes")}
                    <button
                        style={{ color: "var(--primary-p-color)" }}
                        className="btn w-100 mt-3 mt-sm-4"
                        type="submit"
                        onClick={registerStillHere}
                    >
                        {' ' + i18n.t<string>('idle_modal.confirmation').toString()}
                    </button>
                </div>
            </Modal.Body>
		</Modal>
	);
}

export default IdleModal;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getStripePK } from "src/env/SaasEnvironnement";
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { NFT } from "src/business/entities/NFT";
import Footer from "src/presentation/components/Footer/Footer";
import Header from "src/presentation/components/Header/Header";
import ModalMenu from "src/presentation/components/Modal/ModalMenu";
import Scrollup from "src/presentation/components/Scrollup/Scrollup";
import ConfirmPurchase from "src/presentation/components/ConfirmPurchase";
import { PaymentMethod } from "src/business/entities/PaymentMethod";
import Wallet from "src/business/entities/Wallet";
import { Address } from "src/business/entities/Address";

export interface PaymentConfirmationProps extends RouteComponentProps{
  location: {
    key: string;
    pathname: string;
    state: {
      address: Address;
      wallet: Wallet;
      paymentMethod: PaymentMethod;
      nft: NFT;
    };
    search: string,
    hash: string
  };
}


const PaymentConfirmation:FC<PaymentConfirmationProps> = (props: PaymentConfirmationProps): JSX.Element => {
  const stripePromise = loadStripe(
    //'pk_test_51LSV1SGtRHpLMmLmulnwvDxVOAQxA5B9espV21nbIwxxARq1LP5HJSxDtUMqZ6hErI9IVvAZA5tZCXETdl4Xlb6D00o3FTfmEf'   //stripe gab
    // 'pk_test_51Lwqk2BWT9DM9ccj0KQ7oNjcU10gDyt1vBOyma1qfTkFqCRzvCPaFfHmNERkE2mvb99cPKP1kIBteur9P0CduVlu004P9MGu9b'  //stripe etienne
    // 'pk_live_51IbdMDF56eRSpOjCz4b82WQDCwhvANFwi8oiO4En0DmvbEjDIEyixTasI2LlJZ1Bgi8TIB1djVS7idOKXVCoir8x00X1XDen1b'  //stripe live marc
    getStripePK()
  );
  return (
    <div className="main">
      <Header />
      <Elements stripe={stripePromise}>
        <ConfirmPurchase {...props} />
      </Elements>
      <Footer />
      <ModalMenu />
      <Scrollup />
    </div>
  );
};

export default PaymentConfirmation;

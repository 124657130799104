import axios, { AxiosError } from 'axios';
import { AxiosResponse } from 'axios';
import { storageService } from 'src/ApplicationContext';
import { Artist } from 'src/business/entities/Artist';
import { CancelOrder } from 'src/business/entities/CancelOrder';
import { Demand } from 'src/business/entities/Demand';
import { DemandOwnerType } from 'src/business/entities/DemandOwnerType';
import User from 'src/business/entities/User';
import Wallet from 'src/business/entities/Wallet';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { getBackEndUrl, getCollectionAddress } from 'src/env/SaasEnvironnement';
import { AcceptDemandRequest } from 'src/service/dto/request/AcceptDemandRequest';
import { CreateArtistRequest } from 'src/service/dto/request/CreateArtistRequest';
import { RefuseDemandRequest } from 'src/service/dto/request/RefuseDemandRequest';
const bcrypt = require('bcryptjs');

const backendURL: string = getBackEndUrl();

export default class TokiesService {
	static COLLECTION_ADDRESS = getCollectionAddress();

	static async getConversionRateCAD(): Promise<number> {
		return (
			await axios.get(`${backendURL}/Nft/conversion/1`, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	static async createUser(body: any) {
		return await axios.post(`${backendURL}/Users`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getUsers() {
		try {
			return await (await axios.get(`${backendURL}/Users`, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})).data;
		} catch {
			return { data: new Array() };
		}
	}

	static async getUserById(id: string): Promise<User> {
		return await (
			await axios.get(`${backendURL}/Users/${id}`, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	static async updateUser(body: any) {
		return await axios.put(`${backendURL}/Users`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async forgetPassword(body: any) {
		let changePasswordParams = {
			email: body.email,
			newPassword: bcrypt.hashSync(body.newPassword, 13),
		};
		return await axios.post(
			`${backendURL}/auth/forgetPassword`,
			changePasswordParams
		);
	}

	static async uploadImage(id: string, body: any) {
		return await axios.post(`${backendURL}/Users/uploadImage/${id}`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async createTransaction(body: any) {
		return await axios.post(`${backendURL}/Transaction`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getAllTransactionsForMarketPlace(marketId: string) {
		try {
			const request = await await axios.get(
				`${backendURL}/Transaction/market/${marketId}`,
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			);

			return request.data;
		} catch {
			return [];
		}
	}

	static async getTransactionsForUser(userId: string) {
		return await axios.get(`${backendURL}/Transaction/${userId}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async updateTransaction(id: string, body: any) {
		return await axios.patch(`${backendURL}/Transaction/${id}`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getWallets() {
		return await axios.get(`${backendURL}/wallet/`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async connectWallet(body: any) {
		return await axios.post(`${backendURL}/wallet/`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getWalletByUser(userId: string): Promise<Wallet[]> {
		const token = storageService.get_access_token();

		return await (
			await axios.get(`${backendURL}/wallet/users/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
		).data;
	}

	static async getWalletById(id: string) {
		return await axios.get(`${backendURL}/wallet/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async contactEmail(lang: string, body: any) {
		return await axios.post(
			`${backendURL}/mail/contact/${applicationConfiguration.marketId}/${lang}`,
			body,
			{
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			}
		);
	}
	static async postCreditCardSell(body: any) {
		return await axios.post(`${backendURL}/NFT/creditCardBuy`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async postCancelSell(body: any) {
		return await axios.post(`${backendURL}/NFT/cancel`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}
	static async postCancelSellCC(id: string, body: CancelOrder) {
		return await axios.post(`${backendURL}/NFT/cancelCC/${id}`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getContestById(id: string) {
		return await axios.get(`${backendURL}/contest/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async postContest(body: any) {
		return await axios.post(`${backendURL}/contest`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async updateContest(userId: string) {
		return await axios.patch(`${backendURL}/contest/${userId}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getCurrentMarket() {
		return this.getMarket(applicationConfiguration.marketId);
	}

	static async getMarket(id: string) {
		return await axios.get(`${backendURL}/marketPlace/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getAllMarket() {
		return await axios.get(`${backendURL}/marketPlace`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async buyNft(body: any) {
		return await axios.post(`${backendURL}/NFT`, body, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async cancelBuyNft(order_id: string, body: CancelOrder) {
		return await axios.post(
			`${backendURL}/NFT/cancel/${order_id}`,
			body,
			{
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			}
		);
	}

	static async createStripeAccount() {
		return await axios.post(
			`${backendURL}/Stripe/${applicationConfiguration.marketId}`,
			null,
			{
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			}
		);
	}

	static async getStripeAccount(id: string) {
		return await axios.get(`${backendURL}/Stripe/accounts/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getStripeBalance(id: string) {
		return await axios.get(`${backendURL}/Stripe/balance/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async validateAccount(stripeId: any) {
		return await axios.post(
			`${backendURL}/Stripe/validate/${applicationConfiguration.marketId}`,
			stripeId,
			{
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			}
		);
	}

	static async sendForgetPasswordEmail(lang: string, body: any) {
		return await axios.post(
			`${backendURL}/mail/forget/${applicationConfiguration.marketId}/${lang}`,
			body
		);
	}

	static async getPaymentIntent(id: string) {
		return await axios.get(`${backendURL}/Stripe/intent/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getPaymentMethod(id: string) {
		return await axios.get(`${backendURL}/Stripe/method/${id}`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getSession(body: any) {
		return await axios.post(`${backendURL}/Stripe/checkout/session`, body,{
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		});
	}

	static async getGallery() {
		return await (await axios.get(`${backendURL}/gallery`, {
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		})).data
	}

	static async matchAndSetArtistId(): Promise<number>{

		const artists = await TokiesService.getArtists();
		let artistId;
		for (const artist of artists) {
			if (artist.userId === +storageService.get_user_id()) {
				storageService.set_artist_id(artist.id.toString());
				artistId = artist.id;
				break;
			}
		}

		return artistId;
	}

	static async getArtists() : Promise<Artist[]>
	{
		return await (await axios.get(`${backendURL}/artist`, {
		})).data
	}

    static async createArtist(request:CreateArtistRequest) : Promise<void> {
		return (await axios.post(`${backendURL}/artist/create`, request)).data
    }

	static async getOpenDemandsByArtistId(id:number) : Promise<Demand[]>{
		return await (await axios.get(`${backendURL}/demand/artist/${id}`)).data
	}

	static async getAssociationsByArtistId(id:number) {
		return await(await axios.get(`${backendURL}/association/artist/${id}`)).data

	}
	static async createDemande(body:any) {
		return await (await axios.post(`${backendURL}/demand`, body,{
			headers: {
				Authorization: `Bearer ${storageService.get_access_token()}`,
			},
		})).data
	}
	static async acceptDemand(id:number, artistId:number): Promise<AxiosResponse> {
		try {

		const request: AcceptDemandRequest = {
			demandId: id,
			demandUserID: artistId,
			demandOwnerType: DemandOwnerType.Artist
		}

			return await (await axios.post(`${backendURL}/demand/accept`, request)).data
		} catch (e) {
			const error = e as AxiosError
			return error.response
		}
	}

	static async refuseDemand(id:number, artistId:number) {
		try{
			const request: RefuseDemandRequest= {
				demandId: id,
				demandUserID: artistId,
				demandOwnerType: DemandOwnerType.Artist
			}

			return await (await axios.post(`${backendURL}/demand/refuse`, request)).data
		} catch (e) {
			const error = e as AxiosError
			return error.response
		}
	}
}

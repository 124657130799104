import axios, { AxiosRequestConfig } from 'axios';
import { immutableProvider, storageService } from 'src/ApplicationContext';
import { getBackEndUrl } from 'src/env/SaasEnvironnement';
import Wallet from 'src/business/entities/Wallet';

class WalletService {

    backendURL: string = getBackEndUrl();

	async getWalletByUser(userId: number): Promise<Wallet[]> {
		try {
			const result = await axios.get(
				`${this.backendURL}/wallet/users/${userId}`,
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			);

			return result.data;
		} catch {
			return [];
		}
	}

	async changeConnectedWallet(
		userId: number,
		walletId: number
	): Promise<Wallet[]> {
		const token = storageService.get_access_token();

		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${this.backendURL}/wallet/change`,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				walletId: walletId,
				userId: userId,
			}),
		};

		const result = await axios(config);
		return result.data;
	}

	async deleteWallet(userId: number, walletId: number): Promise<Wallet[]> {
		const token = storageService.get_access_token();

		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${this.backendURL}/wallet/delete`,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				walletId: walletId,
				userId: userId,
			}),
		};

		try {
			const result = await axios(config);
			return result.data;
		} catch {
			return [];
		}
	}

	async getSolde(walletAddress: string): Promise<string> {
		return immutableProvider.getBalance(walletAddress);
	}

	async hasCustodialWallet(userId: number): Promise<boolean> {
		const wallets = await this.getWalletByUser(userId);
		const custodialWallet = wallets.find(
			(wallet) => wallet.type === 'custodial'
		);
		return custodialWallet ? true : false;
	}

	async createCustodialWallet(userId: number, email: string): Promise<Wallet> {
		const token = storageService.get_access_token();

		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${this.backendURL}/wallet/custodial`,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				userId,
				email,
			}),
		};

		const result = await axios(config);

		const wallet: Wallet = result.data;

		return wallet;
	}
}

export default WalletService;

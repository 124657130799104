import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import TokiesService from 'src/service/TokiesService';

export function ForgetPasswordUIController(props: RouteComponentProps) {
	const [emailvalidationError, setEmailvalidationError] = useState<string>('');
	const [validationError, setValidationError] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');

	const { t } = useTranslation();

	const userLogicController = new UserLogicController();

	const emailValidation = (): boolean => {
		const emailValidated = userLogicController.emailIsValid(email);
		if (emailValidated) {
			setEmailvalidationError(t<string>('password.messageEmailNotValid'));
			return emailValidated;
		} else {
			setEmailvalidationError('');
			return emailValidated;
		}
	};

	const forgetPasswordSubmit = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (emailValidation()) {
			e.preventDefault();
			if (newPassword == confirmPassword) {
				const results = await TokiesService.forgetPassword({
					email: email,
					newPassword: newPassword,
				})
					.then((result: any) => {
						return result.data;
					})
					.catch((res: any) => {
						return res.message;
					});
				if (results.success) {
					setValidationError(
						t<string>('password.messagePasswordChangeCorrectly')
					);
					userLogicController.logout(props);
				} else {
					setValidationError(
						t<string>('password.messageIncorrectEmailOrPassword')
					);
				}
			} else {
				setValidationError(t<string>('password.messageNotSamePassword'));
			}
		}
	};

	return {
		t,
		email,
		setEmail,
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		emailvalidationError,
		validationError,
		forgetPasswordSubmit,
	};
}

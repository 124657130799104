import { Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Props {
	show: boolean;
    onShowIdleModal: (value: boolean) => void;
    onClickArtistCreateProfile: () => Promise<void>;
}


const CreateArtistProfileModal = (props:Props)=> {
	const { t } = useTranslation();

    const handleClick = async () =>{
        await props.onClickArtistCreateProfile();
		toast.promise(async () => {}, {
			success: {
				render: t<string>('zone_admin.artistProfile.creationSuccess'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
		props.onShowIdleModal(false);
	}
    const handleClose = async () =>{
		props.onShowIdleModal(false);
	}
	return (
		<Modal
			show={props.show}
			contentClassName="rounded"
			onHide={handleClose}
			centered
			scrollable={true}
		>
			<Modal.Header>
                <Modal.Title >
					{t<string>('zone_admin.artistProfile.importantMentions')}
				</Modal.Title>
				<Typography >
					<div>
						<h4>{t<string>('zone_admin.artistProfile.readCarefully')} </h4> 
					</div>

				</Typography>
			</Modal.Header>
			<Modal.Body >
				
    			<Box >
				  	<div>
                        <span>{t<string>('zone_admin.artistProfile.body')}</span>
                        <br/>
                        <br/>
                        <br/>
						<button style={{ color: "var(--primary-p-color)" }} className="btn " onClick={handleClick}>
							<span>{t<string>('zone_admin.artistProfile.accept')}</span>
						</button>
					</div>
    			</Box>
            </Modal.Body>
		</Modal>
	);
}

export default CreateArtistProfileModal;

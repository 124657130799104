import { toast } from 'react-toastify';
import { authenticationService, metamaskAuthentication } from 'src/ApplicationContext';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import { LoginModalProps } from 'src/presentation/components/Modals/LoginModal/LoginModal';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'src/i18n';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function LoginModalUIController(props: LoginModalProps) {
	const [mfaRequired, setMfaRequired] = useState(false);
	const [emailValidationError, setEmailValidationError] = useState<string>('');
	const [loginValidationError, setLoginValidationError] = useState<string>('');
	const [passwordValidationError, setPasswordValidationError] =
		useState<string>('');
	const [wrongMfaCodeError, setWrongMfaCodeError] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [connecting, setConnecting] = useState(false);
	const userLogicController = new UserLogicController();

	const [public_key, set_public_key] = useState<string>();
	const [message, setMessage] = useState<string>();

	const passwordChangeEmailSent = false; //TODO je sais pas a quoi ca sert exactement...

	const connectWithMetamask = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		const public_key = await metamaskAuthentication.get_public_key();
		const signature = await metamaskAuthentication.create_signature_request(
			public_key
		);

		setMessage(signature.message);
		set_public_key(signature.public_key);
	};

	const { t } = useTranslation();

	const handleForgetPassword = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		e.preventDefault();
		props.onShowLogin(false);
		props.onShowForgotPasswordModal();
	};

	const handleRegisterButton = () => {
		props.onShowLogin(false);
		props.onShowRegister(true);
	};

	const onClick = () => {
		window.location.replace('https://tokies.io/');
	};

	const onGoogleError = () => {
		toast.promise(
			async () => {
				throw Error();
			},
			{
				error: {
					render: i18n.t<string>('login.errorLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			}
		);

		handleClose(props);
	};

	const loginWithAuthService = async (
		email: string,
		password: string,
		props: LoginModalProps
	) => {
		setConnecting(true);

		const results: boolean = await authenticationService.login_with_email(
			email,
			password
		);

		if (results) {
			toast.promise(async () => {}, {
				success: {
					render: i18n.t<string>('login.userLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
			if(props.returnProfil){
				props.history.push('/profil')
			}
			handleClose(props);
		} else {
			setLoginValidationError(
				i18n.t<string>('login.messageIncorrectEmailOrPassword')
			);
			setConnecting(false);
		}
	};

	const handleLogin = async (
		email: string,
		password: string,
		props: LoginModalProps
	) => {
		if (password === '') {
			setPasswordValidationError(
				i18n.t<string>('login.messagePasswordRequired')
			);
			return;
		}
		if (userLogicController.emailIsValid(email)) {
			const mfaRequired = await loginWithMfaCheck(email, password);

			if (mfaRequired) return;
			await loginWithAuthService(email, password, props);
		}
	};

	const confirmCode = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		email: string,
		code: string,
		props: LoginModalProps
	) => {
		e.preventDefault();
		let results = await authenticationService.confirm_mfa_sign_in(email, code);

		if (results) {
			toast.promise(async () => {}, {
				success: {
					render: i18n.t<string>('login.userLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
			if(props.returnProfil){
				props.history.push('/profil')
			}
			handleClose(props);
		} else {
			setWrongMfaCodeError(i18n.t<string>('login.wrongMfaCodeError'));
			setConnecting(false);
		}
	};

	const loginWithMfaCheck = async (email: string, password: string) => {
		const mfaRequired = await authenticationService.trigger_mfa_sign_in(
			email,
			password
		);

		if (mfaRequired) {
			setMfaRequired(true);
		}
		return mfaRequired;
	};

	const emailValidation = (email: string) => {
		if (email === '') {
			setEmailValidationError(i18n.t<string>('login.messageEmailRequired'));
			return false;
		}
		if (userLogicController.emailIsValid(email)) {
			setEmailValidationError(i18n.t<string>('login.messageEmailNotValid'));
			return false;
		} else {
			setEmailValidationError('');
			return true;
		}
	};

	const handleClose = (props: LoginModalProps) => {
		props.onShowLogin(false);
	};

	const callback_google_login = () => {
		toast.promise(async () => {}, {
			success: {
				render: i18n.t<string>('login.userLogin'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
		if(props.returnProfil){
			props.history.push('/profil')
		}
		handleClose(props);
	};

	const loginSubmit = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		email: string,
		password: string,
		props: LoginModalProps
	) => {
		e.preventDefault();
		handleLogin(email, password, props);
	};

	return {
		loginSubmit,
		callback_google_login,
		handleClose,
		emailValidation,
		confirmCode,
		onClick,
		handleRegisterButton,
		handleForgetPassword,
		loginWithAuthService,
		loginWithMfaCheck,
		emailValidationError,
		loginValidationError,
		passwordValidationError,
		wrongMfaCodeError,
		mfaRequired,
		connecting,
		code,
		setCode,
		email,
		setEmail,
		password,
		setPassword,
		t,
		onGoogleError,
		public_key,
		set_public_key,
		message,
		setMessage,
		passwordChangeEmailSent,
		connectWithMetamask
	};
}

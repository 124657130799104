import { useEffect, useState } from 'react';
import NFTAsset from 'src/business/entities/NFTAsset';

export function PaintingsUIController() {
	const [sortedElement, setSortedElement] = useState<NFTAsset[]>([]);
	const [searchByName, setSearchByName] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [paintings, setPaintings] = useState<NFTAsset[]>([]);
	const [showLogin, setShowLogin] = useState(false);


	const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && searchByName !=='') {
			e.preventDefault();
			filterPaintaing(searchByName)
		}
		else if(searchByName !==''){
			setSortedElement(paintings)
		}
	};

	const updateAsset = (assets:NFTAsset[]) => {
		setPaintings(assets)
		setSortedElement(assets)
	}
	const filterPaintaing = async (filter: string) => {
		let newTransactions: NFTAsset[] = [];
		paintings.forEach((element) => {
			if (
				element.name?.toLowerCase().includes(filter.toLowerCase()) ||
				element.status?.toLowerCase().includes(filter.toLowerCase()) ||
				element.metadata?.price?.toString().includes(filter)
			)
				newTransactions.push(element);
		});
		setSortedElement(newTransactions);
	};
	
	const getData = async () => {
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	return {
		loading,
		searchByName,
		onSearchKeyDown,
		setSearchByName,
		updateAsset,
		sortedElement,
		paintings,
		showLogin,
		setShowLogin
	};
}

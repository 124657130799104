import 'react-tabs/style/react-tabs.css';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { storageService } from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';
import { AuthorController } from 'src/business/controller/ui/AuthorUIController';
interface Props {
	history: {
		push: Function;
	};
}
const AuthorNFT = (props:Props) => {
	const {
		loading,
		assets,
		setCurrentNFT,
		setShowText,
		t,
		currentNFT,
		showText,
	} = AuthorController();
	
	const onViewDetails = (e:any,asset:NFTAsset) => {
		e.preventDefault();
		props.history.push('/nft/details', {
			asset: asset,
		});
	};

	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center mt-4">
				<DnaLoader />
			</div>
		);
	}
	if (!storageService.get_wallet_address()) {
		return (
			<div>
				<h3 className="card text-center">{t<string>('profil.noWallet')}</h3>
			</div>
		);
	}
	return (
		<div>
			<div>
				{assets.length > 0 ? (
					<div className="row items explore-items">
						{assets.map((item: NFTAsset, idx: number) => {
							return (
								<div
									key={`eds_${idx}`}
									className="col-12 col-md-6 item explore-item"
								>
									{item.metadata ? (
										<div
											className="card text-center p-0"
											onMouseEnter={() => {
												setCurrentNFT(assets[idx].id);
												setShowText(true);
											}}
											onMouseLeave={() => {
												setShowText(false);
											}}
											style={{
												height: '240px',
												overflow: 'hidden',
											}}
										>
											<img
												className="card-img-top"
												style={{
													height: '100%',
													width: '100%',
													objectFit: 'contain',
												}}
												src={
													item.image_url ??
													'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
												}
												alt=""
											/>
											<a
												// href={`profil/item-details/${item.token_address}/${item.token_id}`}
												onClick={(e)=>{onViewDetails(e,assets[idx])}}
												style={{
													position: 'absolute',
													top: '0',
													left: '0',
													width: '100%',
													height: '100%',
													opacity: `${
														currentNFT === assets[idx]?.id && showText
															? '1'
															: '0'
													}`,
													background: 'rgba(0,0,0,0.3)',
												}}
											>
												<span
													style={{
														position: 'absolute',
														top: '50%',
														left: '50%',
														transform: 'translate(-50%,-50%)',
													}}
												>
													<h5 className="mb-2 ">
														{item.name ? item.name : 'No Name'}
													</h5>
												</span>
											</a>
										</div>
									) : (
										<></>
									)}
								</div>
							);
						})}
					</div>
				) : (
					<div className="d-flex justify-content-center align-items-center">
						<div>
							<h3> {t<string>('profil.noNFT')} </h3>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AuthorNFT;

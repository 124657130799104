
import { useEffect, useState } from "react";
import { storageService } from "src/ApplicationContext";
import { Artist } from "src/business/entities/Artist";
import TokiesService from "src/service/TokiesService";
import { CreateArtistRequest } from "src/service/dto/request/CreateArtistRequest";

export function ArtistProfileTabUIController() {
    const [artist, setArtist] = useState<Artist>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [artistIdNotSet, setArtistIdNotSet] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const defaultArtistRequest:CreateArtistRequest = {
        artistName: "Picasso" + (Math.floor(Math.random() * 90 + 10)).toString(),
        ownerName: "Pablo Picasso",
        phoneNumber: "514-123-4567",
        userId: +storageService.get_user_id() as number,
        marketplaceId: 1,
    }

    const getData = async () => {
        setLoading(true)
        const artistId = +storageService.get_artist_id()
        if (!artistId) {
            setArtistIdNotSet(true)
            setLoading(false)
            return;
        }

        const artists = await TokiesService.getArtists()
        for (const artist of artists) {
            if (artist.id == artistId) {
                setArtist(artist)
                break;
            }
        }

        setLoading(false);
    }

   const onClickArtistCreateButton = async () => {
        setLoading(true)
        await TokiesService.createArtist(defaultArtistRequest)
        await TokiesService.matchAndSetArtistId()
        setArtistIdNotSet(false)
        await getData()
    }

    useEffect(() => {
        getData();
    }, [])

    return {
        artist,
        showModal,
        setShowModal,
        loading,
        artistIdNotSet,
        onClickArtistCreateButton
    }
}
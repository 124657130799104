import i18n from 'src/i18n';
import { useEffect } from 'react';
import { Address } from 'src/business/entities/Address';
import { NoAdressFound } from 'src/presentation/components/NoAdressFound';
import AdressCard from 'src/presentation/components/AdressCard';
import { NFT } from 'src/business/entities/NFT';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import Wallet from 'src/business/entities/Wallet';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { RouteComponentProps } from 'react-router';
import { AdressFormUIController } from 'src/business/controller/ui/AdressFormUIController';

export interface AdressFormProps extends RouteComponentProps {
	location: {
		key: string;
		pathname: string;
		state: {
			wallet: Wallet;
			nft: NFT;
			paymentMethod: PaymentMethod;
		};
		search: string;
		hash: string;
	};
	didntChange:boolean
}

const AdressForm = (props: AdressFormProps): JSX.Element => {
	const { paymentMethod, nft, wallet } = props.location.state;

	const {
		verifyAddress,
		userAdress,
		confirmAdress,
		loading,
		createAdress,
		setAdress,
		setCity,
		setCountry,
		setFirstname,
		setLastName,
		setNumeroAppartement,
		setPhoneNumber,
		setState,
		setZip,
		userId,
		numeroAppartement,
		phoneNumber,
		city,
		country,
		lastName,
		adress,
		firstName,
		zip,
		state,
	} = AdressFormUIController(props);

	useEffect(() => {
		verifyAddress(props, wallet, nft, paymentMethod);
	}, []);

	const DefaultAdress = (): JSX.Element => {
		return userAdress.length === 0 ? (
			<NoAdressFound />
		) : (
			<div>
				<div>
					<p className="text-uppercase fw-bold text-font text-center">
						{i18n.t<string>('address.registeredAddress')}
					</p>
					<div
						className="my-4 mx-auto w-75"
						style={{
							borderTop: '1px solid rgba(255,255,255,0.4)',
						}}
					></div>
				</div>
				{userAdress.map((address) => (
					<AdressCard
						onSelectAdress={(adress: Address) =>
							confirmAdress(adress, props, wallet, nft, paymentMethod)
						}
						address={address}
					/>
				))}
				<div
					className="my-4"
					style={{
						borderTop: '1px solid rgba(255,255,255,0.4)',
					}}
				></div>
			</div>
		);
	};

	if (loading) {
		return (
			<section className="author-area">
				<div className="container">
					<div className="d-flex justify-content-center">
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="author-area">
			<div className="container">
				<div>
					<div className="intro text-center">
						<span> {i18n.t<string>('address.address')}</span>
						<h3 className="mt-3 mb-0">
							{i18n.t<string>('address.selectDeliveryAddress')}
						</h3>
					</div>
					<div className="d-flex flex-wrap">
						<div className="p-4" style={{ width: '100%', maxWidth: '350px' }}>
							{DefaultAdress()}
						</div>
						<div className="col">
							<form className="item-form card no-hover">
								<div className="col" style={{ fontSize: 20 }}>
									<b className="col-4">
										{' '}
										{i18n.t<string>('address.addAddress')}{' '}
									</b>
								</div>
								<div className="row">
									<div className="row col-12" style={{ marginTop: 20 }}>
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="FirstName"
												placeholder="First name"
												onChange={(e) => setFirstname(e.target.value)}
											/>
										</div>
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="LastName"
												placeholder="Last name"
												onChange={(e) => setLastName(e.target.value)}
											/>
										</div>
									</div>
									<div className="row col-12">
										<div className="form-group mt-3 col-8">
											<input
												type="text"
												className="form-control"
												placeholder="Address"
												required={true}
												onChange={(e) => setAdress(e.target.value)}
											/>
										</div>
										<div className="form-group mt-3 col-4">
											<input
												type="text"
												className="form-control"
												placeholder="# appartment"
												required={true}
												onChange={(e) => setNumeroAppartement(e.target.value)}
											/>
										</div>
									</div>

									<div className="row col-12">
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="City"
												placeholder="City"
												onChange={(e) => setCity(e.target.value)}
											/>
										</div>
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="ZIP"
												placeholder="Postal code"
												onChange={(e) => setZip(e.target.value)}
											/>
										</div>
									</div>

									<div className="row col-12">
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="Country"
												placeholder="Country"
												onChange={(e) => setCountry(e.target.value)}
											/>
										</div>
										<div className="form-group mt-3 col-6">
											<input
												type="text"
												className="form-control"
												name="State"
												placeholder="State"
												onChange={(e) => setState(e.target.value)}
											/>
										</div>
									</div>

									<div className="row col-12">
										<div className="form-group mt-3 col-12">
											<input
												type="tel"
												className="form-control"
												name="Telephone"
												placeholder="Phone number"
												onChange={(e) => setPhoneNumber(e.target.value)}
											/>
										</div>
									</div>

									<span className="text-danger"></span>

									<div className="col-12">
										<button
											style={{ color: "var(--primary-p-color)" }}
											className="btn w-100 mt-3 mt-sm-4"
											type="submit"
											onClick={(e: any) =>
												createAdress(
													e,
													userId,
													numeroAppartement,
													adress,
													city,
													zip,
													country,
													firstName,
													lastName,
													phoneNumber,
													state,
													wallet,
													nft,
													paymentMethod,
													props
												)
											}
										>
											{i18n.t<string>('address.confirm')}
										</button>
									</div>
								</div>
								<hr />
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AdressForm;


import { PaymentInformationProps } from 'src/business/controller/ui/PaymentInformationUIController';
import { NFT } from 'src/business/entities/NFT';
import { NftPreviewSection } from 'src/presentation/components/NftBasketSection';
import PaymentInformation from 'src/presentation/components/PaymentInformation';

const PaymentItem = (props: PaymentInformationProps): JSX.Element => {
	const nft: NFT = props.nft;

	return (
		<section className="author-area explore-area popular-collections-area">
			<div className="d-flex flex-wrap justify-content-center align-items-center" style={{position: "relative"}}>
				<NftPreviewSection nft={nft} />
				<PaymentInformation {...props} nft={nft} />
			</div>
		</section>
	);
};

export default PaymentItem;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import i18n from 'src/i18n';
import TokiesService from 'src/service/TokiesService';

export function ContactUIController() {
	const [isRobot, setIsRobot] = useState(true);
	const [email, setEmail] = useState<string>();
	const [name, setName] = useState<string>();
	const [subject, setSubject] = useState<string>();
	const [message, setMessage] = useState<string>();

	const { t } = useTranslation();

	const userLogicController = new UserLogicController();

	const sendContactEmail = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (isRobot) {
			window.alert('Please confirm you are not a robot');
			e.preventDefault();
			return;
		}

		if (userLogicController.emailIsValid(email)) {
			e.preventDefault();
			TokiesService.contactEmail(i18n.language, {
				email: email,
				name: name,
				subject: subject,
				message: message,
			});
			setEmail('');
			setName('');
			setSubject('');
			setMessage('');
		}
	};

	return {
		sendContactEmail,
		setIsRobot,
		name,
		setName,
		email,
		setEmail,
		subject,
		setSubject,
		message,
		setMessage,
		t,
	};
}

import Wallet from 'src/business/entities/Wallet';
import { getShortAdress } from 'src/business/controller/logic/ShortWalletAdress'; 
import i18n from 'src/i18n';

interface Props {
    wallet: Wallet;
    changeWallet: () => void;
}

const WalletConfirmation = ({ 
	wallet, 
	// changeWallet
}: Props) => {
	return <div
		className="card pb-auto w-100 h-100 d-flex"
		style={{
			height: '100%',
			backgroundImage: 'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
		}}
	>
		<div className="card-title">
			<div className="row">
				<div className="col-7 p-0">
					<h3 className="mt-0">
						{i18n.t<string>('profil.tab_2.wallet')}
					</h3>
				</div>
				{/* <div className="col-5 p-0">
					<a
						onClick={changeWallet}
						className="m-2 d-flex align-items-center"
						style={{
							textDecoration: 'underline',
							cursor: 'pointer',
						}}
					>
						<p className="text-right w-100">
							{i18n.t<string>('payment.change').toString()}
						</p>
					</a>
				</div> */}
			</div>
		</div>
		<div className="row justify-content-between align-items-center h-100">
			<div className="col">
				<p style={{ margin: '1.5rem 0' }}>
					<>
						<img
							className="pr-2"
							style={{
								height: '40px',
							}}
							src={wallet.type === 'MetaMask'
								? 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
								: 'img/tokies_wallet.png'}
						></img>
						{wallet.type}
					</>
				</p>
			</div>
			<div className="col">
				<h5>
					<>
						{wallet.solde} {i18n.t<string>('profil.tab_2.currency')}
					</>
				</h5>
			</div>
			<div style={{ color: '#28a745' }}></div>
		</div>
		<div className="row justify-content-between align-items-center h-100">
			<div className="col">
				{getShortAdress(wallet.publicKey)}
			</div>
		</div>
	</div>;
}

export default WalletConfirmation;
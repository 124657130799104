import { Dispatch, SetStateAction, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import NoCollection from 'src/presentation/components/NoCollection';
import { useTheme } from '@mui/material/styles';
import NFTAsset from 'src/business/entities/NFTAsset';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { RouteComponentProps } from 'react-router';
import PaintingsTab from './GallerieTabs/PaintingsTab';

interface Props extends RouteComponentProps {
	assets: NFTAsset[];
	assets2: NFTAsset[];
	createCollection: () => void;
}

const CollectionNfts = ( props : Props) => {
	const theme = useTheme();
	const [currentAsset, setCurrentAsset] = useState(props.assets);
	const [tabsValue, setTabsValue] = useState(0);
	const [isSold, setIsSold] = useState(false);
	const { t } = useTranslation();

	if ((!props.assets || props.assets.length === 0)&&(!props.assets2 || props.assets2.length === 0)) {
		return <NoCollection createCollection={props.createCollection} />;
	}

	const handleChangeIndex = (index: number) => {
		setTabsValue(index);
	};
	const switchComponent = (updateElement:(assets:any[])=>void,setSearchByName:Dispatch<SetStateAction<string>>) =>{
		return (
			<div>
				<span> {t<string>('zone_admin.tableaux.selling')}   </span>
				<FormControlLabel control={<Switch value={isSold} onChange={() => {
					setIsSold(!isSold)
					if(!isSold){
						setCurrentAsset(props.assets2)
						updateElement(props.assets2)
						setSearchByName('')
					}
					else{
						setCurrentAsset(props.assets)
						updateElement(props.assets)
						setSearchByName('')
					}
					}}/>} label={t<string>('zone_admin.tableaux.sold')}  />
			</div>

		)
	}
	
	
	return (
		<section className="explore-area mt-0 pt-0">
			<div
			className='align-items-center'
				style={{
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>	
				<div
				>
					<h3> {t<string>('zone_admin.mintage.nftCollection')} </h3>
				</div> 
				<div>
					<div onClick={props.createCollection} className="btn mt-3">
						{t<string>('zone_admin.mintage.createNFT')}
					</div>
				</div>
			</div>
			<div
				style={{
					width: '100%',
					height: 2,
					backgroundColor: 'var(--primary-color)',
				}}
			>
			</div>
			<div className="container">
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tabsValue}
					onChangeIndex={handleChangeIndex}
				>
					<PaintingsTab 
							{...props}
							assets={currentAsset}
							searchBar={true}
							switchComponent={switchComponent}
						/>
				</SwipeableViews>
				</div>
		</section>
	);
};

export default CollectionNfts;

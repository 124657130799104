import { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import i18n from 'src/i18n';


interface Props {
	show: boolean,
	onShowChange:(value:boolean)=>void
}

const RedirectIosModal = (props:Props)=> {
	const [copied, setCopied] = useState(false);

	const onCopy = (e:any) => {
		e.preventDefault();
		navigator.clipboard.writeText(i18n.t<string>("modal.redirectIos.link"));
		setCopied(true)
	}

	const handleClose = () =>{
		props.onShowChange(false);
	}

		return (
			<Modal
				show={props.show}
				onHide={handleClose}
				contentClassName="rounded w-auto mx-4"
				centered
				scrollable={true}
			>
				<Modal.Header>
					<Modal.Title className="ml-2">{i18n.t<string>("modal.redirectIos.title")}</Modal.Title>
					<button
						onClick={handleClose}
						className="btn  rounded-circle"
						style={{ padding: '16px 16px 16px 16px' }}
					>
						<i className="mx-1 fa fa-times"></i>
					</button>
				</Modal.Header>
				<Modal.Body>
					<Form noValidate>
						<div className=" mb-4">
							<span>
								{i18n.t<string>("modal.redirectIos.text")}
							</span>
							<div className="py-2"></div>
							<div>
								<i>
									{i18n.t<string>("modal.redirectIos.note")}
								</i>
							</div>
						</div>

						<div className="pt-4 d-flex flex-column justify-content-center">
							<button
								className="btn btn-bordered-white text-truncate"
								type="button"
								onClick={onCopy}
							>
								<span>{i18n.t<string>("modal.redirectIos.link")}</span>
								<i className="mx-2 fa fa-clipboard"></i>
							</button>
							<span
								className="mx-auto mt-2 text-success"
								hidden={!copied}
							>
								{i18n.t<string>("modal.redirectIos.copy")}
							</span>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		);
	
}

export default RedirectIosModal;

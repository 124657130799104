import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { paymentService, storageService } from 'src/ApplicationContext';
import { ConfirmPurchaseProps } from 'src/presentation/components/ConfirmPurchase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TokiesService from 'src/service/TokiesService';
import { AxiosResponse } from 'axios';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';

function ConfirmPurchaseController(props: ConfirmPurchaseProps) {
	const { nft, address, paymentMethod, wallet } = props?.location?.state;
	const elements = useElements();
	const stripe = useStripe();
	const [purchaseInProgress, setPurchaseInProgress] = useState(false);
	const [canBuy, setCanBuy] = useState<boolean>(true);
	const [taxeProv, setTaxeProv] = useState<number>(0);
	const [taxeGouv, setTaxeGouv] = useState<number>(0);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [showViewer, setShowViewer] = useState<boolean>(false);
	const [shipping_price, set_Shipping_price] = useState<number>(0);
	const [nftPriceCan, setNftPriceCan] = useState<number>(0);
	const shipping_rate = 0.06;

	const { t } = useTranslation();

	useEffect(() => {
		try {
			const nftPrice = Number(nft.price);
			const walletFund = Number(wallet.solde);

			const nft_Can_price = parseInt(nft.priceCan);

			const shipping_price = nft_Can_price * shipping_rate;

			const taxeProv =
				(nft_Can_price + shipping_price) * paymentService.tauxProv;
			const taxeGouv =
				(nft_Can_price + shipping_price) * paymentService.tauxGouv;
			const total = nft_Can_price + shipping_price + taxeProv + taxeGouv;

			setNftPriceCan(nft_Can_price);
			set_Shipping_price(shipping_price);
			setTotalPrice(total);
			setTaxeProv(taxeProv);
			setTaxeGouv(taxeGouv);

			if (nftPrice > walletFund) {
				setCanBuy(false);
			}
		} catch {}
	}, []);

	const makeCryptoPayment = async () => {
		const promise = paymentService.makePayment(paymentMethod, nft, wallet);

		toast
			.promise(promise, {
				pending: {
					render: (
						t<string>('payment.confirm.transaction_pending')
					),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
				success: {
					render: (
						t<string>('payment.confirm.transaction_succeed')
					),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
				error: {
					render: t<string>('payment.confirm.transaction_error'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			})
			.then(() => goToConfirmationPage());
		promise.catch(async (order_details) => {
			if(order_details.data) {
				await TokiesService.cancelBuyNft(
					order_details.data["orderId"], 
					{
						buyerAddress: wallet.publicKey,
						transactionUUID: order_details.data['transactionUUID'],
					}
				);	
			}
			setPurchaseInProgress(false);
		});
		setPurchaseInProgress(true);
	};

	const transactionTreatment = async (clientSecret: AxiosResponse<any>) => {
		const intentInfo = await TokiesService.getPaymentIntent(
			clientSecret.data['idIntent']
		);
		const cardInfo = await TokiesService.getPaymentMethod(
			(
				await intentInfo
			).data.payment_method
		);
		const cardDetail = (await cardInfo).data.card;
		await TokiesService.createTransaction({
			nftName: nft.name,
			tokenid: nft.token_id,
			priceETH: String(nft.price),
			priceCAD: String(nft.priceCan),
			checked: false,
			userId: storageService.get_user_id(),
			marketId: applicationConfiguration.marketId,
			walletId: wallet.id,
			NFTId: -1,
			type: cardDetail.brand,
			cardNumber: cardDetail.last4,
			expiracy: '' + cardDetail.exp_month + '/' + cardDetail.exp_year,
			holder: null,
		});
	};
	const handleErrorCC = async (errorMessages: string, transactionUUID: string, buyerAddress: string) => {
		toast(errorMessages, {
			position: toast.POSITION.BOTTOM_LEFT,
			pauseOnHover: false,
			theme: 'dark',
		});
		await TokiesService.postCancelSellCC(nft.token_id, {
			buyerAddress: buyerAddress,
			transactionUUID: transactionUUID,
		});
	};

	const changePaymentMethod = (): void => {
		if (paymentMethod === PaymentMethod.Crypto) {
			props.history.push('/cryptoPayment', {
				nft,
				paymentMethod,
			});
			return;
		}

		props.history.push('/select-wallet-payment', {
			nft,
			paymentMethod,
		});
	};

	const changeAddress = (): void => {
		props.history.push('/address', {
			wallet,
			paymentMethod,
			nft,
		});
	};

	const makeCreditCardPayment = async (e: any) => {
		e.preventDefault();

		if (!elements || !stripe) {
			return;
		}

		const cardElement = elements.getElement(CardElement);
		if (cardElement) {
			setPurchaseInProgress(true);
			var clientSecret;

			try {
				clientSecret = await TokiesService.postCreditCardSell({
					buyerAddress: wallet.publicKey,
					tokenAddress: nft.token_address,
					tokenId: nft.token_id,
					marketId: applicationConfiguration.marketId,
				});
				
				const confirmation = await stripe.confirmCardPayment(
					clientSecret.data['client_secret'],
					{
						payment_method: {
							card: cardElement,
						},
					}
				);
				if (confirmation.error) {
					handleErrorCC(
						t<string>('payment.errorMessages.' + confirmation.error.code),
						clientSecret.data['transactionUUID'],
						wallet.publicKey
					);
				} else {
					try {
						transactionTreatment(clientSecret);
						goToConfirmationPage();
					} catch (error) {
						handleErrorCC(
							t<string>('payment.confirm.transaction_error'),
							clientSecret.data['transactionUUID'],
							wallet.publicKey
						);
	
					}
				}
			} catch (error) {
				var uuid = ""
				if(clientSecret) {
					uuid = clientSecret.data["transactionUUID"]
				}
				handleErrorCC(
					t<string>('payment.confirm.transaction_error'),
					uuid,
					wallet.publicKey
				);
			} finally {
				setPurchaseInProgress(false);
			}
		}
	};

	const goToConfirmationPage = () => {
		props.history.push('/paymentConfirmationPage', {
			nft,
			paymentMethod,
			wallet,
			address,
		});
	};

	const makePaypalPayment = async () => {
		setPurchaseInProgress(true);

		// await paymentService.mintNft(nft, wallet); //Commenté vu que c'est utilisé par paypal et est brisé (Call la fonction pour Crypto Anyways)

		setPurchaseInProgress(false);
		goToConfirmationPage();
	};

	return {
		purchaseInProgress,
		changePaymentMethod,
		changeAddress,
		setShowViewer,
		canBuy,
		nftPriceCan,
		totalPrice,
		taxeGouv,
		taxeProv,
		shipping_price,
		showViewer,
		makeCreditCardPayment,
		makeCryptoPayment,
		makePaypalPayment,
	};
}

export default ConfirmPurchaseController;

import { toast } from "react-toastify";
import { immutableProvider } from "src/ApplicationContext";
import i18n from "src/i18n";

export class MetamaskLogicController {
    connectMetamask() {
        const promise = async () =>
		await immutableProvider.depositMetaMask();
		toast.promise(promise, {
			pending: {
				render: i18n.t<string>('deposit.metamask.pending'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
			success: {
				render: i18n.t<string>('deposit.metamask.success'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
			error: {
				render: i18n.t<string>('deposit.metamask.error'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
    }

}
import { useTranslation } from 'react-i18next';
import CardGeneral from '../Cards/CardGeneral';
import { Demand } from 'src/business/entities/Demand';

interface Props {
	demand: Demand;
	idx: number;
	received: boolean;
	acceptDemand: (demand: Demand) => Promise<void>;
	refuseDemand: (demand: Demand) => Promise<void>; 
}

const DemandCard = ({ demand,idx, received, acceptDemand, refuseDemand}: Props) => {
	const { t } = useTranslation();
	
	return (
		<CardGeneral 
			index={idx}
			titre={demand.subject? demand.subject: t<string>('zone_admin.entente.defaultAssociation')} 
			imageUrl={{info:'none',url:'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'}}

			description={
				<>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.entente.galleryName')}<a><b>{demand.gallery.galleryName}</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.entente.galleryShare')}<a><b>{demand.galleryShare}&nbsp;%</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.entente.artistShare')}<a><b>{demand.artistShare}&nbsp;%</b></a>
						</span>
					</div>
					<div style={{ paddingBottom: 10}} className="seller d-flex justify-content-between my-2">
						<span>
							{t<string>('zone_admin.entente.demandDate')}<a><b>{new Date(demand.demandDate).toLocaleDateString() + ' ' + new Date(demand.demandDate).toLocaleTimeString('en-US', {hour12:false})}&nbsp;</b></a>

						</span>
					</div>
					{
						demand.responseDate ? 
						<div className="seller d-flex justify-content-between my-2">
							<span>
								{t<string>('zone_admin.entente.responseDate')}<a><b>{new Date(demand.responseDate).toLocaleDateString() + ' ' + new Date(demand.responseDate).toLocaleTimeString('en-US', {hour12:false})}&nbsp;</b></a>

							</span>
						</div>
						:

						<div className="seller d-flex justify-content-between my-2">
							<span>
								{t<string>('zone_admin.entente.responseDate')}<a><b>{t<string>('zone_admin.entente.responsePending')}&nbsp;</b></a>

							</span>
						</div>
					}
					{
						demand.accepted &&
						<div className="seller d-flex justify-content-center my-2">
							<img
								style={{ alignSelf: "center",height: 100}}
								src={'img/green_check.png'}
							/>
						</div>
					}
					{
						demand.refused &&
						<div className="seller d-flex justify-content-center my-2">
							<img
								style={{ alignSelf: "center",height: 80, marginBottom: 20}}
								src={'img/red-x.png'}
							/>
						</div>
					}
					{
						(!received && !demand.accepted && !demand.refused) &&
						<div className="seller d-flex justify-content-center my-2">
							<img
								style={{ alignSelf: "center", height: 80, marginBottom: 20}}
								src={'img/pending-clock-icon.png'}
							/>
						</div>
					}
					{
						(received && !demand.accepted && !demand.refused) && 
						<div className="seller d-flex justify-content-center my-2">
							<button className="btn btn-primary" style={{margin: 20, marginTop: 50}} onClick={(_) => acceptDemand(demand)}> {t<string>('zone_admin.entente.acceptDemand') }</button>
							<button className="btn btn-primary" style={{margin: 20, marginTop: 50}} onClick={(_) => refuseDemand(demand)}> {t<string>('zone_admin.entente.refuseDemand') }</button>
						</div>
					}
				</>
			}	
		/>
	);
};

export default DemandCard;

import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { authenticationService, storageService } from 'src/ApplicationContext';
import User from 'src/business/entities/User';
import i18n from 'src/i18n';
import TokiesService from 'src/service/TokiesService';
import { RouteComponentProps } from 'react-router';

export class UserLogicController {
	async loadDataUser(setUser: Dispatch<SetStateAction<User>>): Promise<void> {
		const result: User = await TokiesService.getUserById(
			storageService.get_user_id()
		);

		setUser(result);
	}

	async userLoadImage(
		event: React.ChangeEvent<HTMLInputElement>,
		userId: string
	): Promise<void> {
		const data = new FormData();
		data.append('file', event.target.files[0]);
		await TokiesService.uploadImage(userId, data);
	}

	logout(props: RouteComponentProps): void {
		const promise = authenticationService.logoutUser();
		toast.promise(async () => promise, {
			success: {
				render: i18n.t<string>('header.logout_success'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});

		props.history.push('/');
	}

	changePassword(props: RouteComponentProps): void {
		props.history.push('/changePassword');
	}

	emailIsValid(email: string): boolean {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return email && regex.test(email);
	}

	getPasswordConfirmationErrorMessage = (
		password: string,
		confirmPassword: string
	) => {
		return authenticationService.passwordValidation(
			password,
			confirmPassword
		);

	};

	phoneNumberIsValid = (phoneNumber: string) => {
		const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/i;

		return phoneNumber && regex.test(phoneNumber);
	};

	changeLanguage() {
		const curr_lng = i18n.language;
		const new_language = curr_lng?.includes('fr') ? 'en' : 'fr';
		i18n.changeLanguage(new_language);
		window.location.reload();
	}
}

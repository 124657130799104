import AuthenticationService from "src/service/AuthenticationService";
import AdressService from "src/service/AdressService";
import ConversionService from "src/service/ConversionService";
import ImmutableProvider from "src/service/ImmutableProvider";
import StorageService from "src/service/StorageService";
import WalletService from "src/service/WalletService";
import CollectionService from "src/service/CollectionService";
import UserService from "src/service/UserService";
import PaymentService from "./service/PaymentService";
import TransactionService from "./service/TransactionService";
import NFTService from "./service/NFTService";
import MetamaskAuthenticationService from "./service/MetamaskAuthenticationService";


export const storageService = new StorageService();
export const immutableProvider = new ImmutableProvider();
export const adressService = new AdressService();
export const walletService = new WalletService();
export const conversionService = new ConversionService();
export const authenticationService = new AuthenticationService();
export const collectionService = new CollectionService();
export const userService = new UserService();
export const paymentService = new PaymentService();
export const nftService = new NFTService();
export const transactionService = TransactionService
export const metamaskAuthentication = new MetamaskAuthenticationService();

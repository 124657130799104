import i18n from 'src/i18n';
import StripeAccount from 'src/presentation/components/StripeAccount/StripeAccount';
import { isMobile } from 'react-device-detect';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { RouteComponentProps } from 'react-router';
import CollectionScreen from 'src/presentation/screens/CollectionScreen';
import { Box } from '@mui/material';
import GallerieTabs from '../GallerieTabs/GallerieTabs';
import { useTranslation } from 'react-i18next';
import EntenteGallerieTab from 'src/presentation/components/GallerieTabs/EntenteGallerieTab';
import ArtistProfileTab from 'src/presentation/components/GallerieTabs/ArtistProfileTab';

interface Props extends RouteComponentProps {
	match: {
		params: {
			id: string;
		};
		path: string;
		isExact: boolean;
		url:string;
	};
	id: string;
	state?:any
}

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

const ZoneAdmin = (props: Props) => {
	const mt = isMobile ? 50 : 0;
	const theme = useTheme();
	const [value, setValue] = React.useState(props.state?.tab ?props.state?.tab:0);
	const { t } = useTranslation();

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						{children}
					</Box>
				)}
			</div>
		);
	}

	React.useEffect(()=>{
		handleChangeIndex(props.state?.tab)
	})
	

	return (
		<section style={{ marginTop: mt }} className="activity-area load-more">
			<div className="container">
				<div className="col-4 intro my-auto pb-2">
					<div className="intro-content">
						<span>{i18n.t<string>('zone_admin.preHeading')}</span>
						<h3 className="mt-3 mb-0">
							{i18n.t<string>('zone_admin.heading')}
						</h3>
					</div>
				</div>
				<div className="item-info">
					<SwipeableViews
						axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
						index={value}
						onChangeIndex={handleChangeIndex}
					>
						<TabPanel value={props.state?.tab} index={0} >
							<CollectionScreen {...props}/>
						</TabPanel>
						<TabPanel value={props.state?.tab} index={1} >
							<StripeAccount validationId={props.match.params.id}/>
						</TabPanel>
						<TabPanel value={props.state?.tab} index={2}>
							<EntenteGallerieTab/>
						</TabPanel>
						<TabPanel value={props.state?.tab} index={3}>
							<GallerieTabs 
								{...props}
								title={t<string>('zone_admin.gallerie.title')}
							/>
						</TabPanel>
						<TabPanel value={props.state?.tab} index={4}>
							<ArtistProfileTab/>
						</TabPanel>
					</SwipeableViews>
				</div>
			</div>
		</section>
	);
};

export default ZoneAdmin;

export default class StorageService {
	get_wallet_id() {
		return sessionStorage.getItem('WALLET_ID');
	}

	set_wallet_id(wallet_id: string) {
		sessionStorage.setItem('WALLET_ID', wallet_id);
	}

	get_access_token() {
		return sessionStorage.getItem('ACCESS_TOKEN');
	}

    remove_access_token() {
        sessionStorage.removeItem('CONNEXION');
        sessionStorage.removeItem('ACCESS_TOKEN');
    }

	set_access_token(access_token: string) {
		sessionStorage.setItem('CONNEXION', 'true');
		sessionStorage.setItem('ACCESS_TOKEN', access_token);
	}

	get_user_id() {
		return sessionStorage.getItem('USER_ID');
	}

	set_user_id(user_id: string) {
		sessionStorage.setItem('USER_ID', user_id);
	}

	get_artist_id() {
		return sessionStorage.getItem('ARTIST_ID');
	}

	set_artist_id(artist_id: string) {
		sessionStorage.setItem('ARTIST_ID', artist_id);
	}

	get_connexion() {
		return sessionStorage.getItem('CONNEXION');
	}

	set_connexion(connexion: string) {
		sessionStorage.setItem('CONNEXION', connexion);
	}

	get_wallet_address() {
		return sessionStorage.getItem('WALLET_ADDRESS');
	}

	set_wallet_address(wallet_address: string) {
		sessionStorage.setItem('WALLET_ADDRESS', wallet_address);
	}
	get_connection_type() {
		return sessionStorage.getItem('CONNECTION_TYPE');
	}

	set_connection_type(connection_type: string) {
		sessionStorage.setItem('CONNECTION_TYPE', connection_type);
	}
	get_stark_public_key() {
		return sessionStorage.getItem('STARK_PUBLIC_KEY');
	}

	set_stark_public_key(stark_public_key: string) {
		sessionStorage.setItem('STARK_PUBLIC_KEY', stark_public_key);
	}
	
    remove_wallet_information() {
        sessionStorage.removeItem('WALLET_ADDRESS');
        sessionStorage.removeItem('CONNECTION_TYPE');
        sessionStorage.removeItem('STARK_PUBLIC_KEY');
    }

	set_session_expire(session_expire: string) {
		sessionStorage.setItem('SESSION_EXPIRE', session_expire);
	}

	get_session_expire() {
		return sessionStorage.getItem('SESSION_EXPIRE');
	}

	set_user_email(user_email: string) {
		sessionStorage.setItem('USER_EMAIL', user_email);
	}

	get_user_email() {
		return sessionStorage.getItem('USER_EMAIL');
	}

	set_connected_wallet_type(connected_wallet_type: string) {
		sessionStorage.setItem('CONNECTED_WALLET_TYPE', connected_wallet_type);
	}

	get_connected_wallet_type() {
		return sessionStorage.getItem('CONNECTED_WALLET_TYPE');
	}

	clear() {
		sessionStorage.removeItem('CONNEXION');
		sessionStorage.removeItem('ACCESS_TOKEN');
		sessionStorage.removeItem('ADMIN');
		sessionStorage.removeItem('USER_EMAIL');
		sessionStorage.removeItem('USER_ID');
		sessionStorage.removeItem('MARKET_ID');
		sessionStorage.removeItem('WALLET_ADDRESS');
		sessionStorage.removeItem('SESSION_EXPIRE');
		sessionStorage.removeItem('CONNECTED_WALLET_TYPE');
		sessionStorage.removeItem('ARTIST_ID')
		sessionStorage.removeItem('WALLET_ID')
		sessionStorage.removeItem('CONNECTION_TYPE')
	}
}

import i18n from 'src/i18n';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaypalButton from 'src/presentation/components/Confirmation/paypal/PaypalButton';
import { environnement } from 'src/env/SaasEnvironnement';
import Environnement from 'src/env/env';
import { NFT } from 'src/business/entities/NFT';

interface Props {
	purchaseInProgress: boolean;
	nft: NFT;
	taxeProv: number;
	taxeGouv: number;
	totalPrice: number;
	mintNft: () => Promise<void>;
	shipping_price: number;
	nftPriceCan: number;
}


interface PaypalCredentials {
	clientId: string;
	clientSecret: string;
}

const paypalCredentialsSandBox: PaypalCredentials = {
	clientId: process.env.REACT_APP_PAYPAL_SANDBOX || '',
	clientSecret: process.env.REACT_APP_PAYPAL_SANDBOX_SECRET || '',
};

const paypalCredentialsLive: PaypalCredentials = {
	clientId: process.env.REACT_APP_PAYPAL_LIVE || '',
	clientSecret: process.env.REACT_APP_PAYPAL_LIVE_SECRET || '',
};

const getPaypalCredential = (): PaypalCredentials => {
	if (environnement === Environnement.Local || environnement === Environnement.Dev) {
		return paypalCredentialsSandBox;
	}

	return paypalCredentialsLive;
};



// TODO JAI E NLEVÉ UN PARAMÈTRE À LA FONCTION
const PaypalConfirmation = ({
	taxeGouv,
	taxeProv,
	totalPrice,
	mintNft,
	shipping_price,
	nftPriceCan,
}: Props) => {
	const paypalCredentials = getPaypalCredential();

	return (
		<div
			style={{
				backgroundImage:
					'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
			}}
			className="card p-4 d-flex flex-column justify-content-center h-100"
		>
			<div className="my-4" style={{ width: '100%' }}>
				<h3> Payment </h3>
				<div style={{ height: 1, backgroundColor: 'white', width: '100%' }}>
					{' '}
				</div>
				<div className="d-flex flex-column justify-content-center">
					<div
						className="my-3"
						style={{
							width: '100%',
							border: 'solid var(--primary-color)',
							borderWidth: '1px',
							opacity: '0.3',
						}}
					></div>

					<div className="row m-2">
						<div className="col-5 text-left">
							{i18n.t<string>('payment.price').toString() + ' : '}
						</div>
						<div className="col-7 text-right  d-flex align-items-center">
							{nftPriceCan.toFixed(2)} $CAD
						</div>
					</div>
					<div className="row m-2">
						<div className="col-5 text-left">
							{i18n.t<string>('payment.shipping').toString() + ' : '}
						</div>
						<div className="col-7 text-right  d-flex align-items-center">
							{shipping_price.toFixed(2)} $CAD
						</div>
					</div>
					<div
						className="my-2 w-50 ml-auto"
						style={{
							border: 'solid var(--primary-color)',
							borderWidth: '1px',
							opacity: '0.3',
						}}
					></div>
					<div className="row m-2">
						<div className="col-5 text-left">
							{i18n.t<string>('payment.before_taxes').toString() + ' : '}
						</div>
						<div className="col-7 text-right  d-flex align-items-center">
							{nftPriceCan + shipping_price || 0} $CAD
						</div>
					</div>
					<div className="row m-2">
						<div className="col-5 text-left">
							{i18n.t<string>('payment.tvq').toString() + ' : '}
						</div>
						<div className="col-7 text-right  d-flex align-items-center">
							{taxeProv.toFixed(2) || 0 + ' '} $CAD
						</div>
					</div>
					<div className="row m-2">
						<div className="col-5 text-left">
							{i18n.t<string>('payment.tps').toString() + ' : '}
						</div>
						<div className="col-7 text-right  d-flex align-items-center">
							{taxeGouv.toFixed(2) || 0 + ' '} $CAD
						</div>
					</div>
					<div
						className="my-2"
						style={{
							width: '100%',
							border: 'solid var(--primary-color)',
							borderWidth: '1px',
							opacity: '0.3',
						}}
					></div>
					<div className="row m-2">
						<h4 className="col-5 text-left m-0">
							{i18n.t<string>('payment.total').toString() + ' : '}
						</h4>
						<div className="text-right  d-flex align-items-center">
							{totalPrice.toFixed(2)} $CAD
						</div>
					</div>
					<div style={{ marginTop: 20 }}>
						<PayPalScriptProvider
							options={{
								'client-id': paypalCredentials.clientId,
								currency: 'CAD',
								'enable-funding': 'paylater',
								components: 'buttons,funding-eligibility',
							}}
						>
							<PaypalButton
								mintNft={mintNft}
								totalPrice={totalPrice.toFixed(2)}
							/>
						</PayPalScriptProvider>
						<span style={{ fontSize: 10 }}>
							{i18n.t<string>('payment.policy_agreement').toString()}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaypalConfirmation;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExploreLogicController } from 'src/business/controller/logic/ExploreLogicController';
import NFTAsset from 'src/business/entities/NFTAsset';

export function ExploreAutoSellOrdersUIController() {
	const [nftAssets, setNftAssets] = useState<NFTAsset[]>([]);
	const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		getNftAssets();
	}, []);

	const exploreLogicController = new ExploreLogicController();

	const getNftAssets = async () => {
		const page_size = 25;
		const nftList = await exploreLogicController.getData(page_size);
		setLoading(false);
		setNftAssets(nftList);
	};

	const getFormattedPrice = (price: number): string => {
		return exploreLogicController.getFormattedPrice(price);
	};

	return { getFormattedPrice, nftAssets, loading, t };
}

import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { RouteComponentProps } from 'react-router';

export interface MessageModalProps extends RouteComponentProps {
	onShowLogin: (value: boolean) => void;
	onShowMessage: (value: boolean) => void;
	onShowRegister: (value: boolean) => void;
	show: boolean;
}

const MessageModal = (props: MessageModalProps) => {

	const { t } = useTranslation();
	
	const handleQuitMessage = () => {
		props.onShowLogin(true);
		props.onShowMessage(false);
	};
	const handleRegisterButton = () => {
		props.onShowRegister(true);
		props.onShowMessage(false);
	};

	return (
		<Modal
			show={props.show}
			onHide={handleQuitMessage}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header  className="mx-3 pb-0">
				<Modal.Title>
					{'Mise à jour'}
				</Modal.Title>
				<button
					onClick={() => handleQuitMessage()}
					className="btn"
					style={{ padding: 16, color: "var(--primary-p-color)" }}
				>
					{'X'}
				</button>
			</Modal.Header>
			<Modal.Body  className="mx-3 pt-0">
				<div className="container">
				<div>
					<h5>La nouvelle structure de sécurité du site vous oblige à créer un nouveau compte, même si vous aviez déjà acheté un premier NFT sur notre plateforme. </h5>
					<h5>Mais soyez sans crainte, vos actifs numériques ne sont pas perdus. Ils seront manuellement ajoutés à votre nouveau compte au bout de 2-3 jours. </h5>
					<h5>Pour quoi que ce soit, n’hésitez pas à nous contacter.</h5>
				</div>
				<button
					className="btn mx-3 mt-3 mt-sm-4"
					type="submit"
					onClick={handleQuitMessage}
				>
					{t<string>('login.messagePreHeading') + ' '}
				</button>
				<button
					className="btn mt-3 mt-sm-4"
					type="submit"
					onClick={handleRegisterButton}
				>
					{t<string>('register.messagePreHeading') + ' '}
				</button>

				</div>
			</Modal.Body>
		</Modal>
	);
};
export default MessageModal;

import { NFT } from 'src/business/entities/NFT';
import { useEffect, useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout
  } from '@stripe/react-stripe-js';
import { getBackEndUrl,getStripePK } from 'src/env/SaasEnvironnement';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import axios, { AxiosRequestConfig } from 'axios';
import TokiesService from 'src/service/TokiesService';
import { storageService } from 'src/ApplicationContext';
import Wallet from '../../../business/entities/Wallet';
import InstructionModal from '../Modals/InstructionModal/InstructionModal';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props   extends RouteComponentProps{
	makeCreditCardPayment: (e: any) => Promise<void>;
	purchaseInProgress: boolean;
	nft: NFT;
	taxeProv: number;
	taxeGouv: number;
	totalPrice: number;
	shipping_price: number;
	nftPriceCan: number;
	wallet: Wallet;
}

const stripePromise = loadStripe(getStripePK());
const CreditCardPaymentConfirmation = (props: Props) => {
	const [clientSecret,setClientSecret] = useState('');
	const [checkoutActive,setCheckoutActive] = useState(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [showInstruction, setShowInstruction] = useState(false);
	const getData = async () =>{
	  // Create a Checkout Session as soon as the page loads
		const data = JSON.stringify({
			buyerAddress: props.wallet.publicKey,
			tokenAddress: props.nft.token_address,
			tokenId: props.nft.token_id,
			marketId: applicationConfiguration.marketId,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${getBackEndUrl()}/NFT/createCheckoutSession`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			await axios.request(config)
			.then((res) => res.data)
			.then((data) => {
				setClientSecret(data.client_secret)
			});
			setCheckoutActive(false)
		} catch (e) {
			setCheckoutActive(true)
		}

	}
	useEffect(() => {
		if(!isComplete){
			getData()
		}
	}, [isComplete]);

	return (
		<div className="card p-4 d-flex flex-column justify-content-center h-100">
		{/* <form onSubmit={makeCreditCardPayment}> */}
    <div id="checkout">
      {clientSecret &&  (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
			<EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
	{checkoutActive &&  (
		<h5>Désolé ce nft est déja en cour d'achat. Veuiller retourner a la boutique poour en choisir un nouveau.</h5>
	)}
	<CheckoutCheck setIsComplete={setIsComplete} isComplete={isComplete} setShowInstruction={setShowInstruction} sessionId={clientSecret} nft={props.nft} wallet={props.wallet} />
	{isComplete &&  (
		<h5>Votre recu va ce trouver dans votre profil</h5>
	)}
    </div>
		<InstructionModal
			{...props}
			show={showInstruction}
			onShowMessage={(value) => {
				setShowInstruction(value);
			}}
			goToHome = {() =>props.history.push('/')}
			goToProfil = {()=>props.history.push('/profil')}
		/>

			{/* </form> */}
		</div>
	);
};

export default withRouter(CreditCardPaymentConfirmation);
interface SubProps {
	sessionId: string;
	nft: NFT;
	wallet:Wallet;
	isComplete:boolean
	setIsComplete: (value:boolean) =>void
	setShowInstruction: (value:boolean) =>void
}
export const CheckoutCheck = ({
	sessionId,
	nft,
	wallet,
	isComplete,
	setIsComplete,
	setShowInstruction,
}: SubProps)=> {
	const createTransaction=async(session:any)=>{
		const intentInfo = await TokiesService.getPaymentIntent(
			session.payment_intent
		);
		const cardInfo = await TokiesService.getPaymentMethod(
			(
				await intentInfo
			).data.payment_method
		);
		const cardDetail = (await cardInfo).data.card;
		await TokiesService.createTransaction({
			nftName: nft.name,
			tokenid: nft.token_id,
			priceETH: String(nft.price),
			priceCAD: String(nft.priceCan),
			checked: false,
			userId: storageService.get_user_id(),
			marketId: applicationConfiguration.marketId,
			walletId: wallet.id,
			NFTId: -1,
			type: cardDetail.brand,
			cardNumber: cardDetail.last4,
			expiracy: '' + cardDetail.exp_month + '/' + cardDetail.exp_year,
			holder: null,
		});
	}
	
	const getData = async () =>{
		  try {
			await TokiesService.getSession({sessionId:sessionId.slice(0,66)})
			.then((res) => res.data)
			.then((data:any) => {
				if(data.status == 'complete'){
					if(!isComplete){
						setShowInstruction(true)
						setIsComplete(true)
						createTransaction(data)
					}
				}
			});
		  } catch (e) {
		  }
  
	  }
	useEffect(() => {
	if(sessionId){
		const id = setInterval(() => {
	 		getData()
		}, 1500)
		return () => clearInterval(id); 
	}
	}, [sessionId]);

	return (
		<div >
		</div>
	);
};

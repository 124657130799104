import { useEffect, useState } from 'react';
import Routes from 'src/presentation/Routes';
import i18n from 'src/i18n';
import * as dotenv from 'dotenv'
import { AdminContext } from 'src/context/AdminContext';
import { MarketContext } from 'src/context/MarketContext';
dotenv.config();

function App() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [marketId, setMarketId] = useState('0');
	const [i18nInit, seti18nInit] = useState('');
	useEffect(() => {
		i18n.reloadResources(undefined, undefined, () => seti18nInit('done'));
	}, []);

	return (
		<MarketContext.Provider value={{ marketId, setMarketId }}>
			<AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
				{i18nInit && <Routes />}
			</AdminContext.Provider>
		</MarketContext.Provider>
	);
}

export default App;

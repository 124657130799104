import i18n from 'src/i18n';
import { authenticationService } from 'src/ApplicationContext';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	onShowForgotPasswordModal: (value: boolean) => void;
	show: boolean;
}

export default function ForgetPasswordModalUIController(_props: Props) {
	const [email, setEmail] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [verificationCode, setVerificationCode] = useState('');
	const [newEmailError, setNewEmailError] = useState('');
	const [type_new_password, setTypeNewPassword] = useState(false);
	const [emailvalidationError, setEmailvalidationError] = useState('');
	const { t } = useTranslation();

	const send_verification_code = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		email: string
	) => {
		e.preventDefault();
		const request = await authenticationService.sendCodeForForgetPassword(
			email
		);

		if (request) {
			setTypeNewPassword(true);
		} else {
			setEmailvalidationError(
				i18n.t<string>('login.messageEmailNotValid').toString()
			);
		}
	};

	const resend_new_password = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		newPassword: string,
		confirmNewPassword: string,
		email: string,
		verificationCode: string,
		props: Props
	) => {
		e.preventDefault();

		if (newPassword !== confirmNewPassword) {
			setNewEmailError('Error while creating new password');
			return;
		}

		const errorMessage = authenticationService.passwordValidation(
			newPassword,
			confirmNewPassword
		);

		if (errorMessage !== '') {
			setNewEmailError('Error while creating new password');
			return;
		}

		const request = await authenticationService.confirm_forgot_password(
			email,
			verificationCode,
			newPassword
		);

		if (request) {
			toast.promise(async () => {}, {
				success: {
					render: i18n.t<string>('login.passwordChangeWithSuccess'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
			props.onShowForgotPasswordModal(false);
		} else {
			setNewEmailError('Error while creating new password');
		}
	};

	return {
		email,
		send_verification_code,
		resend_new_password,
		setEmail,
		setNewPassword,
		setConfirmNewPassword,
		setVerificationCode,
		newEmailError,
		type_new_password,
		emailvalidationError,
		newPassword,
		confirmNewPassword,
		verificationCode,
		t
	};
}


import { useEffect, useState } from "react";
import { immutableProvider } from "src/ApplicationContext";
import NFTAsset from "src/business/entities/NFTAsset";

interface Props {
	tokenAddress: string;
	tokenId: string;
	history: {
		push: Function;
	};
}

export function NftUserItemDetailsUIController(props:Props) {
	const [asset, setAsset] = useState<NFTAsset>(null);
	const [showViewer, setShowViewer] = useState(false);

    const getData = async () => {
		await immutableProvider.init();

		const asset: NFTAsset = await immutableProvider.getAssetDetails(props.tokenAddress,props.tokenId);
		setAsset(asset)

		document.addEventListener('wheel', function (_) {
			if (document?.activeElement?.classList.contains('noscroll') && document.activeElement instanceof HTMLElement) {
				document.activeElement?.blur();
			}
		});
	}

	const onViewDetails = (): void => {
		props.history.push('/nft/details', {
			asset: asset,
		});
	};
	const handlePurchasedDate = (): string => {
		const purchase = asset.updated_at;
		const purchasedDate = new Date(purchase);
		const purchasedTime = purchasedDate
			.toDateString()
			.split(' ')
			.reduce((previousValue, currentValue, idx) => {
				if (idx === 0) {
					return '';
				}
				previousValue += `${currentValue} `;
				return previousValue;
			}, '');
		return purchasedTime;
	};

	useEffect(()=>{
		getData()
	},[])

	return {asset, handlePurchasedDate, setShowViewer, onViewDetails, showViewer}
}
import { NFT } from 'src/business/entities/NFT';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import CreditCardPaymentConfirmation from 'src/presentation/components/Confirmation/CreditCardPaymentConfirmationt';
import CryptoPaymentConfirmation from 'src/presentation/components/Confirmation/CryptoPaymentConfirmation';
import PaypalConfirmation from 'src/presentation/components/Confirmation/PaypalConfirmation';
import Wallet from '../../../business/entities/Wallet';
import { RouteComponentProps } from 'react-router';

interface Props  extends RouteComponentProps {
	paymentMethod: PaymentMethod;
	makeCreditCardPayment: (e: any) => Promise<void>;
	nft: NFT;
	taxeGouv: number;
	totalPrice: number;
	taxeProv: number;
	purchaseInProgress: boolean;
	canBuy: boolean;
	makeCryptoPayment: () => Promise<void>;
	makePaypalPayment: () => Promise<void>;
	shipping_price: number;
	nftPriceCan: number;
	wallet: Wallet;
}

const DecidePaymentMethod = (props: Props): JSX.Element => {
	switch (props.paymentMethod) {
		case PaymentMethod.CreditCard:
			return (
				<CreditCardPaymentConfirmation
					{...props}
					nftPriceCan={props.nftPriceCan}
					shipping_price={props.shipping_price}
					makeCreditCardPayment={props.makeCreditCardPayment}
					purchaseInProgress={props.purchaseInProgress}
					nft={props.nft}
					taxeProv={props.taxeProv}
					taxeGouv={props.taxeGouv}
					totalPrice={props.totalPrice}
					wallet={props.wallet}
				/>
			);
		case PaymentMethod.Crypto:
			return (
				<CryptoPaymentConfirmation
					nftPriceCan={props.nftPriceCan}
					shipping_price={props.shipping_price}
					purchaseInProgress={props.purchaseInProgress}
					makeCryptoPayment={props.makeCryptoPayment}
					canBuy={props.canBuy}
					nft={props.nft}
					taxeProv={props.taxeProv}
					taxeGouv={props.taxeGouv}
					totalPrice={props.totalPrice}
				/>
			);
		case PaymentMethod.Paypal:
			return (
				<PaypalConfirmation
					nftPriceCan={props.nftPriceCan}
					shipping_price={props.shipping_price}
					purchaseInProgress={props.purchaseInProgress}
					nft={props.nft}
					taxeProv={props.taxeProv}
					taxeGouv={props.taxeGouv}
					totalPrice={props.totalPrice}
					mintNft={props.makePaypalPayment}
				/>
			);
		default:
			return (
				<CryptoPaymentConfirmation
					nftPriceCan={props.nftPriceCan}
					shipping_price={props.shipping_price}
					purchaseInProgress={props.purchaseInProgress}
					makeCryptoPayment={props.makeCryptoPayment}
					canBuy={props.canBuy}
					nft={props.nft}
					taxeProv={props.taxeProv}
					taxeGouv={props.taxeGouv}
					totalPrice={props.totalPrice}
				/>
			);
	}
};

export default DecidePaymentMethod;

import PermMediaIcon from "@mui/icons-material/PermMedia";
import { isMobile } from "react-device-detect";
import i18n from "src/i18n";

interface Props {
  cancelCollectionCreation: () => void;
}

const CollectionTitle = ({ cancelCollectionCreation }: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div onClick={cancelCollectionCreation} className="btn btn-smaller">
          {i18n.t<string>("zone_admin.mintage.cancel")}
        </div>
      </div>
      <div className="row">
        <h3 style={{ paddingLeft: window.innerWidth * 0.2 }}>
          {i18n.t<string>("zone_admin.mintage.createNFT")}
        </h3>
        {isMobile === false && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 15,
            }}
          >
            <PermMediaIcon />
          </div>  
        )}
      </div>
    </div>
  );
};

export default CollectionTitle;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authenticationService, storageService } from 'src/ApplicationContext';
import { BuyNowProps } from 'src/presentation/components/BuyNow';

function BuyNowController(props: BuyNowProps) {
	const nft = props?.location?.state?.nft;
	const [isLogin, setLogin] = useState<boolean>(true);
	const [email, setEmail] = useState<string>('');
	const [password, setPasswrod] = useState<string>('');
	const [error, setError] = useState<string>('');

	const { t } = useTranslation();

	useEffect(() => {
		const userId = storageService.get_user_id();

		if (userId) {
			props.history.push('/payment', {
				nft,
			});
			return;
		}

		setLogin(false);
	}, []);

	const onSubmit = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		const results = await authenticationService.login_with_email(
			email,
			password
		);

		if (results) {
			props.history.push('/payment', { nft });
		} else {
			setError(t<string>('login.messageIncorrectEmailOrPassword'));
		}
	};

	return {
		isLogin,
		setLogin,
		email,
		setEmail,
		password,
		setPasswrod,
		error,
		setError,
		onSubmit,
        t
	};
}

export default BuyNowController;

import { isMobile } from 'react-device-detect';
import BoutetHomePage from 'src/presentation/components/Hero/BoutetHomepage.png';
import { useTranslation } from 'react-i18next';

const HeroPicture = (): JSX.Element => {

	const { t } = useTranslation();
	
	const text = {
			pre_heading: t<string>('home.hero.pre_heading'),
			heading: t<string>('home.hero.heading'),
			btn_icon: t<string>('home.hero.btn_icon'),
			discover: t<string>('home.hero.discover'),
	};

	return (
		<section className="hero-section" style={{ marginTop: isMobile ? 50 : 0 }}>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-12 col-lg-11">
						<span>{text.pre_heading}</span>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center flex-column">
				<h1 className="mt-4 text-center">{text.heading}</h1>

				<img
					style={{
						height: 400,
						marginTop: 30,
					}}
					src={BoutetHomePage}
				/>
				<div className="button-group">
					<a
						style={{ color: "var(--primary-p-color)" }}
						className="btn btn-primary"
						href="/marketplace"
					>
						<i className={`${text.btn_icon} mr-2`} />
						{text.discover}
					</a>
				</div>
			</div>
		</section>
	);
};

export default HeroPicture;

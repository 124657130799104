import Header from 'src/presentation/components/Header/Header';
import NftUserItemDetails from 'src/presentation/components/NftUserItemDetails/NftUserItemDetails';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

export interface NftUserItemDetailsProps extends RouteComponentProps{
	match: {
		params: {
			tokenAddress: string,
			tokenId: string,
		}
		isExact: boolean,
		path: string,
		url: string
	},
}

const NftUserItemDetailsScreen: FC<NftUserItemDetailsProps> = (props: NftUserItemDetailsProps) => {
	return (
		<div className="main">
			<Header />
			<NftUserItemDetails
			{...props}
				tokenAddress={props.match.params.tokenAddress}
				tokenId={props.match.params.tokenId}
			/>
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default NftUserItemDetailsScreen;

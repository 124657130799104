import Wallet from '../../business/entities/Wallet';
import { isMobile } from 'react-device-detect';
import { NFT } from 'src/business/entities/NFT';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import i18n from 'src/i18n';
// import AdressConfirmation from 'src/presentation/components/Confirmation/AddressConfirmation';                            // retirer address
import NftInformationConfirmation from 'src/presentation/components/Confirmation/NftInformationConfirmation';
import DecidePaymentMethod from 'src/presentation/components/Confirmation/PaymentMethodConfirmation';
import WalletConfirmation from 'src/presentation/components/Confirmation/WalletConfirmation';
import ModalLoader from 'src/presentation/components/ModalLoader';
import Viewer from 'src/presentation/components/Viewer/Viewer';
import { Address } from 'src/business/entities/Address';
import ConfirmPurchaseController from 'src/business/controller/ui/ConfirmPurchaseController';
import { RouteComponentProps } from 'react-router';

export interface ConfirmPurchaseProps extends RouteComponentProps  {
	location: {
		key: string;
		pathname: string;
		state: {
			address: Address;
			wallet: Wallet;
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
		search:any
		hash:any
	};
}

const ConfirmPurchase = (props: ConfirmPurchaseProps): JSX.Element => {
	const { 
		nft, 
		// address,                            // retirer address
		paymentMethod, 
		wallet 
	} = props?.location?.state;
	const {
		purchaseInProgress,
		changePaymentMethod,
		// changeAddress,                            // retirer address
		setShowViewer,
		canBuy,
		nftPriceCan,
		totalPrice,
		taxeGouv,
		taxeProv,
		shipping_price,
		showViewer,
		makeCreditCardPayment,
		makeCryptoPayment,
		makePaypalPayment,
	} = ConfirmPurchaseController(props);

	return (
		<section className="author-area">
			<ModalLoader
				show={purchaseInProgress}
				handleClose={function (): void {}}
				title={i18n.t<string>('payment.confirm.transaction_pending')}
			/>
			<div className="container">
				<div className="intro text-center">
					<span> {i18n.t<string>('payment.confirm.confirmation')} </span>
					<h4 className="mt-3 mb-0">
						{i18n.t<string>('payment.confirm.confirmPayment')}
					</h4>
				</div>
				<div
					className="d-flex flex-wrap justify-content-center"
					style={{ width: '100%' }}
				>
					<div style={{ maxWidth: '780px', width: '100%' }}>
						<div className="d-flex flex-wrap justify-content-center pt-8">
							<div
								style={{
									// maxWidth: '49%',
									width: '100%',
									minWidth: '360px',
								}}
							>
								<WalletConfirmation
									wallet={wallet}
									changeWallet={changePaymentMethod}
								/>
							</div>
							{/* <div
								style={{
									paddingLeft: `${isMobile ? '0px' : '2%'}`,
								}}
							></div>
							<div
								style={{
									width: '100%',
									maxWidth: '49%',
									minWidth: '360px',
								}}
							>
								<AdressConfirmation
									address={address}                           // retirer address
									changeAddress={changeAddress}
								/>
							</div> */}
						</div>
						<NftInformationConfirmation
							nft={nft}
							setShowViewer={setShowViewer}
						/>
					</div>
					<div
						style={{
							paddingLeft: `${isMobile ? '0px' : '2%'}`,
						}}
					></div>
					<div style={{ maxWidth: '360px', width: '100%' }}>
						<DecidePaymentMethod
							{...props}
							nftPriceCan={nftPriceCan}
							shipping_price={shipping_price}
							paymentMethod={paymentMethod}
							nft={nft}
							taxeGouv={taxeGouv}
							totalPrice={totalPrice}
							taxeProv={taxeProv}
							purchaseInProgress={purchaseInProgress}
							canBuy={canBuy}
							makeCryptoPayment={makeCryptoPayment}
							makePaypalPayment={makePaypalPayment}
							makeCreditCardPayment={makeCreditCardPayment}
							wallet={wallet}
						/>
					</div>
				</div>
			</div>
			<Viewer
				imageURL={nft.image}
				isShowed={showViewer}
				showViewer={setShowViewer}
			/>
		</section>
	);
};
export default ConfirmPurchase;

import { useEffect, useState } from 'react';
import i18n from 'src/i18n';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import Masonry from '@mui/lab/Masonry';
import { RouteComponentProps, withRouter } from 'react-router';
import NFTAsset from 'src/business/entities/NFTAsset';
import {
	collectionService,
	immutableProvider,
	nftService,
	storageService,
} from 'src/ApplicationContext';
import { ExploreLogicController } from 'src/business/controller/logic/ExploreLogicController';
import LoginModal from '../Modals/LoginModal/LoginModal';
import RegisterModal from '../Modals/RegisterModal/RegisterModal';
import MessageModal from '../Modals/LoginModal/MessageModal';

interface Props extends RouteComponentProps {}

const ExploreP2P = (props: Props) => {
	const [showLogin, setShowLogin] = useState(false);
	const [showRegister, setShowRegister] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [data, setData] = useState<NFTAsset[]>([]);
	const [loading, setLoading] = useState(false);
	const exploreLogicController = new ExploreLogicController();

	const getData = async () => {
		setLoading(true);

		const orders: NFTAsset[] = [];

		const collection = await collectionService.getCollection();
		const collectionAddress = collection.address;
		const response: NFTAsset[] = await immutableProvider
			.getOrders({
				sell_token_address: collectionAddress,
				page_size: 500,
			})
			.catch(() => []);

		response.forEach((element) => {
			if (element.status === 'active') {
				orders.push(element);
			}
		});
		setData(orders);
		setLoading(false);
	};

	const getFormattedPrice = (price: number) => {
		if (price) {
			const priceToEth = nftService.weiToEth(price);
			const priceText = priceToEth.toString();
			const decimalIndex = priceText.indexOf('.');
			return priceText.substring(0, decimalIndex + 7);
		}
	};

	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	const checkLogin = () => {
		storageService.get_connexion()
			? props.history.push('/profil')
			: setShowMessage(true);
	};

	if (loading) {
		return (
			<section className="explore-area mt-0 pt-0">
				<div className="container">
					<div className="d-flex justify-content-center">
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}
	return (
		<section className="explore-area mt-0 pt-0">
			<div className="container">
				<div
					className="d-flex justify-content-end mt-2"
					onClick={() => checkLogin()}
				>
					<a style={{ color: "var(--primary-p-color)" }} className="btn btn-primary">
						{i18n.t<string>('explore.sell_btn')}
						<i className={`${i18n.t<string>('explore.btn_icon')}`} />
					</a>
				</div>
				<div>
					<Masonry columns={3} spacing={2}>
						{data.map((item, idx) => {
							return (
								<div key={`edth_${idx}`} data-groups={item.collection}>
									<div className="card p-0" style={{ height: '100%' }}>
										<div
											style={{
												width: '100%',
												position: 'relative',
											}}
										>
											<a
												style={{ overflow: 'hidden', borderRadius: '8px' }}
												onClick={() =>
													exploreLogicController.goToNft(item, props)
												}
											>
												<img
													className="card-img-top"
													style={{
														minHeight: '100%',
														minWidth: '100%',
														objectFit: 'cover',
													}}
													src={
														item.image_url ??
														'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
													}
													alt=""
												/>
											</a>
										</div>
										<div className="card-caption p-0">
											<div className="card-body p-4">
												<a
													onClick={(e) => {
														e.preventDefault();
														exploreLogicController.goToNft(item, props);
													}}
													href="#"
												>
													<h5 className="mb-0">
														{item.name ? item.name : 'null'}
													</h5>
												</a>
												<div className="seller d-flex align-items-center my-3">
													<span>{i18n.t<string>('explore.owner')}</span>
													<a>
														<h6 className="ml-2 mb-0">
															{applicationConfiguration.saasName}
														</h6>
													</a>
												</div>
												<div className="card-bottom d-flex justify-content-between">
													<span className="text-center">
														{item.metadata.price
															? getFormattedPrice(item.metadata.price) + ' ETH'
															: 'No price'}
													</span>
													<span className="ml-2 text-center">
														{i18n.t<string>('explore.available')}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</Masonry>
				</div>
			</div>
			<LoginModal
				onShowForgotPasswordModal={() => { } }
				show={showLogin}
				onShowLogin={(value) => {
					setShowLogin(value);
				} }
				onShowRegister={(value) => {
					setShowRegister(value);
				} }
				returnProfil={false}
				{...props}		/>
			<MessageModal
				{...props}
				show={showMessage}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				onShowMessage={(value) => {
					setShowMessage(value);
				}}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
			/>
			<RegisterModal
				show={showRegister}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
			/>
		</section>
	);
};

export default withRouter(ExploreP2P);

export const handleDate = (time: string): string => {
    const date = new Date(time);

    const finalTime = date
        .toDateString()
        .split(' ')
        .reduce((previousValue, currentValue, idx) => {
            if (idx === 0) {
                return '';
            }

            previousValue += `${currentValue} `;

            return previousValue;
        }, '');

    return finalTime;
};
// import ReCAPTCHA from 'react-google-recaptcha';
// import { getRecaptchaInformation } from 'src/env/SaasEnvironnement';
import { ContactUIController } from 'src/business/controller/ui/ContactUIController';

// const recaptchaInformation = getRecaptchaInformation();

const Contact = () => {
	const {
		// setIsRobot,
		name,
		setName,
		email,
		setEmail,
		subject,
		setSubject,
		message,
		setMessage,
		sendContactEmail,
		t,
	} = ContactUIController();

	// const robotConfirmation = (): void => {
	// 	setIsRobot(false);
	// };

	return (
		<section className="author-area" id="contact">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-8 col-lg-7">
						<div className="intro text-center">
							<span>{t<string>('contact.name')}</span>
							<h3 className="mt-3 mb-0">{t<string>('contact.details')}</h3>
							<p>{t<string>('contact.content')}</p>
						</div>
						<form
							id="contact-form"
							style={{ backgroundColor: 'var(--primary-color)' }}
							className="item-form card no-hover"
						>
							<div className="row">
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="text"
											className="form-control"
											name="name"
											placeholder={t<string>('contact.nameplaceholder')}
											required={true}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="email"
											pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
											className="form-control"
											name="email"
											value={email}
											placeholder={t<string>('contact.email')}
											required={true}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<input
											type="text"
											className="form-control"
											name="subject"
											placeholder={t<string>('contact.subject')}
											required={true}
											value={subject}
											onChange={(e) => setSubject(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<textarea
											className="form-control"
											name="message"
											placeholder={t<string>('contact.message')}
											cols={30}
											rows={3}
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										/>
									</div>
								</div>
								{/* <div className="align-center mx-auto">
									<ReCAPTCHA
										sitekey={recaptchaInformation.sitekey}
										onChange={robotConfirmation}
									/>
								</div> */}
								<div className="col-12">
									<button
										className="btn w-100 mt-3 mt-sm-4"
										type="submit"
										onClick={(e) => sendContactEmail(e)}
										style={{ color: "var(--primary-p-color)" }}
									>
										<i className="icon-paper-plane mr-2" />
										{t<string>('contact.send')}
									</button>
								</div>
							</div>
						</form>
						<p className="form-message" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;

import { Address } from "src/business/entities/Address";


interface Props {
	address: Address;
	onSelectAdress: (address: Address) => void;
}

function AdressCard({ address, onSelectAdress }: Props): JSX.Element {
	return (
		<div
			className="card mt-3"
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
			}}
		>
			<div>
				<p> {address.address}</p>
				<p>
					{' '}
					{address.zip}, {address.city}, {address.country}
				</p>
			</div>
			<div className="mt-4">
				<div>
					<button
						type="submit"
						onClick={() => onSelectAdress(address)}
						color="dark"
						className="btn w-100 mt-3 mt-sm-4"
					>
						Select
					</button>
				</div>
			</div>
		</div>
	);
}

export default AdressCard;

import { useTranslation } from 'react-i18next';
import NFTAsset from 'src/business/entities/NFTAsset';

export function CertificationUIController(asset: NFTAsset) {
	const { t } = useTranslation();

	const handleCreatedDate = (): string => {
		const created = asset.created_at;

		const createdDate = new Date(created);

		const createdTime = createdDate
			.toDateString()
			.split(' ')
			.reduce((previousValue, currentValue, idx) => {
				if (idx === 0) {
					return '';
				}

				previousValue += `${currentValue} `;

				return previousValue;
			}, '');

		return createdTime;
	};

	const handlePurchasedDate = () => {
		const purchase = asset.updated_at;

		const purchasedDate = new Date(purchase);

		const purchasedTime = purchasedDate
			.toDateString()
			.split(' ')
			.reduce((previousValue, currentValue, idx) => {
				if (idx === 0) {
					return '';
				}

				previousValue += `${currentValue} `;

				return previousValue;
			}, '');

		return purchasedTime;
	};

	return { handleCreatedDate, handlePurchasedDate, t };
}

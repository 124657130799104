import Header from 'src/presentation/components/Header/Header';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import NftMarketplaceDetail from 'src/presentation/components/NftMarketplaceDetails/NftMarketplaceDetails';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

export interface NftMarketplaceDetailsProps extends RouteComponentProps{
	match: {
		params: {
			tokenId: string;
			tokenAddress: string;
		};
		isExact: boolean,
		path: string,
		url: string
	};
}

const NftMarketplaceDetailsScreen:FC<NftMarketplaceDetailsProps> = (props: NftMarketplaceDetailsProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<NftMarketplaceDetail
				tokenAddress={props.match.params.tokenAddress}
				tokenId={props.match.params.tokenId}
			/>
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default NftMarketplaceDetailsScreen;

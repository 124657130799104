import SelectPaymentWallet from 'src/presentation/components/SelectPaymentWallet';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import { NFT } from 'src/business/entities/NFT';
import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';

export interface SelectPaymentWalletPageProps extends RouteComponentProps {
	location: {
		key: string;
		pathname: string;
		state: {
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
		search: string,
		hash: string
	};
}

const SelectPaymentWalletPage:FC<SelectPaymentWalletPageProps> = (props: SelectPaymentWalletPageProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<SelectPaymentWallet {...props} />
			<Scrollup />
			<ModalMenu />
		</div>
	);
};

export default SelectPaymentWalletPage;

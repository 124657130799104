import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { authenticationService, storageService } from 'src/ApplicationContext';
import { EnabledMFAModalProps } from './EnabledMFAModal';

export const EnabledMFAController = (props: EnabledMFAModalProps) => {
	const { t } = useTranslation();
	const [password, setPassword] = React.useState('');
	const [passwordValidationError, setPasswordValidationError] =
		React.useState('');
	const [hasSendFirstConfirmation, setHasSendFirstConfirmation] =
		React.useState(false);
	const [wrongMfaCodeError, setWrongMfaCodeError] = useState<string>('');
	const [code, setCode] = useState<string>('');

	const enabled_mfa = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		const request = await authenticationService.enabled_mfa(
			storageService.get_user_email(),
			password
		);

		if (request) {
			setHasSendFirstConfirmation(true);
		} else {
			setPasswordValidationError(
				t<string>('login.messageIncorrectEmailOrPassword')
			);
		}
	};

	const confirm_enabled_mfa = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		const request = await authenticationService.confirm_enabled_mfa(
			storageService.get_user_email(),
			password,
			code
		);

		if (request) {
			toast.promise(async () => {}, {
				success: {
					render: t<string>('profil.MFAwasEnabledSuccess'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
			props.handleClose();
		} else {
			setWrongMfaCodeError(t<string>('login.wrongMfaCodeError'));
		}
	};

	const onClick = () => {
		window.location.replace('https://tokies.io/');
	};

	return {
		code,
		enabled_mfa,
		confirm_enabled_mfa,
		password,
		t,
		hasSendFirstConfirmation,
		setCode,
		wrongMfaCodeError,
		setPassword,
		onClick,
		passwordValidationError,
	};
};

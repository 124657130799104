import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { storageService } from "src/ApplicationContext";
import { Demand } from "src/business/entities/Demand";
import { DemandOwnerType } from "src/business/entities/DemandOwnerType";
import TokiesService from "src/service/TokiesService";

export function EntenteGallerieTabUIController() {
    const [receivedDemands, setReceivedDemands] = useState<Demand[]>([]);
    const [sentDemands, setSentDemands] = useState<Demand[]>([]);
    const [artistIdNotSet, setArtistIdNotSet] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const acceptDemand = async (demand: Demand) => {
        const axiosResponse = await TokiesService.acceptDemand(demand.id, +storageService.get_artist_id())
        if(axiosResponse.status == 400) {
            await toast.promise(
                        async () => {
                            return Promise.reject()
                        },
                        {
                            error: {
                                render: axiosResponse.data.message,
                                position: toast.POSITION.BOTTOM_LEFT,
                                pauseOnHover: false,
                                theme: 'dark',
                            },
                        }
                    );
        }
        await getData()
    }

    const refuseDemand = async (demand: Demand) => {
        const axiosResponse = await TokiesService.refuseDemand(demand.id, +storageService.get_artist_id())
        if(axiosResponse.status == 400) {
            await toast.promise(
                        async () => {
                            return Promise.reject()
                        },
                        {
                            error: {
                                render: axiosResponse.data.message,
                                position: toast.POSITION.BOTTOM_LEFT,
                                pauseOnHover: false,
                                theme: 'dark',
                            },
                        }
                    );
        }
        await getData()
    }

    const getData = async () => {
        setLoading(true)
        const artistId = +storageService.get_artist_id()
        console.log(artistId)
        if (!artistId) {
            setArtistIdNotSet(true)
            setLoading(false)
            return;
        }
        const allDemands = await TokiesService.getOpenDemandsByArtistId(artistId)
        const receivedDemands: Demand[] = []
        const sentDemands: Demand[] = []
        for(const demand of allDemands){
            if (demand.ownerType == DemandOwnerType.Artist)
                sentDemands.push(demand)
            else if (demand.ownerType == DemandOwnerType.Gallery)
                receivedDemands.push(demand)
        }
        setSentDemands(sentDemands);
        setReceivedDemands(receivedDemands);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return {
        receivedDemands,
        sentDemands,
        artistIdNotSet,
        loading,
        acceptDemand,
        refuseDemand
    }
}
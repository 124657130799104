
import Header from 'src/presentation/components/Header/Header';
import Marketplace from 'src/presentation/components/Marketplace/Marketplace';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';

interface ExploreMarketplaceScreenProps extends RouteComponentProps {}


const ExploreMarketplaceScreen = (props: ExploreMarketplaceScreenProps) => {
	return (
		<div className="main">
			<Header />
			<Marketplace {...props} />
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default ExploreMarketplaceScreen;

import Header from 'src/presentation/components/Header/Header';
import ZoneAdmin from 'src/presentation/components/ZoneAdmin/ZoneAdmin';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

export interface ZoneAdminProps extends RouteComponentProps {
	match: {
		params: {
			id: string;
		};
		path: string;
		isExact: boolean;
		url: string;
	};
}

const ZoneAdminScreen:FC<ZoneAdminProps> = (props:ZoneAdminProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<ZoneAdmin {...props} id={props.match.params.id} state={props.location.state} />
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default ZoneAdminScreen;

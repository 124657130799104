import i18n from 'src/i18n';

export const NoAdressFound = (): JSX.Element => {
	return (
		<div className="card">
			<p
				style={{
					textAlign: 'center',
				}}
				className="text-uppercase fw-bold text-font"
			>
				{i18n.t<string>('address.noRegisteredAddress')}
			</p>
		</div>
	);
};

import {
	ETHTokenType,
	ImmutableXClient,
	Link,
	ProviderPreference,
} from '@imtbl/imx-sdk';
import axios from 'axios';
import { Subject } from 'rxjs';
import { storageService } from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';
import {
	getBackEndUrl,
	getImmutableAPI,
	getImmutableLink,
} from 'src/env/SaasEnvironnement';

const linkAddress = getImmutableLink();
const apiAddress = getImmutableAPI();

const backendURL: string = getBackEndUrl();


export default class ImmutableProvider {
	link:any = null;
	client:any = null;
	connectionType:string;
	address:string;
	starkPublicKey:string;
	lastActivityTime:string;
	addressSubject:Subject<any>;
	
	constructor() {
		this.init();
		this.connectionType = storageService.get_connection_type();
		this.address = storageService.get_wallet_address()
		this.starkPublicKey = storageService.get_stark_public_key();

		this.addressSubject = new Subject();
	}

	/**
	 * Always call init when service used in a component
	 */
	async init() {
		this.link = new Link(linkAddress);
		this.client = await ImmutableXClient.build({ publicApiUrl: apiAddress });
	}

	async connectMetamask(isDesktop: boolean = true) {
		let provider = ProviderPreference.METAMASK;
		if (!isDesktop) {
			provider = ProviderPreference.WALLET_CONNECT;
		}

		try {
			const { address, starkPublicKey } = await this.link.setup({
				providerPreference: provider,
			});
			this.address = address;
			this.starkPublicKey = starkPublicKey;
			storageService.set_connection_type('MetaMask');
			storageService.set_wallet_address(address);
			storageService.set_stark_public_key(starkPublicKey);
			this.updateActitivy();
			this.addressSubject.next(address);
		} catch (err) {
			// Should handle error with popup
			// throw Error(err);
		}
	}

	disconnectMetamask() {
		this.address = null;
		this.starkPublicKey = null;
		this.addressSubject.next(null);
		storageService.remove_wallet_information();
	}

	async buyOrder(params:any) {
		try {
			const buyRequest = await this.link.buy(params);
			this.updateActitivy();
			return buyRequest;
		} catch (err) {
			throw err;
		}
	}

	async depositMetaMask() {
		try {
			const address = storageService.get_wallet_address();
			// Deposit ETH into IMX
			await this.link.deposit({
				type: ETHTokenType.ETH,
				user: address,
			});
			this.updateActitivy();
		} catch (err) {
			// Should handle error with popup
			// throw Error(err);
		}
	}

	async prepareWithdrawal(amount:number) {
		try {
			this.updateActitivy();
			return await this.link.prepareWithdrawal({
				type: ETHTokenType.ETH,
				amount,
			});
		} catch (err) {
			// Should handle error with popup
			// throw Error(err);
		}
	}

	async confirmWithdrawal() {
		try {
			await this.link.completeWithdrawal({
				type: ETHTokenType.ETH,
			});
			this.updateActitivy();
		} catch (err) {
			// Should handle error with popup
			// throw Error(err);
		}
	}

	/////////////////////////////// VIA BACKEND ///////////////////////////////

	async getBalance(wallet: any) {
		return (
			await axios.post(
				`${backendURL}/imx/balance`,
				{ walletAddress: wallet, id: storageService.get_user_id() },
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}

	async updateActitivy() {
		if (
			!(
				await axios.post(
					`${backendURL}/imx/balance`,
					{ id: storageService.get_user_id() },
					{
						headers: {
							Authorization: `Bearer ${storageService.get_access_token()}`,
						},
					}
				)
			).data
		) {
			this.disconnectMetamask();
		}
	}

	async weiToEth(valueInWei:number) {
		return (
			await axios.post(
				`${backendURL}/imx/balance`,
				{ price: valueInWei },
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}

	async getAllUserDeposits() {
		this.updateActitivy();
		return (
			await axios.post(`${backendURL}/imx/deposit/all`, this.address, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	async getAllUserWithdrawals() {
		this.updateActitivy();
		return (
			await axios.post(`${backendURL}/imx/withdraw/all`, this.address, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	async getPreparedUserWithdrawals() {
		this.updateActitivy();
		return (
			await axios.post(`${backendURL}/imx/withdraw/prepare`, this.address, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	/**
	 * Check Imx documentation for params values
	 */
	async getOrders(params:any) {
		this.updateActitivy();
		return (
			await axios.post(`${backendURL}/imx/orders`, params, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

	async getAssets(userWallet: string, collection: string, page_size = 0): Promise<NFTAsset[]> {
		return (
			await axios.post(
				`${backendURL}/imx/assets`,
				{
					userWallet: userWallet,
					collection: collection,
					page_size: page_size,
				},
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}
	async getAssetDetails(tokenAddress: string, tokenId: string) {
		return (
			await axios.post(
				`${backendURL}/imx/assetDetail`,
				{ tokenAddress: tokenAddress, tokenId: tokenId },
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}
}

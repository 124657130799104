import { Form, Modal } from 'react-bootstrap';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import GoogleLoginButton from 'src/presentation/components/Modals/LoginModal/GoogleLoginButton';
import { Divider, Typography } from '@mui/material';
import 'react-phone-number-input/style.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	RegisterModalProps,
	RegisterModalUIController,
} from 'src/business/controller/ui/RegisterModalUIController';

const RegisterModal = (props: RegisterModalProps) => {
	const {
		handleClose,
		hasCreatedNonConfirmedAccount,
		setHasCreatedNonConfirmedAccount,
		setTryingToRegister,
		handleLoginButton,
		setCode,
		code,
		confirm_code,
		email,
		password,
		resend_code_confirmation,
		resendCode,
		errorCode,
		tryingToRegister,
		callback_google_login,
		onGoogleError,
		firstName,
		setFirstName,
		lastName,
		setLastName,
		setEmail,
		setPassword,
		passwordValidationError,
		confirmPassword,
		setConfirmPassword,
		registerSubmit,
		emailvalidationError,
		t
	} = RegisterModalUIController(props);
	return (
		<Modal
			show={props.show}
			onHide={handleClose}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
				{hasCreatedNonConfirmedAccount ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<div
							onClick={() => {
								setHasCreatedNonConfirmedAccount(false);
								setTryingToRegister(false);
							}}
						>
							<ArrowBackIcon />
						</div>
						<Modal.Title className="ml-2">
							{t<string>('register.confirmEmail')}
						</Modal.Title>
					</div>
				) : (
					<>
						<Modal.Title className="ml-4">
							{t<string>('register.messagePreHeading').toString()}
						</Modal.Title>
						<button
							onClick={handleLoginButton}
							className="btn"
							style={{ padding: 16, color: "var(--primary-p-color)" }}
						>
							{t<string>('login.messagePreHeading').toString()}
						</button>
					</>
				)}
			</Modal.Header>
			<Modal.Body className="pt-0">
				{hasCreatedNonConfirmedAccount ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div>
							{' '}
							<span>{t<string>('register.enterCode')}</span>
						</div>
						<div
							style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}
						>
							<div>
								<input value={code} onChange={(e) => setCode(e.target.value)} />
							</div>
							<div>
								<button
									onClick={confirm_code}
									className="btn"
									style={{ padding: 16, color: "var(--primary-p-color)", marginLeft: 20 }}
								>
									{t<string>('register.confirm')}
								</button>{' '}
							</div>
						</div>
						<div
							style={{
								marginTop: 30,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span>
								{t<string>('register.receiveNothing')}
								<button
									className="btn btn-bordered-white btn-smaller ml-2"
									onClick={resend_code_confirmation}
								>
									{t<string>('register.resendCode')}
								</button>
							</span>
							{resendCode && (
								<Typography mt={3} variant="body2" color="green">
									{resendCode}
								</Typography>
							)}
							{errorCode && (
								<Typography mt={3} variant="body2" color={'error'}>
									{errorCode}
								</Typography>
							)}
						</div>
					</div>
				) : (
					<Form noValidate>
						{!tryingToRegister ? (
							<div className="row">
								<div className="col-12">
									<Divider />
									<GoogleLoginButton
										callback={callback_google_login}
										onLoginError={onGoogleError}
									/>
									<Divider
										light
										style={{
											height: 1,
											backgroundColor: 'var(--primary-color)',
											marginTop: 30,
										}}
									/>
									<div className="form-group mt-3">
										<label>
											{t<string>('register.messageFirstName').toString()}
										</label>
										<input
											type="text"
											className="form-control"
											name="firstname"
											required={true}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<label>
											{t<string>('register.messageLastName').toString()}
										</label>
										<input
											type="text"
											className="form-control"
											name="lastname"
											required={true}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<label>
											{t<string>('register.messageEmail').toString()}
										</label>
										<input
											type="email"
											className="form-control"
											name="email"
											required={true}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
										<span className="text-danger">{emailvalidationError}</span>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<label>
											{t<string>('register.messagePassword').toString()}
										</label>
										<input
											type="password"
											className="form-control"
											name="password"
											required={true}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<span className="text-danger">
											{passwordValidationError}
										</span>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group mt-3">
										<label>
											{t('register.messageConfirmPassword').toString()}
										</label>
										<input
											type="password"
											className="form-control"
											name="confirm-password"
											required={true}
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
										/>
									</div>
								</div>
								<span style={{ fontSize: 14 }}>
									* {t('register.legal')}
								</span>
								<div className="col-12">
									<button
										style={{ color: "var(--primary-p-color)" }}
										className="btn w-100 mt-3 mt-sm-4"
										type="submit"
										onClick={registerSubmit}
									>
										{' ' +
											t<string>('register.messageRegister').toString()}
									</button>
									<p className="pt-2 ">
										<small className="font-weight-light">
											{t<string>('register.messageTermOfUse').toString()}
										</small>
									</p>
								</div>
							</div>
						) : (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: '20vh' }}
							>
								<DnaLoader />
							</div>
						)}
					</Form>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default RegisterModal;


import { useEffect, useState } from "react";
import TokiesService from "src/service/TokiesService";

interface Props {
    validationId:string
}
export function StripeAccountUIController(props:Props) {
    const [connected, setConnected] = useState(true);
	const [balanceAvailable, setBalanceAvailable] = useState(0);
	const [balancePending, setBalancePending] = useState(0);
	const [currencyAvailable, setCurrencyAvailable] = useState('');
	const [currencyPending, setCurrencyPending] = useState('');
	const [loading, setLoading] = useState(false);

	const createStripeAccount = async ()=> {
        const stripeRequest = await TokiesService.createStripeAccount()
            .catch(e =>{console.error(e);})
        if(stripeRequest){
            const win = window.open(stripeRequest.data.url, '_blank');
            win.focus();
        }
    }

    const getStripeAccount = async ()=> {
        const marketRequest = await TokiesService.getCurrentMarket();
        if(marketRequest.data.stripeAccountId){
            const stripeAccountRequest = await TokiesService.getStripeAccount(marketRequest.data.stripeAccountId);
            if(stripeAccountRequest){
                await getStripeBalance(marketRequest.data.stripeAccountId);
            }
            setConnected(true);
        }
        else{
            setConnected(false);
        }
        setLoading(false);
    }

    const getStripeBalance = async (stripeAccountId:string)=> {
        const balanceRequest = await TokiesService.getStripeBalance(stripeAccountId);
        if(balanceRequest.data.available && balanceRequest.data.pending){
            setBalanceAvailable(balanceRequest.data.available[0].amount)
            setBalancePending(balanceRequest.data.pending[0].amount)
            setCurrencyAvailable(balanceRequest.data.available[0].currency)
            setCurrencyPending(balanceRequest.data.pending[0].currency)
        }
    }

    const validateAccount =  ()=>{
        if(props.validationId){
		    TokiesService.validateAccount({stripeId:props.validationId});
        }
    }
    const goToAccount = async ()=> {
        window.open("https://dashboard.stripe.com/login", '_blank')?.focus()
    }

    useEffect(() => {
        setLoading(true);
        validateAccount();
        getStripeAccount();
	}, [])

    return {loading, connected, balanceAvailable, balancePending, currencyAvailable, currencyPending, createStripeAccount, goToAccount}
}
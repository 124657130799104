import ApplicationStyle from "src/env/ApplicationStyle";

interface IApplicationConfiguration {
	environnement: string;
	marketId: string;
	prodCollection: string;
	immutableEnv: string;
	paypalSandBoxClientId: string;
	paypalSandBoxSecret: string;
	paypalLiveClientId: string;
	paypalLiveSecret: string;
	localBackend: string;
	backendDev: string;
	backendProd: string;
	collectionAddress: string;
	stripeProd: string;
	stripeTest: string;
	immutableApiSandbox: string;
	immutableApiLive: string;
	immutableLinkLive: string;
	immutableLinkSandbox: string;
	recaptchaLocalKey: string;
	recaptchaLocalPrivateKey: string;
	recaptchaLiveKey: string;
	recaptchaLivePrivateKey: string;
	saasName: string;
	authorPicture: string;
	applicationStyle: string;
	adminAddress: string;
	googleClientId: string;
}

export const applicationConfiguration: IApplicationConfiguration = {
	environnement: process.env.REACT_APP_ENV,
	marketId: process.env.REACT_APP_MARKET_ID || "",
	prodCollection: process.env.REACT_APP_PROD_COLLECTION || '',
	immutableEnv: process.env.REACT_APP_IMX_ENV,
	paypalSandBoxClientId: process.env.REACT_APP_PAYPAL_SANDBOX,
	paypalSandBoxSecret: process.env.REACT_APP_PAYPAL_SANDBOX_SECRET,
	paypalLiveClientId: process.env.REACT_APP_PAYPAL_LIVE,
	paypalLiveSecret: process.env.REACT_APP_PAYPAL_LIVE_SECRET,
	localBackend: process.env.REACT_APP_BACKEND_URL_LOCAL || '',
	backendDev: process.env.REACT_APP_BACKEND_URL_DEV || '',
	backendProd: process.env.REACT_APP_BACKEND_URL_PROD || '',
	collectionAddress: process.env.REACT_APP_COLLECTION_ADDRESS_DEV || '',
	stripeProd: process.env.REACT_APP_STRIPE_PROD || '',
	stripeTest: process.env.REACT_APP_STRIPE_TEST || '',
	immutableApiSandbox: process.env.REACT_APP_IMMUTABLE_API_SANDBOX || '',
	immutableApiLive: process.env.REACT_APP_IMMUTABLE_API_LIVE || '',
	immutableLinkLive: process.env.REACT_APP_IMMUTABLE_LINK_LIVE || '',
	immutableLinkSandbox: process.env.REACT_APP_IMMUTABLE_LINK_SANDBOX || '',
	recaptchaLocalKey: process.env.REACT_APP_RECAPTCHA_LOCAL_SITE_KEY || '',
	recaptchaLocalPrivateKey:
		process.env.REACT_APP_RECAPTCHA_LOCAL_PRIVATE_KEY || '',
	recaptchaLiveKey: process.env.REACT_APP_RECAPTCHA_LIVE_SITE_KEY || '',
	recaptchaLivePrivateKey:
		process.env.REACT_APP_RECAPTCHA_LIVE_PRIVATE_KEY || '',
	saasName: process.env.REACT_APP_SAAS_NAME || '',
	authorPicture: process.env.REACT_APP_AUTHOR_PICTURE || '',
	applicationStyle: process.env.REACT_APP_SAAS_STYLE || ApplicationStyle.ONE,
	adminAddress: process.env.REACT_APP_ADMIN_ADDRESS || "",
	googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || ""
};


import { useEffect, useMemo, useRef, useState } from "react";
import { CiPlay1, CiPause1 } from "react-icons/ci";
import { VscMute, VscUnmute } from "react-icons/vsc";
import { ImLoop } from "react-icons/im";
import { Duration } from "./Durations";
import IconButton  from '@mui/material/Button';

type Props = {
  playerRef: any;
  playing: boolean;
  loop: boolean;
  volume: number;
  muted: boolean;
  progress: number;
  duration: number;

  handlePlay: () => void;
  toggleMute: () => void;
  toggleLoop: () => void;
  handlePause: () => void;
  handleVolumeChange: (newVolume: number) => void;
};
export const PlayerControls = ({
  playerRef,
  loop,
  playing,
  volume,
  muted,
  progress,
  duration,
  handlePlay,
  toggleLoop,
  handlePause,
  handleVolumeChange,
  toggleMute,
}: Props) => {
  const [played, setPlayed] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const playPauseButtonRef = useRef<HTMLButtonElement>(null);

  const togglePlayAndPause = () => {
    if (playing) {
      handlePause();
    } else {
      handlePlay();
    }
  };
  const handleSeekMouseDown = (e: any) => {
      console.log(e);
      setSeeking(true);
    };
  
  const handleSeekChange = (e: any) => {
      setPlayed(parseFloat(e.target.value));
    };
  
  const handleSeekMouseUp = (e: any) => {
      playerRef.current?.seekTo(parseFloat(e.target.value));
      setSeeking(false);
    };
    const handleChangeInVolume =  (e: React.ChangeEvent<HTMLInputElement>) => {
       handleVolumeChange(Number(e.target.value));
      };
    useMemo(() => {
      setPlayed((prevPlayed) => {
        if (!seeking && prevPlayed !== progress) {
          return progress;
        }
        return prevPlayed;
      });
    }, [progress, seeking]);

    // shifts focus to play button on component mount
    useEffect(() => {
      playPauseButtonRef.current?.focus();
    }, []);

  return (
 <div className="bg-gray-50  rounded-b-xl py-10">
      <div className="mb-8 flex gap-x-10 px-10">
        <div className="row">
            {/* duration: time played  */}
            <div className="col text-left text-gray-600">
              <Duration seconds={duration * played} />
            </div>
            {/* duration: time left */}
            <div className="col text-right text-gray-600 flex">
              <Duration seconds={duration * (1 - played)} />
            </div>
        </div>
        
        {/* progress bar */}
        <div className="flex-1 mx-auto">
          <input
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
            className="w-full h-4 rounded-lg appearance-none  bg-slate-400 accent-gray-900 focus:outline focus:outline-cyan-500 "
          />
        </div>
      </div>

      <div className="row grid-cols-3 items-center ">
         {/* loop button */}
        <div className="col justify-center items-center">
			<IconButton 
				variant="contained"
				style={{ backgroundColor: 'var(--primary-color)',borderRadius: 10 }}
				id="basic-button"
				onClick={toggleLoop}
            >
                {loop? <ImLoop style={{color: 'var(--secondary-color)'}}/>:  <ImLoop />}
			
            </IconButton>
        </div>

        {/* play/pause button */}
        <div className="col justify-center">
			<IconButton 
				variant="contained"
        className="p-4"
				style={{ backgroundColor: 'var(--primary-color)',borderRadius: 15 }}
				id="basic-button"
        ref={playPauseButtonRef}
				onClick={togglePlayAndPause}
			>{playing ? <CiPause1 /> : <CiPlay1 />}
            </IconButton>
        </div>


        {/* volume control */}
        <div className="col justify-center items-center">

          {/* mute button */}
				<IconButton 
					variant="contained"
					style={{ backgroundColor: 'var(--primary-color)',borderRadius: 10 }}
					id="basic-button"
					onClick={toggleMute}
				>
                {muted ? <VscMute /> : <VscUnmute />}
                </IconButton>

          {/* volume slider */}
          <input
            type="range"
            className="focus:outline focus:outline-cyan-500 w-[50%] h-2 rounded-lg  bg-slate-400 accent-gray-900"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={handleChangeInVolume}
          />
        </div>
      </div>
    </div>
  );
};
import PoweredByTokies from 'src/presentation/components/PoweredByTokies/PoweredByTokies';
import NFTMetadata from 'src/presentation/components/NFTMetadata';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import NFTAsset from 'src/business/entities/NFTAsset';

export interface NftDetailsProps extends RouteComponentProps{
	location: {
		pathname: string;
		state: {
			asset: NFTAsset;
		};
		search: string;
		hash: string;
	};
}

const NFTDetails:FC<NftDetailsProps> = (props: NftDetailsProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<NFTMetadata {...props} />
			<PoweredByTokies />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default NFTDetails;

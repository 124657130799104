import Header from 'src/presentation/components/Header/Header';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { NFT } from 'src/business/entities/NFT';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import BuyNowController from 'src/business/controller/ui/BuyNowController';

export interface BuyNowProps extends RouteComponentProps {
	location: {
		state: {
			nft: NFT;
		};
		pathname: string;
		search: string;
		hash: string;
	};
}

const BuyNow: FC<BuyNowProps> = (props: BuyNowProps): JSX.Element => {
	const {
		email,
		setEmail,
		error,
		isLogin,
		password,
		setPasswrod,
		onSubmit,
		t,
	} = BuyNowController(props);

	if (isLogin === false) {
		return (
			<div className="main">
				<Header />
				<section className="author-area">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-7">
								<div className="intro text-center">
									<span>{t<string>('login.messagePreHeading')}</span>
									<h3 className="mt-3 mb-0">
										{t<string>('login.messageHeading')}
									</h3>
								</div>
								<form className="item-form card no-hover">
									<div className="row">
										<div className="col-12">
											<div className="form-group mt-3">
												<input
													type="email"
													className="form-control"
													name="email"
													placeholder={t<string>('login.messageLogin')}
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-12">
											<div className="form-group mt-3">
												<input
													type="password"
													className="form-control"
													name="password"
													placeholder={t<string>('login.messagePassword')}
													value={password}
													onChange={(e) => setPasswrod(e.target.value)}
												/>
											</div>
										</div>

										<span className="text-danger">{error}</span>

										<div className="col-12">
											<button
												style={{ color: "var(--primary-p-color)" }}
												className="btn w-100 mt-3 mt-sm-4"
												type="submit"
												onClick={(e) => onSubmit(e)}
											>
												{t<string>('login.messageLogIn')}{' '}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
				<Scrollup />
				<ModalMenu />
			</div>
		);
	}

	return (
		<div className="main">
			<Header />
			<section className="author-area explore-area popular-collections-area">
				<div className="container">
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<DnaLoader />
					</div>
				</div>
			</section>
			<Scrollup />
			<ModalMenu />
		</div>
	);
};

export default BuyNow;

import Header from 'src/presentation/components/Header/Header';
import Hero from 'src/presentation/components/Hero/Hero';
import Explore from 'src/presentation/components/Explore/ExploreLittle';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import About from 'src/presentation/components/About/About';
import Footer from 'src/presentation/components/Footer/Footer';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import ApplicationStyle from 'src/env/ApplicationStyle';
import HeroPicture from 'src/presentation/components/Hero/HeroPicture';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import { RouteComponentProps } from 'react-router';

export interface HomePageProps extends RouteComponentProps {}

const HomePage = (props: HomePageProps) => {
	return (
		<div className="main">
			<Header />
			{applicationConfiguration.applicationStyle === ApplicationStyle.ONE || applicationConfiguration.applicationStyle === ApplicationStyle.THREE && (
				<Hero {...props} />
			)}
			{applicationConfiguration.applicationStyle === ApplicationStyle.TWO && (
				<HeroPicture />
			)}
			<Explore {...props} />
			<About />
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default HomePage;

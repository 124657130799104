import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storageService } from 'src/ApplicationContext';
import { MetamaskLogicController } from 'src/business/controller/logic/MetamaskLogicController';
import i18n from 'src/i18n';
import TokiesService from 'src/service/TokiesService';

export function DepositUIController() {
	const [showIosModal, setShowIosModal] = useState(false);
	const [image, setImage] = useState<string>();
	const [title, setTitle] = useState<string>();
	const [content, setContent] = useState<string>();
	const [type, setType] = useState<string>();

    const { t } = useTranslation();

	const metamaskLogicController = new MetamaskLogicController();

	useEffect(() => {
		getWalletData();
	}, []);

	const connectMetamask = () => {
		metamaskLogicController.connectMetamask();
	};

	const getWalletData = async (): Promise<void> => {
		const userId = storageService.get_user_id();
		const wallets = await TokiesService.getWalletByUser(userId);
		const currentWallet = wallets.find((wallet: any) => wallet.connected);

		if (currentWallet?.type === 'MetaMask') {
			setShowIosModal(true);
			setType(currentWallet.type);
			setImage(
				'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png'
			);
			setTitle(i18n.t<string>('deposit.metamask.title'));
			setContent(i18n.t<string>('deposit.metamask.content'));
		}
	};

	return { connectMetamask, t, type, image, title, content, showIosModal, setShowIosModal };
}

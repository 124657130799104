
import { useEffect, useState } from "react";
import { Market } from "src/business/entities/Market";
import Transaction from "src/business/entities/Transaction";
import { applicationConfiguration } from "src/env/ApplicationConfiguration";
import TokiesService from "src/service/TokiesService";


export function ReceiptsAdminUIController() {
  const [sortedTransactions, setSortedTransactions] = useState<Transaction[]>([]);
	const [market, setMarket] = useState<Market>();
	const [periods, setPeriods] = useState<any>([]);
	const [loading, setLoading] = useState(false);

	const getData = async ()=>{          // TODO a optimiser 
		const transactions = await (await TokiesService.getAllTransactionsForMarketPlace(
			applicationConfiguration.marketId
		));
		let marketPlace:any = await TokiesService.getCurrentMarket();
		let keyWalletMarket = await TokiesService.getWalletById(
        marketPlace.data.walletId
    );
    marketPlace.walletId=keyWalletMarket.data.publicKey;
    setMarket(marketPlace.data)

    const sortedTransactions =  transactions.sort(function (a:Transaction, b:Transaction) {
      const dateA = a.createdAt;
      const dateB = b.createdAt;
      if (dateA > dateB) return 1;
      if (dateA < dateB) return -1;
      return 0;
    });
    setSortedTransactions(sortedTransactions)
    const selectPeriod = (year: number, month: number, debut = 15, fin = 14) => {
      const transactionsPeriod = [];
      let countTransaction = 0;
      let passTarget = false;
      if (sortedTransactions[0]) {
        while (
          countTransaction < sortedTransactions.length &&
          !passTarget
        ) {
          const dateEval = new Date(
            sortedTransactions[countTransaction].createdAt
          );
  
          if (
            dateEval.getDate() >= debut &&
            fin < debut &&
            year === dateEval.getFullYear() &&
            ((month === dateEval.getMonth() && month === 11) ||
              (month === dateEval.getMonth() + 1 && month !== 11))
          ) {
            transactionsPeriod.push(
              sortedTransactions[countTransaction]
            );
          } else if (
            dateEval.getDate() <= fin &&
            fin < debut &&
            ((year === dateEval.getFullYear() &&
              month !== 0 &&
              dateEval.getMonth() === month) ||
              (year === dateEval.getFullYear() - 1 &&
                month === 11 &&
                dateEval.getMonth() === (month + 1) % 12))
          ) {
            transactionsPeriod.push(
              sortedTransactions[countTransaction]
            );
          } else if (
            dateEval.getDate() <= fin &&
            month === dateEval.getMonth() &&
            dateEval.getDate() >= debut &&
            fin > debut &&
            year === dateEval.getFullYear()
          ) {
            transactionsPeriod.push(
              sortedTransactions[countTransaction]
            );
          }
          // else if (month < dateEval.getMonth()+1 || year!=dateEval.getFullYear()){
          //     passTarget=true;
          // }
          countTransaction++;
        }
      }
      return transactionsPeriod;
    }

    const allReports = [];
    if (sortedTransactions[0]) {
      const firstDate = new Date(sortedTransactions[0].createdAt);
      let refYear = firstDate.getFullYear();
      let refMonth = firstDate.getMonth();
      let refLength = 0;
      if (firstDate.getDate() >= 15) {
        refMonth++;
        refMonth = refMonth % 12;
        if (refMonth === 0) {
          refYear++;
        }
      }
      allReports.push(selectPeriod(refYear, refMonth));
      refLength += allReports[0].length;

      while (refLength < sortedTransactions.length &&refYear > 2000) {
        refMonth++;
        refMonth = refMonth % 12;
        if (refMonth === 0) {
          refYear++;
          refYear = refYear % 2100; //  En cas de bug evite les infinite loop
        }
        allReports.push(selectPeriod(refYear, refMonth));
        refLength += allReports[allReports.length - 1].length;
      }
    }
    setPeriods(allReports)
    setLoading(false)
	}

  useEffect(()=>{
		setLoading(true)
		getData()
	},[])

  return {market, periods, loading, sortedTransactions}
}
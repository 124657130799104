const Scrollup = () => {
	return (
		<div id="scroll-to-top" className="scroll-to-top">
			<a href="#header" className="smooth-anchor">
				<i className="fas fa-arrow-up" />
			</a>
		</div>
	);
};

export default Scrollup;

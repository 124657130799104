import {
	Document,
	View,
	Page,
	Text,
	PDFDownloadLink,
} from '@react-pdf/renderer';
import i18n from 'src/i18n';
import { storageService } from 'src/ApplicationContext';

interface Props {
	transactions: any[];
    markets: object
}


const Receipts = (props: Props) =>  {
	const MyDocument = (transaction: any, markets: any) => {
		const tps=0.05
		const tvq=0.09975
		if (transaction.type == 'wallet') {
			return (
				<Document>
					<Page size="A4" style={{ backgroundColor: '#E4E4E4' }}>
						<View style={{ margin: 10, padding: 10, flexDirection: 'row' }}>
							<View style={{ margin: 10, padding: 10 }}>
								<Text style={{ fontSize: 30 }}>
									{markets[transaction.marketId].clientName}
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].street}
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].city} (
									{markets[transaction.marketId].shortProvince})
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].postalCode}{' '}
									{markets[transaction.marketId].country}
								</Text>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 3 }}></View>
							{/* <View style={{margin: 10,padding: 10}}>
        	                    <Text >Facture #</Text>
        	                    <Text >{transaction.id}</Text>
        	                </View> */}
						</View>
						<View style={{ margin: 10, padding: 10, flexDirection: 'column' }}>
							<Text style={{ fontSize: 50 }}>{i18n.t<string>('recu.titre')}</Text>
							<View
								style={{ marginHorizontal: 10, padding: 10, flexGrow: 1 }}
							></View>
							<Text style={{ fontSize: 15 }}>
								{i18n.t<string>('recu.date')} {transaction.createdAt}
							</Text>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 20 }}>
									{i18n.t<string>('recu.noClient')}{' '}
								</Text>
								<Text style={{ fontSize: 20 }}>
									{storageService.get_user_id()}
								</Text>
							</View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 18 }}>{i18n.t<string>('recu.from')} </Text>
								<Text style={{ fontSize: 12 }}>
									{storageService.get_wallet_address()}
								</Text>
							</View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 18 }}>{i18n.t<string>('recu.to')} </Text>
								<Text style={{ fontSize: 12 }}>
									{markets[transaction.marketId].walletId}
								</Text>
							</View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 20 }}>{i18n.t<string>('recu.site')} </Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].siteCommerce}
								</Text>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							<View style={{ flexDirection: 'row' }}>
								<View style={{ flexDirection: 'column', flexGrow: 3 }}>
									<Text>{i18n.t<string>('recu.nft')}</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{transaction.nftName}
									</Text>
									<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
								</View>
								<View style={{ flexDirection: 'column', flexGrow: 1 }}>
									<Text>{i18n.t<string>('recu.eth')}</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{transaction.priceETH}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										{i18n.t<string>('recu.subtotal')}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{i18n.t<string>('recu.tps')}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										{i18n.t<string>('recu.tvq')}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{i18n.t<string>('recu.tip')}
									</Text>
									<Text style={{ fontSize: 25, padding: 5 }}>
										{i18n.t<string>('recu.total')}
									</Text>
								</View>
								<View style={{ flexDirection: 'column', flexGrow: 1 }}>
									<Text>{i18n.t<string>('recu.cad')}</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${transaction.priceCAD}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										${(transaction.priceCAD * 1.0).toFixed(2)}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${(transaction.priceCAD * tps).toFixed(2)}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										${(transaction.priceCAD * tvq).toFixed(2)}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${transaction.tip.toFixed(2)}
									</Text>
									<Text style={{ fontSize: 25, padding: 5 }}>
										$
										{((Number((transaction.priceCAD * tps).toFixed(2))+Number((transaction.priceCAD * tvq).toFixed(2))+Number((transaction.priceCAD * 1.0).toFixed(2))).toFixed(2))}
									</Text>
								</View>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							<View style={{ flexDirection: 'row' }}>
								<View style={{ flexDirection: 'column' }}>
									<Text style={{ fontSize: 15 }}>
										{i18n.t<string>('recu.tps')} :{' '}
									</Text>
									<Text style={{ fontSize: 15 }}>
										{i18n.t<string>('recu.tvq')} :{' '}
									</Text>
								</View>
								<View style={{ flexDirection: 'column' }}>
									<Text style={{ fontSize: 15 }}>
										{markets[transaction.marketId].numeroTPS}
									</Text>
									<Text style={{ fontSize: 15 }}>
										{markets[transaction.marketId].numeroTVQ}
									</Text>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		} else {
			return (
				<Document>
					<Page size="A4" style={{ backgroundColor: '#E4E4E4' }}>
						<View style={{ margin: 10, padding: 10, flexDirection: 'row' }}>
							<View style={{ margin: 10, padding: 10 }}>
								<Text style={{ fontSize: 30 }}>
									{markets[transaction.marketId].clientName}
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].street}
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].city} (
									{markets[transaction.marketId].shortProvince})
								</Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].postalCode}{' '}
									{markets[transaction.marketId].country}
								</Text>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 3 }}></View>
							{/* <View style={{margin: 10,padding: 10}}>
								<Text >Facture #</Text>
								<Text >{transaction.id}</Text>
							</View> */}
						</View>
						<View style={{ margin: 10, padding: 10, flexDirection: 'column' }}>
							<Text style={{ fontSize: 50 }}>{i18n.t<string>('recu.titre')}</Text>
							<View
								style={{ marginHorizontal: 10, padding: 10, flexGrow: 1 }}
							></View>
							<Text style={{ fontSize: 15 }}>
								{i18n.t<string>('recu.date')} {transaction.createdAt}
							</Text>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 20 }}>
									{i18n.t<string>('recu.noClient')}{' '}
								</Text>
								<Text style={{ fontSize: 20 }}>
									{storageService.get_user_id()}
								</Text>
							</View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 18 }}>
									{i18n.t<string>('recu.credit')}{' '}
								</Text>
								<Text style={{ fontSize: 15 }}>{transaction.type}</Text>
								<Text style={{ marginLeft: 10, fontSize: 18 }}>
									{i18n.t<string>('recu.number')}{' '}
								</Text>
								<Text style={{ fontSize: 15 }}>{transaction.cardNumber}</Text>
								<Text style={{ marginLeft: 10, fontSize: 18 }}>
									{i18n.t<string>('recu.expiracy')}{' '}
								</Text>
								<Text style={{ fontSize: 15 }}>{transaction.expiracy}</Text>
							</View>
							<View style={{ flexDirection: 'row', margin: 10 }}>
								<Text style={{ fontSize: 20 }}>{i18n.t<string>('recu.site')} </Text>
								<Text style={{ fontSize: 15 }}>
									{markets[transaction.marketId].siteCommerce}
								</Text>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							<View style={{ flexDirection: 'row' }}>
								<View style={{ flexDirection: 'column', flexGrow: 3 }}>
									<Text>{i18n.t<string>('recu.nft')}</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{transaction.nftName}
									</Text>
									<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
								</View>
								<View style={{ flexDirection: 'column', flexGrow: 1 }}>
									<Text> </Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{' '}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										{i18n.t<string>('recu.subtotal')}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{i18n.t<string>('recu.tps')}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										{i18n.t<string>('recu.tvq')}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										{i18n.t<string>('recu.tip')}
									</Text>
									<Text style={{ fontSize: 25, padding: 5 }}>
										{i18n.t<string>('recu.total')}
									</Text>
								</View>
								<View style={{ flexDirection: 'column', flexGrow: 1 }}>
									<Text>{i18n.t<string>('recu.cad')}</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${transaction.priceCAD}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										${(transaction.priceCAD * 1.0).toFixed(2)}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${(transaction.priceCAD * tps).toFixed(2)}
									</Text>
									<Text style={{ fontSize: 15, padding: 5 }}>
										${(transaction.priceCAD * tvq).toFixed(2)}
									</Text>
									<Text
										style={{
											fontSize: 15,
											padding: 5,
											backgroundColor: '#E4E4EF',
										}}
									>
										${transaction.tip.toFixed(2)}
									</Text>
									<Text style={{ fontSize: 25, padding: 5 }}>
										$
										{((Number((transaction.priceCAD * tps).toFixed(2))+Number((transaction.priceCAD * tvq).toFixed(2))+Number((transaction.priceCAD * 1.0).toFixed(2))).toFixed(2))}
									</Text>
								</View>
							</View>
							<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
							{/* <Text style={{fontSize:25}}>Siege Social</Text>
							<Text style={{fontSize:15}}>{markets[transaction.marketId].street}</Text>
							<Text style={{fontSize:15}}>{markets[transaction.marketId].city} ({markets[transaction.marketId].shortProvince})</Text>
							<Text style={{fontSize:15}}>{markets[transaction.marketId].postalCode} {markets[transaction.marketId].country}</Text> */}
							<View style={{ flexDirection: 'row' }}>
								<View style={{ flexDirection: 'column' }}>
									<Text style={{ fontSize: 15 }}>
										{i18n.t<string>('recu.tps')} :{' '}
									</Text>
									<Text style={{ fontSize: 15 }}>
										{i18n.t<string>('recu.tvq')} :{' '}
									</Text>
								</View>
								<View style={{ flexDirection: 'column' }}>
									<Text style={{ fontSize: 15 }}>
										{markets[transaction.marketId].numeroTPS}
									</Text>
									<Text style={{ fontSize: 15 }}>
										{markets[transaction.marketId].numeroTVQ}
									</Text>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
	};

	{
		if (props.transactions.length < 1) {
			return (
				<div style={{ textAlign: 'center' }}>
					<h3>
						{i18n.t<string>('profil.tab_3.noReceipt')}
					</h3>
				</div>
			);
		}
		{
		return (
			<div className="container">
				<div className="row">
					<div className="col-4">
						<h5>{i18n.t<string>('profil.tab_3.nom')}</h5>
					</div>
					<div className="col-4 my-auto intro-content">
						<h5>{i18n.t<string>('profil.tab_3.date')}</h5>
					</div>
					<div className="col-4 my-auto intro-content">
						<h5>{i18n.t<string>('profil.tab_3.recu')}</h5>
					</div>
				</div>
				<div className="single-tab-list">
					{props.transactions.sort((a,b)=>b.id-a.id).map((transaction, idx) => {
						const fileNameDownload = transaction.nftName.concat(
							'_',
							transaction.createdAt,
							'.pdf'
						);
						return (
							<div className="row" key={idx}>
								<div className="col">
									<h5>{transaction.nftName}</h5>
								</div>
								<div className="col my-auto intro-content">
									{transaction.createdAt.slice(0, 10)}
								</div>
								<div className="col my-auto intro-content">
									<PDFDownloadLink
										document={MyDocument(transaction, props.markets)}
										fileName={fileNameDownload}
									>
										<div className="btn btn-bordered-white pa-2 ma-2">
											{' '}
											{i18n.t<string>('profil.tab_3.download')}
										</div>
									</PDFDownloadLink>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
}

export default Receipts;

import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Divider } from '@mui/material';
import GoogleLoginButton from 'src/presentation/components/Modals/LoginModal/GoogleLoginButton';
import { LoginModalUIController } from 'src/business/controller/ui/LoginModalUIController';
import { MetaMaskInpageProvider } from '@metamask/providers';
import { MetaMaskComponent } from './MetaMaskComponent';
import { RouteComponentProps } from 'react-router';

declare global {
	interface Window {
		ethereum?: MetaMaskInpageProvider;
	}
}

const button_width = 320;

export interface LoginModalProps extends RouteComponentProps {
	onShowLogin: (value: boolean) => void;
	onShowRegister: (value: boolean) => void;
	onShowForgotPasswordModal: () => void;
	show: boolean;
	returnProfil: boolean;
}

const LoginModal = (props: LoginModalProps) => {
	const {
		handleRegisterButton,
		handleClose,
		t,
		connecting,
		mfaRequired,
		wrongMfaCodeError,
		onClick,
		loginSubmit,
		email,
		emailValidationError,
		callback_google_login,
		password,
		setEmail,
		setCode,
		setPassword,
		onGoogleError,
		handleForgetPassword,
		code,
		confirmCode,
		loginValidationError,
		passwordValidationError,
		public_key,
		message,
		connectWithMetamask,
		passwordChangeEmailSent,
	} = LoginModalUIController(props);

	const closeModal = () => {
		props.onShowLogin(false);
	};

	return (
		<Modal
			show={props.show}
			onHide={() => handleClose(props)}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
				<Modal.Title className="ml-4">
					{t<string>('login.messagePreHeading').toString()}
				</Modal.Title>
				<button
					onClick={handleRegisterButton}
					className="btn"
					style={{ padding: 16, color: "var(--primary-p-color)" }}
				>
					{t<string>('register.messagePreHeading').toString()}
				</button>
			</Modal.Header>
			<Modal.Body className="pt-0">
				{public_key && message && (
					<MetaMaskComponent
						handleClose={closeModal}
						{...props}
						email={email}
						setEmail={setEmail}
						public_key={public_key}
						message={message}
					/>
				)}
				{!public_key && !message && (
					<div>
						<Form noValidate>
							{connecting ? (
								<div
									className="d-flex justify-content-center align-items-center"
									style={{ height: '20vh' }}
								>
									<div
										className="spinner-border"
										style={{ height: '3rem', width: '3rem' }}
										role="status"
									>
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : !mfaRequired ? (
								<div className="row">
									<div className="col-12">
										<Divider />
										<div className="form-group mt-2">
											<label>
												{t<string>('login.messageLogin').toString()}
											</label>
											<input
												type="email"
												className="form-control"
												name="email"
												required={true}
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
											<span className="text-danger">
												{emailValidationError}
											</span>
										</div>
									</div>

									<div className="col-12">
										<div className="form-group mt-3">
											<label>
												{t<string>('login.messagePassword').toString()}
											</label>
											<input
												type="password"
												className="form-control"
												name="password"
												required={true}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
											<span className="text-danger">
												{passwordValidationError}
											</span>
										</div>
									</div>

									<span className="text-danger">{loginValidationError}</span>

									<div className="col-12">
										<button
											className="btn w-100 mt-3 mt-sm-4"
											type="submit"
											style={{ color: 'white' }}
											onClick={(
												e: React.MouseEvent<HTMLButtonElement, MouseEvent>
											) => loginSubmit(e, email, password, props)}
										>
											{t<string>('login.messageLogIn') + ' '}
										</button>
										{!passwordChangeEmailSent ? (
											<a
												className="row justify-content-center pt-2"
												href="/"
												onClick={(e) => handleForgetPassword(e)}
											>
												{t<string>('login.messageForgetPassword') + ' '}
											</a>
										) : (
											<a
												aria-disabled="true"
												className="row justify-content-center pt-2"
												href="/"
												onClick={(e) => handleForgetPassword(e)}
											>
												{t<string>('login.messageForgetPassword') + ' '}
											</a>
										)}
									</div>
									<div className="col-12">
										<Divider
											light
											className='my-3'
											style={{
												height: 1,
												backgroundColor: 'var(--primary-color)',
											}}
										/>
										<GoogleLoginButton
											buttonWidth={button_width}
											callback={() => callback_google_login()}
											onLoginError={() => onGoogleError()}
										/>
										<div
											className='my-2'
											style={{
												justifyContent: 'center',
												display: 'flex',
											}}
										>
											<Button
												style={{
													backgroundColor: 'var(--primary-color)',
													width: button_width,
												}}
												onClick={(e) => connectWithMetamask(e)}
												variant="contained"
											>
												{t('login.connectWithMetamask')}
												<img
													style={{ width: 30, marginLeft: 10 }}
													src={'../../../img/metamask.png'}
												/>
											</Button>
										</div>
									</div>
								</div>
							) : (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div>
										{' '}
										<span>{t<string>('login.mfaEnterCode')}</span>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginTop: 20,
										}}
									>
										<div>
											<input
												value={code}
												onChange={(e) => setCode(e.target.value)}
											/>
										</div>
										<div>
											<button
												onClick={async (e) =>
													await confirmCode(e, email, code, props)
												}
												className="btn"
												style={{ padding: 16, color: 'white', marginLeft: 20 }}
											>
												{t<string>('login.mfaConfirm')}
											</button>
										</div>
									</div>
									<div>
										<span className="text-danger">{wrongMfaCodeError}</span>
									</div>
								</div>
							)}
							<div
								className="mt-5"
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<span className="powerLink" onClick={onClick}>
									{t<string>('header.powered')}
								</span>
							</div>
						</Form>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};
export default LoginModal;


import { isMobile } from 'react-device-detect';
import { Dispatch, SetStateAction, useEffect } from 'react';
import FiltersModal from '../ZoneAdmin/FiltersModal';
import { RouteComponentProps } from 'react-router';
import { PaintingsUIController } from 'src/business/controller/ui/PaintingsUIController';
import NFTCard from './NFTCard';

interface GalerieProps extends RouteComponentProps{
	assets: any[] ;
	title?:string
	searchBar:boolean
	switchComponent?:(updateAsset:(assets:any[])=>void,setSearchByName:Dispatch<SetStateAction<string>>) =>JSX.Element
}

const PaintingsTab = ({assets,title,searchBar,switchComponent}: GalerieProps) => {
    const {searchByName,setSearchByName,onSearchKeyDown,sortedElement,updateAsset,setShowLogin,showLogin} = PaintingsUIController();
	
	useEffect(()=>{
		updateAsset(assets)
	},[])
	return (
		<>
			{title?
				<div
					style={{
						paddingLeft: isMobile ? undefined : '38%',
						alignItems: 'center',
					}}
				>
					<h3> {title} </h3>
				</div>
			:
				<></>
			}{searchBar?
			<>
				<div className='row justify-content-between'>
					<div className="widget-content search-widget col-lg-6 col-md-8 col-sm-10 single-widget my-auto py-2 ">
						<div className='row'>
							<input
								type="text"
								className="form-control col"
								value={searchByName}
								onKeyDown={(e)=> {
									onSearchKeyDown(e)
								}}
								onChange={(e)=> {
									setSearchByName(e.target.value)
								}}
								placeholder="Enter your keywords"
							/>
							<a
								className="col-2 my-auto"
								onClick={()=> console.log(searchByName)}
							>
								<i className="fas fa-search"></i>
							</a>
						</div>
					</div>
					<div className=' mt-2  align-items-center'>
						{switchComponent(updateAsset,setSearchByName)}
					</div>
				</div>
			</>:
				<></>

			}
			
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				{sortedElement.map((item, idx) => {return <NFTCard item={item} idx={idx} key={idx}/>})}
			</div>
			<FiltersModal
				remaining={10}
				show={showLogin}
				onShowIdleModal={(value) => {
					setShowLogin(value);
				}}
			/>
		</>
	);
};

export default PaintingsTab;

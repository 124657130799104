import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer className="footer-area">
			<div className="footer-top">
				<div className="container">
					<div className="footer-items">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ width: '50%' }}>
								<img style={{ width: 250 }} src={t<string>('img.header')} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
								<div className="copyright-left" >
									<span style={{ 'color': 'var(--primary-color)'}}> {t<string>("footer.rightReserved")} </span>
								</div>
								<div
									className="copyright-right"
									style={{ color: 'var(--primary-color)' }}
								>
									<a
										style={{ color: 'var(--primary-color)' }}
										href="https://tokies.io/home"
									>
										{t<string>("footer.madeWith")} <i className="fas fa-heart" />{t<string>("footer.byTokies")}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

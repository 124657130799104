import 'react-tabs/style/react-tabs.css';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import NoWalletFound from 'src/presentation/components/Wallet/NoWalletFound';
import Wallets from 'src/presentation/components/Wallet/Wallets';
import DeleteWalletModal from './DeleteWalletModal';
import { AuthorWalletUIController } from 'src/business/controller/ui/AuthorWalletUIController';

const AuthorWallet = () => {
	const {
		setWalletToDelete,
		loading,
		setShowDeleteConfirmationModal,
		onConfirmWalletDeletion,
		showDeleteConfirmationModal,
		connectedWallet,
		wallets,
		user,
		removeWallet,
		changeConnectedWallet,
	} = AuthorWalletUIController();

	if (loading) {
		return (
			<div className="container">
				<div className="d-flex justify-content-center">
					<DnaLoader />
				</div>
			</div>
		);
	}

	return (
		<div>
			<div>
				<DeleteWalletModal
					setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
					setWalletToDelete={setWalletToDelete}
					showDeleteConfirmationModal={showDeleteConfirmationModal}
					onConfirmWalletDeletion={onConfirmWalletDeletion}
				/>
			</div>
			{connectedWallet ? (
				<Wallets
					onChangeConnectedWallet={changeConnectedWallet}
					onRemoveWallet={removeWallet}
					walletAddress={connectedWallet.publicKey}
					walletType={connectedWallet.type}
					walletId={connectedWallet.id}
					user={user}
					solde={connectedWallet.solde ? connectedWallet.solde : ''}
					disconnectWallet={wallets.filter(
						(wallet) => wallet.connected === false
					)}
				/>
			) : (
				<NoWalletFound />
			)}
		</div>
	);
};
export default AuthorWallet;

import i18n from 'src/i18n';

const NoWalletFound = (): JSX.Element => {
    return (
        <div style={{ 'display': 'flex', 'justifyContent': 'center', alignItems: 'center'}}>
            <a
                style={{ color: "var(--primary-p-color)"}}
                className="btn  btn-smaller m-4"
                href="/wallet-connect"
            >
                {i18n.t<string>('profil.connect')}
            </a>
        </div>
    )

}

export default NoWalletFound;

import { Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { MetaMaskComponentController } from 'src/business/controller/ui/MetaMaskComponentController';

export interface MetaMaskProps extends RouteComponentProps {
	email: string;
	setEmail: (email: string) => void;
	public_key: string;
	message: string;
	handleClose: () => void;
}

export const MetaMaskComponent = (props: MetaMaskProps) => {
	const { t } = useTranslation();
	const { message, email, setEmail } = props;
	const {
		confirmSignature,
		emailvalidationError,
		firstName,
		setFirstName,
		lastName,
		setLastName,
		readyToCreateMetaMaskAccount,
		signatureError,
		createMetaMaskUser,
		validationError,
	} = MetaMaskComponentController(props);

	if (!readyToCreateMetaMaskAccount) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ margin: 10 }}>{t('register.mustSignMessage')}</span>
				<span style={{ margin: 10 }}>
					{' '}
					{t('register.messageToSign')} : {message}
				</span>
				<button
					onClick={confirmSignature}
					className="btn"
					style={{ padding: 16, color: 'white', marginLeft: 20, marginTop: 10 }}
				>
					{t('register.confirm')}
				</button>
				<span className="text-danger"> {signatureError} </span>
			</div>
		);
	}

	return (
		<div>
			<span> {t('login.createYourMetaMaskAccount')} </span>
			<Form noValidate>
				<div className="row">
					<div className="col-12">
						<Divider
							light
							style={{
								height: 1,
								backgroundColor: 'var(--primary-color)',
								marginTop: 30,
							}}
						/>
						<div className="form-group mt-3">
							<label>{t<string>('register.messageFirstName').toString()}</label>
							<input
								type="text"
								className="form-control"
								name="firstname"
								required={true}
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group mt-3">
							<label>{t<string>('register.messageLastName').toString()}</label>
							<input
								type="text"
								className="form-control"
								name="lastname"
								required={true}
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group mt-3">
							<label>{t<string>('register.messageEmail').toString()}</label>
							<input
								type="email"
								className="form-control"
								name="email"
								required={true}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<span className="text-danger">{emailvalidationError}</span>
						</div>
					</div>
					<span style={{ fontSize: 14 }}>* {t('register.legal')}</span>
					<span className="text-danger">{validationError}</span>
					<div className="col-12">
						<button
							style={{ color: 'white' }}
							className="btn w-100 mt-3 mt-sm-4"
							type="submit"
							onClick={(e) => createMetaMaskUser(e)}
						>
							{' ' + t<string>('register.messageRegister').toString()}
						</button>
						<p className="pt-2 ">
							<small className="font-weight-light">
								{t<string>('register.messageTermOfUse').toString()}
							</small>
						</p>
					</div>
				</div>
			</Form>
		</div>
	);
};

import Modal from "react-bootstrap/Modal";
import { Oval } from "react-loader-spinner";
import i18n from "src/i18n";

interface Props {
  show: boolean;
  handleClose: () => void;
  title: string | undefined
}

const ModalLoader = ({ show, handleClose, title }: Props) => {
  const name = title ? title : i18n.t<string>("zone_admin.mintage.creatingNFT");
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title> {name} </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Oval
          height={80}
          width={80}
          color="var(--primary-color)"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalLoader;

import TokiesService from 'src/service/TokiesService';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { immutableProvider, storageService } from 'src/ApplicationContext';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import { NFT } from 'src/business/entities/NFT';
import Wallet from 'src/business/entities/Wallet';

class PaymentService {
	tauxProv = 0.1;
	tauxGouv = 0.05;

	// async mintNft(nft: NFT, wallet: Wallet) { 
		// const order_id = await TokiesService.buyNft({
		// 	buyerAddress: wallet.publicKey,
		// 	tokenAddress: nft.token_address,
		// 	tokenId: nft.token_id,
		// 	marketId: applicationConfiguration.marketId,
		// });

		// let result = await immutableProvider.getOrders({
		// 	status: 'active',
		// 	user: applicationConfiguration.adminAddress, //TODO
		// });
		// let count = 0;
		// while (
		// 	!result.some(
		// 		(value: { order_id: any }) => value.order_id == order_id.data
		// 	) &&
		// 	count < 100
		// ) {
		// 	result = result = await immutableProvider.getOrders({
		// 		status: 'active',
		// 		user: applicationConfiguration.adminAddress, //TODO  
		// 	});
		// 	count++;
		// 	await new Promise((resolve) => setTimeout(resolve, 1000)); // 3 sec
		// }
		// if (count >= 100) {
		// 	TokiesService.cancelBuyNft(order_id.toString());
		// } else {
		// 	await new Promise((resolve) => setTimeout(resolve, 10000)); // 3 sec

		// 	try {
		// 		immutableProvider.buyOrder({
		// 			orderIds: [order_id.data],
		// 		});
		// 	} catch {
		// 		throw order_id;
		// 	}

		// 	const transaction:{} = {
		// 		nftName: nft.name,
		// 		tokenid: nft.token_id,
		// 		priceETH: String(nft.price),
		// 		priceCAD: String(nft.priceCan),
		// 		checked: false,
		// 		userId: storageService.get_user_id(),
		// 		marketId: parseInt(applicationConfiguration.marketId),
		// 		walletId: wallet.id,
		// 		NFTId: -1,
		// 		type: 'wallet',
		// 		cardNumber: null,
		// 		expiracy: null,
		// 		holder: null,
		// 	};

		// 	await TokiesService.createTransaction(transaction);
		// }
	// }

	async makePayment(
		paymentMethod: PaymentMethod,
		nft: NFT,
		wallet: Wallet,
	) {
		if (paymentMethod === PaymentMethod.Crypto) {	 
			const order_details = await TokiesService.buyNft({
				buyerAddress: wallet.publicKey,
				tokenAddress: nft.token_address,
				tokenId: nft.token_id,
				marketId: applicationConfiguration.marketId,
			});

			let result = await immutableProvider.getOrders({
				status: 'active',
				user: applicationConfiguration.adminAddress,
			});
			let count = 0;
			while (
				!result.some(
					(value: { order_id: any }) => value.order_id == order_details.data["orderId"]
				) &&
				count < 100
			) {
				result = result = await immutableProvider.getOrders({
					status: 'active',
					user: applicationConfiguration.adminAddress,
				});
				count++;
				await new Promise((resolve) => setTimeout(resolve, 1000)); // 3 sec
			}
			if (count >= 100) {
				TokiesService.cancelBuyNft(order_details.data["orderId"], {
					buyerAddress: wallet.publicKey,
					transactionUUID: order_details.data['transactionUUID'],
				});
			} else {
				await new Promise((resolve) => setTimeout(resolve, 10000)); // 3 sec

				try {
					await immutableProvider.buyOrder({
						orderIds: [order_details.data["orderId"]],
					});
				} catch (e) {
					throw order_details;
				}
				await TokiesService
					.createTransaction({
						nftName: nft.name,
						tokenid: nft.token_id,
						priceETH: String(nft.price),
						priceCAD: String(nft.priceCan),
						checked: false,
						userId: storageService.get_user_id(),
						marketId: applicationConfiguration.marketId,
						walletId: wallet.id,
						NFTId: -1,
						type: 'wallet',
						cardNumber: null,
						expiracy: null,
						holder: null,
					})
			}
		}
		if (paymentMethod === PaymentMethod.Paypal) {
			//TODO
		}
	}
}

export default PaymentService;

import axios from "axios";
import { storageService } from "src/ApplicationContext";
import { getBackEndUrl } from "src/env/SaasEnvironnement";

class NFTService {

    backendURL: string = getBackEndUrl();

    async getBalance(wallet: any) {
		return (
			await axios.post(
				`${this.backendURL}/imx/balance`,
				{ walletAddress: wallet, id: storageService.get_user_id() },
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}

    public async getAssets(userWallet: string, collection: string, page_size = 0) {
		return (
			await axios.post(
				`${this.backendURL}/imx/assets`,
				{
					userWallet: userWallet,
					collection: collection,
					page_size: page_size,
				},
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}


   public  async getOrders(params:any) {
		return (
			await axios.post(`${this.backendURL}/imx/orders`, params, {
				headers: {
					Authorization: `Bearer ${storageService.get_access_token()}`,
				},
			})
		).data;
	}

    public 	async weiToEth(valueInWei:number) {
		return (
			await axios.post(
				`${this.backendURL}/imx/balance`,
				{ price: valueInWei },
				{
					headers: {
						Authorization: `Bearer ${storageService.get_access_token()}`,
					},
				}
			)
		).data;
	}
}

export default NFTService;
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import { Button } from '@mui/material';
import AuthenticationService from 'src/service/AuthenticationService';
import GoogleIcon from '@mui/icons-material/Google';
import i18n from 'src/i18n';

interface Props {
	callback: () => void;
	onLoginError: () => void;
	buttonWidth?: number;
}

const GoogleLoginButton = ({ callback, onLoginError, buttonWidth }: Props) => {
	const authenticationService = new AuthenticationService();

	const loginWithGoogle = useGoogleLogin({
		onSuccess: (codeResponse) => {
			getUsers(codeResponse);
		},
		onError: (_) => {
			onLoginError();
		},
	});

	const getUsers = async (token: TokenResponse) => {
		try {
			const request = await authenticationService.connect_with_google(token);

			if (request) {
				callback();
			} else {
				onLoginError();
			}
		} catch {
			onLoginError();
		}
	};

	return (
		<div
			style={{ justifyContent: 'center', display: 'flex', marginTop: 20 }}
		>
			<Button
				style={{ backgroundColor: 'var(--primary-color)', width: buttonWidth }}
				onClick={() => loginWithGoogle()}
				variant="contained"
			>
				{i18n.t<string>('login.google')}
				<GoogleIcon style={{ marginLeft: 10 }} />
			</Button>
		</div>
	);
};

export default GoogleLoginButton;

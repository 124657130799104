import {
  Document,
  View,
  Page,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import DnaLoader from "src/presentation/components/loader/DNALoader";
import i18n from "src/i18n";
import { ReceiptsAdminUIController } from "src/business/controller/ui/ReceiptsAdminUIController";

const ReceiptsAdmin = ()=> {
  const { market, periods, loading, sortedTransactions } = ReceiptsAdminUIController();

  const MyDocument = (idx: number, month: number, debut = 15, fin = 14) => {
    let totalCAD=0
    let totalETH=0
    let totalTip=0
    return (
      <Document>
        <Page size="A4" style={{ backgroundColor: "#E4E4E4" }}>
          <View style={{ margin: 10, padding: 10, flexDirection: "row" }}>
            <View style={{ margin: 10, padding: 10, flexDirection: "column" }}>
              <Text style={{ fontSize: 30 }}>
                {i18n.t<string>("recuSommaire.header_1")}
              </Text>
              <Text style={{ fontSize: 15 }}>
                {i18n.t<string>("recuSommaire.header_2")}
              </Text>
              <Text style={{ fontSize: 15 }}>
                {i18n.t<string>("recuSommaire.header_3")}
              </Text>
              <Text style={{ fontSize: 15 }}>
                {i18n.t<string>("recuSommaire.header_4")}
              </Text>
              <Text style={{ fontSize: 15 }}>
                {i18n.t<string>("recuSommaire.header_5")}
              </Text>
            </View>
            <View style={{ margin: 10, flexDirection: "column", width: 400 }}>
              <Text style={{ fontSize: 12, padding: 10 }}>
                {i18n.t<string>("recuSommaire.description")}
              </Text>
            </View>
          </View>
          <View style={{ margin: 10, padding: 10, flexDirection: "column" }}>
            <Text style={{ fontSize: 40 }}>
              {i18n.t<string>("recuSommaire.titre")}
            </Text>
            <Text style={{ fontSize: 15 }}>
              {" "}
              {i18n.t<string>("recuSommaire.fromDate")} {debut}{" "}
              {i18n.t<string>("month.".concat(month.toString()))}{" "}
              {i18n.t<string>("recuSommaire.toDate")} {fin}{" "}
              {i18n.t<string>("month.".concat(((month + 1) % 12).toString()))}
            </Text>
            <View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>

            <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={{ fontSize: 20 }}>
                  {i18n.t<string>("recuSommaire.toClient")}{" "}
                </Text>
                <Text style={{ fontSize: 15 }}>
                  {market.clientName}
                </Text>
                <Text style={{ fontSize: 15 }}>{market.street}</Text>
                <Text style={{ fontSize: 15 }}>
                  {market.city} ({market.shortProvince})
                </Text>
                <Text style={{ fontSize: 15 }}>
                  {market.postalCode} {market.country}
                </Text>
              </View>
              <View style={{ marginHorizontal: 20, flexDirection: "column" }}>
                <Text style={{ fontSize: 20 }}>
                  {i18n.t<string>("recuSommaire.wallet")}{" "}
                </Text>
                <Text style={{ fontSize: 15 }}>
                  {market.walletId}
                </Text>
                <Text style={{ fontSize: 20 }}>
                  {i18n.t<string>("recuSommaire.site")}{" "}
                </Text>
                <Text style={{ fontSize: 15 }}>
                  {market.siteCommerce}
                </Text>
              </View>
            </View>

						<View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
						<View style={{ flexDirection: 'row' }}>
							<View
								style={{ flexDirection: 'column', flexGrow: 3, width: 150 }}
							>
								<Text>{i18n.t<string>('recuSommaire.nft')}</Text>
							</View>
							<View
								style={{ flexDirection: 'column', flexGrow: 3, width: 200 }}
							>
								<Text>{i18n.t<string>('recuSommaire.date')}</Text>
							</View>
							<View
								style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
							>
								<Text>{i18n.t<string>('recuSommaire.eth')}</Text>
							</View>
							<View
								style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
							>
								<Text>{i18n.t<string>('recuSommaire.cad')}</Text>
							</View>
						</View>
						{periods[idx].map((transaction: any, key: any) => {
              
              totalCAD += transaction.priceCAD??0
              totalETH += transaction.priceETH??0
              totalTip += transaction.tip ?? 0
							return (
								<View style={{ flexDirection: 'row' }} key={key}>
									<View
										style={{ flexDirection: 'column', flexGrow: 3, width: 150 }}
									>
										<Text
											style={{
												fontSize: 15,
												padding: 5,
												backgroundColor: '#E4E4E8',
											}}
										>
											{transaction.nftName}
										</Text>
									</View>
									<View
										style={{ flexDirection: 'column', flexGrow: 3, width: 200 }}
									>
										<Text
											style={{
												fontSize: 15,
												padding: 5,
												backgroundColor: '#E4E4E8',
											}}
										>
											{transaction.createdAt}
										</Text>
									</View>
									<View
										style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
									>
										<Text
											style={{
												fontSize: 15,
												padding: 5,
												backgroundColor: '#E4E4E8',
											}}
										>
											{(transaction.priceETH ?? 0).toFixed(6)}
										</Text>
									</View>
									<View
										style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
									>
										<Text
											style={{
												fontSize: 15,
												padding: 5,
												backgroundColor: '#E4E4E8',
											}}
										>
											${(transaction.priceCAD ?? 0).toFixed(2)}
										</Text>
									</View>
								</View>
							);
						})}
						<View style={{ flexDirection: 'row' }}>
							<View
								style={{ flexDirection: 'column', flexGrow: 3, width: 150 }}
							></View>
							<View
								style={{ flexDirection: 'column', flexGrow: 3, width: 200 }}
							>
								<Text style={{ fontSize: 15, padding: 5 }}>
									{i18n.t<string>('recuSommaire.subtotal')}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									{i18n.t<string>('recuSommaire.tps')}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									{i18n.t<string>('recuSommaire.tvq')}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									{i18n.t<string>('recuSommaire.tip')}
								</Text>
								<Text style={{ fontSize: 25, padding: 5 }}>
									{i18n.t<string>('recuSommaire.total')}
								</Text>
							</View>
							<View
								style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
							>
								<Text style={{ fontSize: 15, padding: 5 }}>
									{totalETH.toFixed(6)}
								</Text>
							</View>
							<View
								style={{ flexDirection: 'column', flexGrow: 1, width: 100 }}
							>
								<Text style={{ fontSize: 15, padding: 5 }}>
									${totalCAD.toFixed(2)}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									${(totalCAD * Number(process.env.REACT_APP_TPS)).toFixed(2)}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									${(totalCAD * Number(process.env.REACT_APP_TVQ)).toFixed(2)}
								</Text>
								<Text style={{ fontSize: 15, padding: 5 }}>
									${totalTip.toFixed(2)}
								</Text>
								<Text style={{ fontSize: 25, padding: 5 }}>
									$
									{(
										totalCAD * (1+Number(process.env.REACT_APP_TVQ)+Number(process.env.REACT_APP_TPS)) +
										totalTip
									).toFixed(2)}
								</Text>
							</View>
						</View>

            <View style={{ margin: 10, padding: 10, flexGrow: 1 }}></View>
          </View>
        </Page>
      </Document>
    );
  };
  

  if (loading) {
		return (
				<div className="d-flex justify-content-center align-items-center mt-4">
					<DnaLoader />
				</div>
		);
  }
  return (
    <section className="activity-area load-more pt-0">
      <div className="container">
        <div className="row">
          <div className="col">
            <h5>{i18n.t<string>("recuSommaire.date")}</h5>
          </div>
          <div className="col my-auto intro-content">
            <h5>{i18n.t<string>("recuSommaire.recu")}</h5>
          </div>
        </div>
        <div className="single-tab-list">
          {" "}
          {/*var(--primary-color)*/}
          {/* <PDFViewer className='w-100' height={"1000px"}>
                          {MyDocument()}
                      </PDFViewer> */}
          {sortedTransactions[0] &&
            periods.map((period:any, idx:any) => {
              if (period[0]) {
                let periodMonth = new Date(period[0].createdAt).getMonth();
                const periodDate = new Date(period[0].createdAt).getDate();
                const periodYear = new Date(
                  period[0].createdAt
                ).getFullYear();
                let fileNameDownload = "";
                if (periodDate < 15) {
                  periodMonth--;
                  periodMonth = periodMonth % 12;
                }
                fileNameDownload = i18n
                  .t("recuSommaire.sommaire")
                  .concat(
                    "_",
                    periodYear.toString(),
                    "_",
                    i18n.t<string>("recuSommaire.fromDate"),
                    "_15_",
                    i18n.t<string>("month.".concat(periodMonth.toString())),
                    "_",
                    i18n.t<string>("recuSommaire.toDate"),
                    "_14_",
                    i18n.t<string>("month.".concat(((periodMonth + 1) % 12).toString())),
                    ".pdf"
                  );
                return (
                  <div className="row" key={idx}>
                    <div className="col">
                      <h5>{fileNameDownload}</h5>
                    </div>
                    <div className="col my-auto intro-content">
                      <PDFDownloadLink
                        document={MyDocument(idx, periodMonth)}
                        fileName={fileNameDownload}
                      >
                        <div className="btn btn-bordered-white pa-2 ma-2">
                          {i18n.t<string>("recuSommaire.download")}
                        </div>
                      </PDFDownloadLink>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </section>
  );
  
}

export default ReceiptsAdmin;

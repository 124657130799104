import Header from 'src/presentation/components/Header/Header';
import Deposit from 'src/presentation/components/Deposits/Deposit';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

const DepositsScreen:FC<RouteComponentProps> = (): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<Deposit />
				<Footer />
				<ModalMenu />
				<Scrollup />
			</div>
		);
}

export default DepositsScreen;

import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import PaymentItem from 'src/presentation/components/PaymentItem';
import { NFT } from 'src/business/entities/NFT';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

export interface PaymentProps extends RouteComponentProps{
	location: {
		pathname: string
		state: {
			nft: NFT
		}
		search: string,
		hash: string
	}
}

const PaymentScreen:FC<PaymentProps> = (props: PaymentProps): JSX.Element => {
	const nft = props.location?.state?.nft;

	return (
		<div className="main">
			<Header />
			<PaymentItem nft={nft} {...props} />
			<Scrollup />
			<ModalMenu />
		</div>
	);
};

export default PaymentScreen;

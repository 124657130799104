import Header from 'src/presentation/components/Header/Header';
import Author from 'src/presentation/components/Author/Author';
import Footer from 'src/presentation/components/Footer/Footer';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';


const AuthorScreen:FC<RouteComponentProps> = (props): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<Author {...props}/>
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default withRouter(AuthorScreen);

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import i18n from "src/i18n";

interface Props {
  show: boolean;
  handleClose: () => void;
  confirmClose: () => void;
}

const CancelCreationConfirmation = ({
  show,
  handleClose,
  confirmClose
}: Props) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> {i18n.t<string>("zone_admin.mintage.confirmation")} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {i18n.t<string>("zone_admin.mintage.confirmText")}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-around" }}>
        <Button variant="secondary" onClick={handleClose}>
        {i18n.t<string>("zone_admin.mintage.cancel")}
        </Button>
        <Button variant="primary" onClick={confirmClose}>
        {i18n.t<string>("zone_admin.mintage.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelCreationConfirmation;

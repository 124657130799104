import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Footer from 'src/presentation/components/Footer/Footer';
import Wallet from 'src/presentation/components/Wallet/Wallet';
import Header from 'src/presentation/components/Header/Header';

const WalletConnectScreen:FC<RouteComponentProps> = (): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<Wallet />
				<Footer />
				<ModalMenu />
				<Scrollup />
			</div>
		);
}

export default WalletConnectScreen;

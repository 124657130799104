import i18n from 'src/i18n';
import { Button } from '@mui/material';
import { PaymentInformationProps, PaymentInformationUIController } from 'src/business/controller/ui/PaymentInformationUIController';


const style = {
	payment: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	paymentName: {
		marginLeft: 10,
		color: "var(--primary-p-color)",
	},
	selectedPayment: { backgroundColor: 'black' },
	unselectedPayment: { 'border-color': 'black' },
};

const PaymentInformation = (props: PaymentInformationProps): JSX.Element => {
	const {
		nft, 
		// isCryptoSelected,  
		onProceedToPayment, 
		// isPaypalSelected, 
		isCreditCardSelected,
		// onSelectPaypal, 
		onSelectCreditCard, 
		// onSelectCrypto 
	} = PaymentInformationUIController(props);
	return (
		<div
			className="card m-4"
			style={{
				width: '500px',
				minHeight: '500px',
				height: '100%',
				backgroundImage:
					'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
			}}
		>
			<h3 style={{ color: "var(--primary-p-color)" }}>
				{' '}
				{i18n.t<string>('payment.paymentInformation')}{' '}
			</h3>
			<div className="col">
				<div>	
					{/* <div style={style.payment as React.CSSProperties}>
						<a
							id="paypal-button"
							style={isPaypalSelected() ? style.selectedPayment : undefined}
							className="button-payment"
							onClick={onSelectPaypal}
						/>
						<a style={style.paymentName}> {i18n.t<string>('payment.paypal')} </a>
						<img
							src={'./img/paypal.png'}
							style={{ height: 85, width: 100, marginLeft: 15 }}
							alt=""
						/>
					</div> */}
					{+nft.priceCan > 0.5 ? (
						<div style={style.payment as React.CSSProperties}>
							<a
								id="credit-card-button"
								style={
									isCreditCardSelected() ? style.selectedPayment : undefined
								}
								className="button-payment"
								onClick={onSelectCreditCard}
							/>
							<a style={style.paymentName}>
								{' '}
								{i18n.t<string>('payment.creditCard')}{' '}
							</a>
							<img
								src={'./img/credit_card_new.png'}
								style={{ height: 85, width: 85, marginLeft: 5 }}
								alt=""
							/>
						</div>
					) : (
						<></>
					)}
					{/* <div style={style.payment as React.CSSProperties}>
						<a
							id="crypto-button"
							style={isCryptoSelected() ? style.selectedPayment : undefined}
							className="button-payment"
							onClick={onSelectCrypto}
						/>
						<a style={style.paymentName}> {i18n.t<string>('payment.crypto')} </a>
						<img
							src={'./img/crypto.png'}
							style={{ height: 80, width: 100, marginLeft: 15 }}
							alt=""
						/>
					</div> */}
				</div>
				<div
					className={`d-flex flex-row mt-4 `}
					style={{
						borderRadius: '25px',
						height: '100px',
						background: 'var(--primary-bg-color)',
					}}
				>
					<h3 className="px-4 m-auto">{nft.priceCan}</h3>
					<h4
						className="px-4 my-0 d-flex align-items-center justify-content-center"
						style={{
							borderRadius: '25px',
							width: '150px',
							height: '100px',
							background: 'var(--secondary-bg-color)',
						}}
					>
						$CAD
					</h4>
				</div>
				{/* {isCryptoSelected() && (
					<>
						<div
							className="d-flex align-items-center my-2 pl-4"
							style={{ fontSize: '1.2rem' }}
						>
							* {' ' + nft.price + ' '}
							ETH
							<img
								src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png"
								alt=""
								style={{
									height: '25px',
									width: '25px',
									objectFit: 'scale-down',
								}}
							/>
						</div>
						<div
							className="d-flex align-items-center my-2 pl-4"
							style={{
								fontSize: '0.8rem',
								textDecoration: 'underline',
							}}
						>
							{i18n
								.t('nft_marketplace_details.priceDisclaimerText')
								.toString()}
						</div>
					</>
				)} */}

				<div
					style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
				>
					<Button
						onClick={onProceedToPayment}
						style={{ backgroundColor: '#0B64AD' }}
						variant="contained"
					>
						{i18n.t<string>('payment.proceedPayment')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PaymentInformation;

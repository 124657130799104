import { useContext, useEffect, useState } from "react"
import { storageService, userService } from "../ApplicationContext";
import { AdminContext } from "./AdminContext";

export const useAdminContext = () => {
    const user_token = storageService.get_access_token();
    const context = useContext(AdminContext);
     const [isAdmin, setIsAdmin] = useState(context?.isAdmin);
    const [error, _setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const getIsAdmin = async () => {
            if(storageService.get_user_email()){
                const user = await userService.getUserByEmail(storageService.get_user_email())
                const isUserAdmin = user?.role === "Admin";
                setIsAdmin(isUserAdmin);
            }
        };

        getIsAdmin();
    }, [user_token])

    return { isAdmin, error }
}

import { useContext, useEffect, useState } from 'react';
import { storageService, userService } from '../ApplicationContext';
import { MarketContext } from './MarketContext';

export const useMarketContext = () => {
	const user_token = storageService.get_access_token();
	const context = useContext(MarketContext);
	const [marketId, setMarketId] = useState(context?.marketId);
	const [error, _setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		const getMarketId = async () => {
			
            if(storageService.get_user_email()){
				const user = await userService.getUserByEmail(
					storageService.get_user_email()
				);
				setMarketId(user.marketId.toString());
			}
		};

		getMarketId();
	}, [user_token]);

	return { marketId, error };
};

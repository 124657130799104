import DnaLoader from 'src/presentation/components/loader/DNALoader';
import ChooseWallet from 'src/presentation/components/ChooseWallet';
import i18n from 'src/i18n';
import { SelectPaymentProps, SelectPaymentWalletUIController } from 'src/business/controller/ui/SelectPaymentWalletUIController';


const SelectPaymentWallet = (props: SelectPaymentProps): JSX.Element => {
	const {loading, wallets, connectedWallet, onChooseWallet} = SelectPaymentWalletUIController(props);
	if (loading) {
		return (
			<section className="author-area explore-area popular-collections-area">
				<div className="container">
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}

	return (
		<section className="author-area explore-area popular-collections-area">
			<div className="container">
				<div
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div>
						<h1> {i18n.t<string>('payment.ChooseYourWallet')} </h1>
						<div
							style={{ backgroundColor: 'var(--primary-color)', height: 3, color: "var(--primary-p-color)" }}
						></div>
					</div>
						<ChooseWallet
							connectedWallet={connectedWallet}
							wallets={wallets}
							onChooseWallet={onChooseWallet}
						/>
				</div>
			</div>
		</section>
	);
};

export default SelectPaymentWallet;

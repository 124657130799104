import { useEffect, useState } from 'react';
import {
	// adressService,                           // retirer address
	storageService,
	walletService,
} from 'src/ApplicationContext';
// import { Address } from 'src/business/entities/Address';                            // retirer address
import { NFT } from 'src/business/entities/NFT';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import Wallet from 'src/business/entities/Wallet';
import WalletType from 'src/business/entities/WalletType';
import { CryptoSelectionProps } from 'src/presentation/components/CryptoSelection';

function CryptoSelectionController(props: CryptoSelectionProps) {
	const userId = storageService.get_user_id();

	const [wallets, setWallets] = useState<Wallet[]>();
	const [connectedWallet, setConnectedWallet] = useState<Wallet>();
	const [loading, setLoading] = useState<boolean>(true);
	// const [userAdress, setUserAdress] = useState<Address[]>();                           // retirer address

	const nft: NFT = props.location?.state?.nft;
	const paymentMethod: PaymentMethod = props.location?.state?.paymentMethod;

	const getUserWallets = async () => {
		const nonCustodialWallets = (
			await walletService.getWalletByUser(parseInt(userId ?? ''))
		).filter((wallet) => wallet.type !== WalletType.Custodial);

		for (const wallet of nonCustodialWallets) {
			const solde = await walletService.getSolde(wallet.publicKey);
			wallet.solde = solde;
		}

		setWallets(nonCustodialWallets);

		const connectedWallet = nonCustodialWallets.find(
			(wallet) => wallet.connected
		);

		if (connectedWallet) {
			setConnectedWallet(connectedWallet);
		}

		setLoading(false);
	};

	// const getUserAdress = async (): Promise<void> => {                            // retirer address
	// 	const userAdress: Address[] = await adressService.getUserAdress();
	// 	setUserAdress(userAdress);
	// };

	useEffect(() => {
		getUserWallets();
		// getUserAdress();
	}, []);

	const onChooseWallet = (wallet: Wallet) => {
		storageService.set_wallet_address(wallet.publicKey);

		// const address = userAdress[0];                           // retirer address
		// if (address) {
			props.history.push('/paymentConfirmation', {
				// address,
				wallet,
				nft,
				paymentMethod,
			});
		// } else {
		// 	props.history.push('/address', {                           // retirer address
		// 		wallet,
		// 		nft,
		// 		paymentMethod,
		// 	});
		// }
	};

	const connectWallet = () => {
		props.history.push('/paymentWalletConnect', {
			paymentMethod,
			nft,
		});
	};

	const hasWallet = (): boolean => wallets.length > 0;

	return {
		hasWallet,
		connectWallet,
		onChooseWallet,
		loading,
		wallets,
		connectedWallet,
	};
}

export default CryptoSelectionController;

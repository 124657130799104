import NFTAsset from 'src/business/entities/NFTAsset';
import { ExploreLogicController } from 'src/business/controller/logic/ExploreLogicController';
import { useTranslation } from 'react-i18next';
import CardGeneral from '../Cards/CardGeneral';

interface Props {
	item: NFTAsset ;
	idx: any
}

const NFTCard = ({ item,idx }: Props) => {
	const {getProfit} = new ExploreLogicController();
	const { t } = useTranslation();
	
	return (
		<CardGeneral 
			index={idx}
			titre={item.name? item.name: null} 
			imageUrl={{url:item.image_url? item.image_url: 'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'}} 

			description={
				<>
					<div className="d-flex justify-content-between my-3">
						<span>
							{t<string>('zone_admin.tableaux.gallerie')}<a><b>{item.group?item.group:'Self'} </b></a>
						</span>
					</div>
					<div className="d-flex justify-content-between my-3">
						<span>
							{t<string>('zone_admin.tableaux.price')} <a><b>{item.metadata?.price?item.metadata.price:'free'}&nbsp;$</b></a>
						</span>
						
						<span>
							{t<string>('zone_admin.tableaux.profit')}  <a><b>{item.metadata?.price?getProfit(item):'-'}&nbsp;$</b></a> 
						</span>
					</div>
				</>
			}	
		/>
	);
};

export default NFTCard;

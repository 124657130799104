import { Dna } from 'react-loader-spinner';

const DnaLoader = (): JSX.Element => {
	return (
		<Dna
			visible={true}
			height="80"
			width="80"
			ariaLabel="dna-loading"
			wrapperStyle={{}}
			wrapperClass="dna-wrapper"
		/>
	);
};

export default DnaLoader;

import { SoldeETHUIController } from 'src/business/controller/ui/SoldeETHUIController';
import DnaLoader from '../loader/DNALoader';

const SoldeETH = ()=> {
	const { loading, balanceETH, balanceCAD } = SoldeETHUIController();

	if (loading) {
		return (
			<div className="container">
				<div className='d-flex justify-content-center'> 
				<DnaLoader />
				</div>
			</div>
		);
	}
	return (
		<div>
			<div className="container">
				<div className="row d-flex" style={{ color: 'var(--primary-t-color)' }}>
					<div>
						<img
							className="img-responsive mr-2"
							style={{ height: '20px', maxWidth: '20px' }}
							src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png"
							alt=""
						/>
						{balanceETH.toFixed(7)} ETH
					</div>
				</div>
				<div className="row text-secondary ">
					<small className="mx-2" style={{ fontSize: '13px' }}>
						{'( ' + balanceCAD.toFixed(2) + ' CAD )'}
					</small>
				</div>
			</div>
		</div>
	);
	
}

export default SoldeETH;

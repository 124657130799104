import { useTranslation } from "react-i18next";

const About = (): JSX.Element => {
	const { t } = useTranslation();
		return (
			<section className="about-section pb-0">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 col-lg-10">
							<div
								className="intro text-left mb-4"
							>
								<span>{t<string>('about.pre_heading')}</span>
								<h3 className="mt-3 mb-0">
									{t<string>('about.heading')}
								</h3>
								<p>
									{t<string>('about.content_1')}
								</p>

								<p>{t<string>('about.content_2')}</p>

								<p>{t<string>('about.content_3')}</p>

								{t<string>('about.content_4') !== 'about.content_4' && <p>{t<string>('about.content_4')}</p> }
								{t<string>('about.content_5') !== 'about.content_5' && <p>{t<string>('about.content_5')}</p> }
							</div>
						</div>
					</div>
				</div>
			</section>
		);
}

export default About;

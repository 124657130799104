
import { Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import TokiesService from 'src/service/TokiesService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { storageService } from 'src/ApplicationContext';
import { RouteComponentProps } from 'react-router';
import { DemandOwnerType } from 'src/business/entities/DemandOwnerType';

export interface DemandeModalProps extends RouteComponentProps {
	show: boolean;
    onShowDemandModal: (value: boolean) => void;
    galleryId: number;
}


const DemandeModal = (props:DemandeModalProps)=> {
	const [ratioArtist,setRatioArtist] = useState(50);
	const { t } = useTranslation();
  
	const handleChange = (_event: Event, newValue: number|number[] ) => {
		setRatioArtist(newValue as number);
	  };

	const valuetext = (value: number) => {
		return `${value} `;
	  }

    const handleClick = async () =>{
		await TokiesService.createDemande({
			artistId: +storageService.get_artist_id(),
			galleryId: props.galleryId,
			ownerId: +storageService.get_artist_id(),
			ownerType: DemandOwnerType.Artist,
			description: '',
			subject: '',
			artistShare: ratioArtist,
			galleryShare: 100-ratioArtist,

		})
		toast.promise(async () => {}, {
			success: {
				render: t<string>('zone_admin.gallerie.demands.succeed'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
		props.onShowDemandModal(false);
	}
    const handleClose = async () =>{
		props.onShowDemandModal(false);
	}
	return (
		<Modal
			show={props.show}
			contentClassName="rounded"
			onHide={handleClose}
			centered
			scrollable={true}
		>
			<Modal.Header>
                <Modal.Title >
					{t<string>('zone_admin.gallerie.demands.title')}
				</Modal.Title>
				<Typography >
					<div>
						<span>{t<string>('zone_admin.gallerie.demands.artistShare')} {ratioArtist}</span> 
					</div>
					<div>
						<span>{t<string>('zone_admin.gallerie.demands.galleryShare')} {100-ratioArtist}</span> 
					</div>
				</Typography>
			</Modal.Header>
			<Modal.Body >
				
    			<Box >
    			  	<Slider
    			  	  getAriaLabel={() => 'Share split'}
    			  	  value={ratioArtist}
						min={0}
						max={100}
    			  	  onChange={handleChange}
    			  	  valueLabelDisplay="auto"
    			  	  getAriaValueText={valuetext}
    			  	/>
				  	<div>
						<button style={{ color: "var(--primary-p-color)" }} className="btn m-2" onClick={handleClose}>
							<span>{t<string>('zone_admin.gallerie.demands.cancel')}</span>
						</button>
						<button style={{ color: "var(--primary-p-color)" }} className="btn " onClick={handleClick}>
							<span>{t<string>('zone_admin.gallerie.demands.sendDemand')}</span>
						</button>
					</div>
    			</Box>
            </Modal.Body>
		</Modal>
	);
}

export default DemandeModal;

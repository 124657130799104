import i18n from 'src/i18n';
import AuthorNFT from 'src/presentation/components/AuthorWallet/AuthorNFT';
import AuthorProfile from 'src/presentation/components/AuthorProfile/AuthorProfile';
import AuthorReceipt from 'src/presentation/components/AuthorReceipt/AuthorReceipt';
import AuthorWallet from 'src/presentation/components/AuthorWallet/AuthorWallet';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
interface Props {
	history: {
		push: Function;
	};
}

const Author = (props:Props): JSX.Element => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index: number) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	}

	return (
		<section className="activity-area">
			<div className="container">
				<div className="row justify-content-between">
					<div className="my-2 col-12 col-sm-6 col-lg-4 col-md-5">
						<AuthorProfile />
					</div>
					<div className="my-2 col-12 col-sm-6 col-lg-8 col-md-7 item-info">
						<Box sx={{ bgcolor: 'transparent' }}>
							<AppBar position="static">
								<Tabs
									// indicatorColor="undefined"
									style={{ background: 'var(--primary-color)'}}
									value={value}
									onChange={handleChange}
									textColor="inherit"
									variant="fullWidth"
								>
									<Tab
										label={i18n.t<string>('profil.tab_1.titre')}
										{...a11yProps(0)}
									/>
									<Tab
										label={i18n.t<string>('profil.tab_2.titre')}
										{...a11yProps(1)}
									/>
									<Tab
										label={i18n.t<string>('profil.tab_3.titre')}
										{...a11yProps(2)}
									/>
								</Tabs>
							</AppBar>
							<SwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={value}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={value} index={0}>
									<AuthorNFT {...props}/>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<AuthorWallet />
								</TabPanel>
								<TabPanel value={value} index={2}>
									<AuthorReceipt />
								</TabPanel>
							</SwipeableViews>
						</Box>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Author;
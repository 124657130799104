import { useState } from "react";
import i18n from "src/i18n";
import { UserLogicController } from "../logic/UserLogicController";
import { RegisterUserRequest } from "src/service/dto/request/RegisterUserRequest";
import { authenticationService } from "src/ApplicationContext";
import { applicationConfiguration } from "src/env/ApplicationConfiguration";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

export interface RegisterModalProps {
	onShowLogin: (value: boolean) => void;
	onShowRegister: (value: boolean) => void;
	show: boolean;
}


export function RegisterModalUIController(props:RegisterModalProps) {
	const [errorCode, setErrorCode] = useState<string>('');
	const [resendCode, setResendCode] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [hasCreatedNonConfirmedAccount, setHasCreatedNonConfirmedAccount] = useState(false);
	const [emailvalidationError, setEmailvalidationError] = useState<string>('');
	const [passwordValidationError, setPasswordValidationError] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [tryingToRegister, setTryingToRegister] = useState(false);
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
    const userController: UserLogicController = new UserLogicController();
	const { t } = useTranslation();


    const passwordValidation = () => {
        const errorMessage =  userController.getPasswordConfirmationErrorMessage(password, confirmPassword)
        setPasswordValidationError(errorMessage)

		return errorMessage === '' ? true : false;
	}

	const emailValidation = () => {
		if (!userController.emailIsValid(email)) {
			setEmailvalidationError(i18n.t<string>('password.messageEmailNotValid'))
			return false;
		} else {
			setEmailvalidationError('')
			return true;
		}
	}

    const registerSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
	{
		e.preventDefault();

		if (emailValidation() && passwordValidation()) {
			const register_user_dto: RegisterUserRequest = {
				role: 'Client',
				firstName: firstName,
				lastName: lastName,
				password: password,
				email: email,
				marketId: parseInt(applicationConfiguration.marketId),
				google_auth: false,
				phoneNumber: phoneNumber,
			};

			const request = await authenticationService.register_user(
				register_user_dto
			);

			if (request) {
				setHasCreatedNonConfirmedAccount(true)
			} else {
				setEmailvalidationError(i18n.t<string>('register.emailExist'))
			}
		}
	};

	const handleClose = () => {
		props.onShowRegister(false);
	}

	const handleLoginButton = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		props.onShowRegister(false);
		props.onShowLogin(true);
	}

	const callback_google_login = () => {
		toast.promise(async () => {}, {
			success: {
				render: i18n.t<string>('login.userLogin'),
				position: toast.POSITION.BOTTOM_LEFT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
		handleClose();
	};

	const onGoogleError = () => {
		toast.promise(
			async () => {
				throw Error();
			},
			{
				error: {
					render: i18n.t<string>('login.errorLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			}
		);

		handleClose();
	};

	const confirm_code = async () => {
		const request = await authenticationService.confirm_user(
			email,
			code
		);

		if (request) {

			const login_request = await authenticationService.login_with_email(email, password);

			if (login_request) {
                handleClose();
                toast.promise(async () => {}, {
                    success: {
                        render: i18n.t<string>('login.userLogin'),
                        position: toast.POSITION.BOTTOM_LEFT,
                        pauseOnHover: false,
                        theme: 'dark',
                    }
                });
			} else {
				toast.promise(async () => {}, {
					success: {
						render: i18n.t<string>('login.errorLogin'),
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					}
				});
			}
		} else {
			setErrorCode(i18n.t<string>('register.errorCode'))
			setTimeout(() => setErrorCode(undefined), 5000);
		}
	};

	const resend_code_confirmation = async () => {
		const request = await authenticationService.resend_confirmation(
			email
		);

		if (request) {
			setResendCode(i18n.t<string>('register.resendSuccess'))
			setTimeout(() => setResendCode(undefined), 5000);
		}
	};

	return {handleClose, hasCreatedNonConfirmedAccount, setHasCreatedNonConfirmedAccount, setTryingToRegister, handleLoginButton, setCode, code, confirm_code,
			email, password, resend_code_confirmation, resendCode, errorCode, tryingToRegister, callback_google_login, onGoogleError, firstName, setFirstName,
			lastName, setLastName, setEmail, setPassword, passwordValidationError, confirmPassword, setConfirmPassword, phoneNumber, setPhoneNumber,
			registerSubmit, emailvalidationError, t}
}
import { withRouter } from 'react-router';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import RegisterModal from 'src/presentation/components/Modals/RegisterModal/RegisterModal';
import Viewer from 'src/presentation/components/Viewer/Viewer';
import LoginModal from 'src/presentation/components/Modals/LoginModal/LoginModal';
import { NFTProps } from 'src/business/controller/logic/NFTLogicController';
import { NftMarketplaceDetailsUIController } from 'src/business/controller/ui/NFTMarketplaceDetailsUIController';
import MessageModal from '../Modals/LoginModal/MessageModal';


const NftMarketplaceDetails = (props: NFTProps) => {
	const {
		asset,
		loading,
		proceedToPayment,
		isFree,
		priceToCAD,
		// priceToETH,
		setShowLogin,
		setShowRegister,
		showRegister,
		showMessage,
		setShowMessage,
		setShowViewer,
		soldOut,
		tokenAddress,
		tokenId,
		t,
		showLogin,
		showViewer,
		collection,
	} = NftMarketplaceDetailsUIController(props);

	if (loading) {
		return (
			<section className="item-details-area mt-4">
				<div className="py-4"></div>
				<div className="container">
					<div className="row justify-content-center">
						<DnaLoader />
					</div>
				</div>
			</section>
		);
	}

	const points = asset.description?.split('\n');
	return (
		<section className="item-details-area mt-4">
			<div className="py-4"></div>
			{asset ? (
				<div className="container">
					{/* First row  */}
					<div className="row justify-content-between">
						<div className="col-12 col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center align-items-center">
							<div className="item-info my-auto d-flex align-items-center">
								<div
									className="card p-0 no-hover d-flex justify-content-center align-items-center"
									style={{
										height: '100%',
										maxHeight: '350px',
										maxWidth: '350px',
										width: '100%',
										overflow: 'hidden',
										position: 'relative',
									}}
									onClick={() => setShowViewer(true)}
								>
									<img
										className="card-img-top"
										style={{
											height: '100%',
											width: '100%',
											objectFit: 'cover',
										}}
										src={
											asset.image_url ??
											'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
										}
										alt=""
									/>
									<div
										className="card-inner d-flex align-items-center justify-content-center"
										style={{
											height: '100%',
											width: '100%',
											position: 'absolute',
											background: 'rgba(0,0,0,0.4)',
											cursor: 'pointer',
										}}
									>
										<i className="fas fa-eye fa-lg"></i>
									</div>
									{soldOut && (
										<a
											style={{
												position: 'absolute',
												top: '0',
												left: '0',
												height: '100%',
												maxHeight: '350px',
												maxWidth: '350px',
												width: '100%',
												overflow: 'hidden',
												opacity: '1',
												background: 'rgba(0,0,0,0.3)',
											}}
										>
											<img
												className="card-img-top"
												style={{
													width: '100%',
													objectFit: 'cover',
												}}
												src={'/img/png_sold_out_53735.png'}
												alt=""
											/>
										</a>
									)}
								</div>
							</div>
						</div>
						<div className="col"></div>
						<div className="col-12 col-lg-6 col-md-6 col-sm-12">
							<div className="content mt-5 mt-lg-0">
								<h3
									style={{
										color: 'var(--primary-color)',
									}}
									className="m-0"
								>
									{asset.name ? asset.name : 'No name'}
								</h3>
								<div className="owner d-flex align-items-center">
									<span>{t<string>('nft_marketplace_details.owner')}</span>
									<a className="owner-meta d-flex align-items-center ml-3">
										<h6 className="ml-2">
											{t<string>('nft_marketplace_details.itemOwner')}
										</h6>
									</a>
								</div>
							</div>
							{points && points.length > 1 ? (
								<ul>
									{points.map((point: any, idx: any) => {
										return (
											<li
												style={{
													color: 'var(--white-color)',
												}}
												key={idx}
											>
												{point
													? point
													: t<string>(
															'nft_marketplace_details.empty_description'
													  )}
											</li>
										);
									})}{' '}
								</ul>
							) : (
								<span style={{ color: 'var(--white-color)' }}>
									{asset.description
										? asset.description
										: t<string>('nft_marketplace_details.empty_description')}
								</span>
							)}
						</div>
					</div>
					{/* Second row */}
					<div className="row justify-content-between">
						<div className="col-12 col-lg-5 col-md-5 col-sm-12">
							{/* Tab Picker */}
							<div className="item-info-list mt-4">
								<ul className="netstorm-tab nav nav-tabs" id="nav-tab">
									<li>
										<a
											className="active"
											id="nav-tab2"
											data-toggle="pill"
											href="#nav-content2"
										>
											<h5 className="m-0">
												{t<string>('nft_marketplace_details.tab_2')}
											</h5>
										</a>
									</li>
								</ul>
							</div>
							<div className="tab-content" id="nav-tabContent">
								<div
									className="tab-pane fade show my-2 active"
									id="nav-content2"
								>
									<div className="item-info-list mt-4">
										<ul className="list-unstyled">
											<li className="price d-flex justify-content-between">
												<span
													style={{
														color: 'var(--white-color)',
													}}
													className="my-auto"
												>
													{t<string>('nft_marketplace_details.collection')}
												</span>
												<div className="container row">
													<div
														style={{
															color: 'var(--white-color)',
														}}
														className="col m-auto p-0 text-right"
													>
														{collection.collectionName}
													</div>
													<div
														className="rounded-circle ml-2"
														style={{
															height: '30px',
															width: '30px',
															overflow: 'hidden',
														}}
													>
														<img
															className="card-img-top"
															style={{
																height: '100%',
																width: '100%',
																objectFit: 'cover',
															}}
															src={asset.collection.icon_url}
															alt=""
														/>
													</div>
												</div>
											</li>
											<li className="price d-flex justify-content-between">
												{/* Adresse du contrat */}
												<span
													style={{
														color: 'var(--white-color)',
													}}
												>
													{t<string>('nft_marketplace_details.address')}
												</span>
												<a
													href={'https://etherscan.io/address/' + tokenAddress}
													target="_blank"
													rel="noopener noreferrer"
												>
													<span
														style={{
															color: 'var(--white-color)',
														}}
													>
														{tokenAddress.substring(0, 6) +
															'...' +
															tokenAddress.substring(
																tokenAddress.length,
																tokenAddress.length - 4
															)}
													</span>
												</a>
											</li>
											<li className="price d-flex justify-content-between">
												<span
													style={{
														color: 'var(--white-color)',
													}}
												>
													{t<string>('nft_marketplace_details.tokenId')}
												</span>
												<span
													style={{
														color: 'var(--white-color)',
													}}
												>
													{Number(tokenId)-3500}
												</span>
											</li>
											<li className="price d-flex justify-content-between">
												<span
													style={{
														color: 'var(--white-color)',
													}}
												>
													{t<string>('nft_marketplace_details.network')}
												</span>
												<span
													style={{
														color: 'var(--white-color)',
													}}
												>
													{asset.status}
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col"></div>
						<div className="col-12 col-lg-6 col-md-6 col-sm-12">
							<div className="row items">
								<div className="col-12 item px-lg-2">
									<div className="card no-hover">
										<div className="mt-0 mb-2">
											{t<string>('nft_marketplace_details.price')}
										</div>
										<div className="d-flex flex-wrap align-items-center mt-2">
											<div
												className="mx-6"
												style={{
													height: '40px',
													width: '20px',
													lineHeight: '40px',
													overflow: 'hidden',
												}}
											></div>
											<h3 style={{ color: "var(--primary-p-color)" }} className="mx-2 my-1">
												{!isFree
													? `$ ${priceToCAD} CAD`
													: t<string>('nft_marketplace_details.free')}
											</h3>
											{/* <span style={{ color: "var(--primary-p-color)" }}>{`(`}</span>
											<div
												style={{
													height: '30px',
													width: '30px',
													overflow: 'hidden',
												}}
											>
												<img
													style={{
														height: '75%',
														width: '75%',
														objectFit: 'scale-down',
													}}
													src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png"
													alt=""
												/>
											</div>
											<span style={{ color: "var(--primary-p-color)" }}>{`${priceToETH.toFixed(
												7
											)} ETH*)`}</span> */}
										</div>
										{/* <p className="">
											<small>
												{t<string>(
													'nft_marketplace_details.priceDisclaimerText'
												)}
											</small>
										</p> */}
									</div>
								</div>
							</div>
							<button
								style={{ color: "var(--primary-p-color)" }}
								className="d-block btn btn-primary mt-4 btn-block"
								onClick={() => proceedToPayment()}
							>
								{isFree
									? t<string>('nft_marketplace_details.freeBtnText')
									: t<string>('nft_marketplace_details.buyBtnText')}
							</button>
						</div>
					</div>
				</div>
			) : (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '50vh' }}
				>
					<div
						className="spinner-border"
						style={{ height: '3rem', width: '3rem' }}
						role="status"
					>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
			<LoginModal
				onShowForgotPasswordModal={() => { } }
				show={showLogin}
				onShowLogin={(value) => {
					setShowLogin(value);
				} }
				onShowRegister={(value) => {
					setShowRegister(value);
				} }
				returnProfil={false}
				{...props}		/>
			<MessageModal
				{...props}
				show={showMessage}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				onShowMessage={(value) => {
					setShowMessage(value);
				}}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
			/>
			<RegisterModal
				show={showRegister}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
			/>
			<Viewer
				imageURL={asset.image_url ?? ''}
				isShowed={showViewer}
				showViewer={setShowViewer}
			/>
		</section>
	);
};

export default withRouter(NftMarketplaceDetails);

import { Dispatch, SetStateAction } from "react";
import { ExploreLogicController } from "src/business/controller/logic/ExploreLogicController";
import NFTAsset from "src/business/entities/NFTAsset";


export class ExploreLittleUIController{
    setNftAssets:Dispatch<SetStateAction<NFTAsset[]>>;
    setLoading:Dispatch<SetStateAction<boolean>>;

    exploreLogicController: ExploreLogicController;
    constructor(setNftAssets:Dispatch<SetStateAction<NFTAsset[]>>, setLoading:Dispatch<SetStateAction<boolean>>) {
        this.setNftAssets = setNftAssets;
        this.setLoading = setLoading;
        this.exploreLogicController = new ExploreLogicController();
    }

    async getNftAssets() {
        const page_size = 25
        const nftList = await this.exploreLogicController.getData(page_size)
        this.setLoading(false)
		this.setNftAssets(nftList)
    }

    getFormattedPrice(price:number):string{
        return this.exploreLogicController.getFormattedPrice(price)
    }
}
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	collectionService,
	immutableProvider,
	storageService,
} from 'src/ApplicationContext';
import NFTAsset from 'src/business/entities/NFTAsset';
import Wallet from 'src/business/entities/Wallet';
import TokiesService from 'src/service/TokiesService';

export function AuthorController() {
	const [assets, setAssets] = useState<NFTAsset[]>([]);
	const [currentNFT, setCurrentNFT] = useState<string>('');
	const [showText, setShowText] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		loadWallet();
	}, []);

	const loadWallet = async () => {
		const wallets: Wallet[] = await TokiesService.getWalletByUser(
			storageService.get_user_id()
		);

		if (wallets.length > 0) {
			const currentWallet = wallets.find((wallet: Wallet) => wallet.connected);
			if (currentWallet) {
				storageService.set_wallet_address(currentWallet.publicKey);
			}
			await loadAssets();
		} else {
			setLoading(false);
		}
	};

	const loadAssets = async () => {
		setLoading(true);
		const wallet = storageService.get_wallet_address();
		if (wallet) {
			const collectionAddress = await collectionService.getCollection();
			try {
				const assets = await immutableProvider.getAssets(
					wallet,
					collectionAddress.address,
					500
				);
				setAssets(assets);
				setLoading(false);
			} catch (error) {
				setAssets([]);
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	};

	return {
		assets,
		loading,
		setCurrentNFT,
		setShowText,
		setLoading,
		showText,
		currentNFT,
		t,
	};
}

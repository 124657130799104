import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { NFT } from 'src/business/entities/NFT';
import Footer from 'src/presentation/components/Footer/Footer';
import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import PaymentWalletConnect from 'src/presentation/components/PaymentWalletConnect';

export interface PaymentWalletConnectProps extends RouteComponentProps {
	location: {
		key: string;
		pathname: string;
		state: {
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
		search: string;
		hash: string;
	};
}

const PaymentWalletConnectScreen:FC<PaymentWalletConnectProps> = (props: PaymentWalletConnectProps): JSX.Element => {
	return (
		<div className="main">
			<Header />
			<PaymentWalletConnect {...props} />
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default PaymentWalletConnectScreen;

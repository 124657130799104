import RedirectIosModal from 'src/presentation/components/Modals/RedirectIosModal/RedirectIosModal';
import {
	isBrowser,
	isMobile,
} from 'react-device-detect';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'src/i18n';
import DnaLoader from 'src/presentation/components/loader/DNALoader';
import { WalletUIController } from 'src/business/controller/ui/WalletUIController';

const Wallet = (props:RouteComponentProps)=> {

		const { loading, showCustodial, showIosModal, walletData, walletDataMobile, setShowIosModal } = WalletUIController(props)

		if (loading) {
			return (
				<section className="wallet-connect-area">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-7">
								{/* Intro */}
								<div className="intro text-center">
									<span>{i18n.t<string>("wallet_connect.pre_heading")}</span>
									<h3 className="mt-3 mb-0">{i18n.t<string>('wallet_connect.heading')}</h3>
									<p>{i18n.t<string>('wallet_connect.content')}</p>
								</div>
							</div>
						</div>
						<div className="row justify-content-center items">
							<DnaLoader />
						</div>
					</div>
				</section>
			);
		}

		if (isMobile) {
			return (
				<section className="wallet-connect-area">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-7">
								{/* Intro */}
								<div className="intro text-center">
									<span>{i18n.t<string>("wallet_connect.pre_heading")}</span>
									<h3 className="mt-3 mb-0">{i18n.t<string>('wallet_connect.heading')}</h3>
									<p>{i18n.t<string>('wallet_connect.content')}</p>
								</div>
							</div>
						</div>
						<div className="row justify-content-center items">
							{walletDataMobile
								.filter((wallet) =>!(showCustodial === false &&wallet.title === 'Custodial Wallet'))
								.map((item, idx) => {
									return (
										<div
											key={`wd_${idx}`}
											className="col-12 col-md-6 col-lg-4 item pe-auto"
										>
											{/* Single Wallet */}
											<div className="card single-wallet">
												<a
													className="d-block text-center"
													href="#"
													onClick={item.connect}
												>
													<img className="avatar-lg" src={item.img} alt="" />
													<h4 className="mb-0">{item.title}</h4>
													<p>{item.content}</p>
												</a>
											</div>
										</div>
									);
								})}
						</div>
					</div>
					<RedirectIosModal
						show={showIosModal}
						onShowChange={(value:any) => {
							setShowIosModal(value)
						}}
					/>
				</section>
			);
		} else if (isBrowser) {
			return (
				<section className="wallet-connect-area">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-7">
								{/* Intro */}
								<div className="intro text-center">
									<span>{i18n.t<string>("wallet_connect.pre_heading")}</span>
									<h3 className="mt-3 mb-0">{i18n.t<string>('wallet_connect.heading')}</h3>
									<p>{i18n.t<string>('wallet_connect.content')}</p>
								</div>
							</div>
						</div>
						<div className="row justify-content-center items">
							{walletData
								.filter((wallet) =>!(showCustodial === false &&wallet.title === 'Custodial Wallet'))
								.map((item, idx) => {
									return (
										<div
											key={`wd_${idx}`}
											className="col-12 col-md-6 col-lg-4 item pe-auto"
										>
											{/* Single Wallet */}
											<div className="card single-wallet">
												<a
													className="d-block text-center"
													href="#"
													onClick={item.connect}
												>
													<img className="avatar-lg" src={item.img} alt="" />
													<h4 className="mb-0">{item.title}</h4>
													<p>{item.content}</p>
												</a>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</section>
			);
		}
	
}

export default withRouter(Wallet);

import Header from 'src/presentation/components/Header/Header';
import ForgetPasswordComponent from 'src/presentation/components/ForgetPassword/ForgetPasswordComponent';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

const ForgetPasswordScreen:FC<RouteComponentProps> = (): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<ForgetPasswordComponent/>
				<Scrollup />
				<ModalMenu />
			</div>
		);
}

export default ForgetPasswordScreen;

import i18n from 'src/i18n';
import { Modal, Form } from 'react-bootstrap';
import { storageService } from 'src/ApplicationContext';

interface Props {
	show: boolean,
	missingAmount: number,
	handleMetamask: () => void,
	onShowChange:(value:boolean)=>void
}
const RedirectFundsModal = (props:Props)=> {

	const handleClose = () => {
		props.onShowChange(false);
	}

	return (
		<Modal
			size="lg"
			backdrop="static"
			show={props.show}
			onHide={handleClose}
			contentClassName="rounded"
			centered
			scrollable={true}
		>
			<Modal.Header>
				<Modal.Title className="ml-4">
					<i className="mr-2 fa fa-exclamation-triangle"></i>
					{i18n.t<string>('modal.redirect_funds.funds')}
				</Modal.Title>
				<button
					onClick={handleClose}
					className="btn rounded-circle"
					style={{ padding: '16px 16px 16px 16px' }}
				>
					<i className="mx-1 fa fa-times"></i>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form noValidate>
					<div style={{ width: '100%' }}>
						<div style={{ margin: 'auto', textAlign: 'center' }}>
							<h5 className="text-danger w-auto">
								{i18n.t<string>('modal.redirect_funds.missingETH')} :{' '}
								{props.missingAmount}{' '}
								<i className="fa fa-exclamation-circle mr-2"></i>
							</h5>
						</div>
					</div>
					<div className="row justify-content-center items">
						{storageService.get_connection_type() === 'MetaMask' && (
							<div className="col-12 col-md-6 col-lg-6 item pe-auto">
								{/* Metamask Wallet */}
								<div
									className="card single-wallet"
									style={{ height: '280px' }}
								>
									<a
										className="d-block text-center"
										href="#"
										onClick={props.handleMetamask}
									>
										<img
											className="avatar-lg"
											src={'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png'}
											alt=""
										/>
										<h4 className="mb-0">
											{'Metamask'}
										</h4>
										<p>{'Déposez des ETH  directement depuis votre compte metamask'}</p>
									</a>
								</div>
							</div>
						)}
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
	
}

export default RedirectFundsModal;

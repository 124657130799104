import { Form, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import ForgetPasswordModalUIController from 'src/business/controller/ui/ForgetPasswordModalUIController';

interface Props {
	onShowForgotPasswordModal: (value: boolean) => void;
	show: boolean;
}

const ForgotPasswordModal = (props: Props) => {
	const {
		email,
		setEmail,
		emailvalidationError,
		newEmailError,
		type_new_password,
		setNewPassword,
		setVerificationCode,
		newPassword,
		verificationCode,
		resend_new_password,
		send_verification_code,
		confirmNewPassword,
		setConfirmNewPassword,
		t,
	} = ForgetPasswordModalUIController(props);

	return (
		<Modal
			show={props.show}
			onHide={() => props.onShowForgotPasswordModal(false)}
			contentClassName="rounded"
			centered
		>
			<Modal.Header>
				<Modal.Title className="ml-4">
					{t<string>('login.forgotPassword')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<div>
					<Form noValidate>
						<div className="row">
							<div className="col-12">
								<div className="form-group mt-2">
									<label>{t<string>('login.messageLogin').toString()}</label>
									<input
										type="email"
										className="form-control"
										name="email"
										required={true}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<span className="text-danger">{emailvalidationError}</span>
								</div>
							</div>
							{type_new_password && (
								<div className="col-12">
									<div className="form-group mt-2">
										<label>{t<string>('login.enterVerificationCode')}</label>
										<input
											type="number"
											className="form-control"
											name="email"
											required={true}
											value={verificationCode}
											onChange={(e) => setVerificationCode(e.target.value)}
										/>
									</div>
									<div className="form-group mt-2">
										<label>{t<string>('login.enterNewPassword')}</label>
										<input
											type="password"
											className="form-control"
											name="password"
											required={true}
											value={newPassword}
											onChange={(e) => setNewPassword(e.target.value)}
										/>
									</div>
									<div className="form-group">
										<label>{t<string>('login.confirmNewPassowrd')}</label>
										<input
											type="password"
											className="form-control"
											name="confirmPassword"
											required={true}
											value={confirmNewPassword}
											onChange={(e) => setConfirmNewPassword(e.target.value)}
										/>
									</div>
									<span className="text-danger">{newEmailError}</span>
									<div className="col-12">
										<button
											className="btn w-100 mt-3 mt-sm-4"
											type="submit"
											style={{ color: "var(--primary-p-color)" }}
											onClick={(e) =>
												resend_new_password(
													e,
													newPassword,
													confirmNewPassword,
													email,
													verificationCode,
													props
												)
											}
										>
											{t<string>('login.confirmNewPassowrd')}
										</button>
									</div>
								</div>
							)}
							<div className="col-12">
								<button
									className="btn w-100 mt-3 mt-sm-4"
									type="submit"
									style={{ color: "var(--primary-p-color)" }}
									onClick={(e) => send_verification_code(e, email)}
								>
									{t<string>('login.sendVerificationCode')}
								</button>
							</div>
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ForgotPasswordModal;

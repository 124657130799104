import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import DnaLoader from 'src/presentation/components/loader/DNALoader';

interface Props {
    mintNft: () => void;
    totalPrice: string;
}

const PaypalButton = ({ totalPrice, mintNft }: Props): JSX.Element => {
	const  isPending  = usePayPalScriptReducer()[1];
	return (
		<>
			{isPending && <DnaLoader />}
			<PayPalButtons
				style={{ layout: 'vertical', color: 'blue', label: 'pay' }}
                createOrder={(_ , actions) => {
                    return actions.order.create(
                        {
                            purchase_units: [
                                {
                                    amount: {
                                        value: totalPrice,
                                        currency_code: 'CAD'
                                    }
                                }
                            ]
                        }
                    ).then((orderId) => orderId)

                }}
                onApprove={(_, actions) => {
                    return actions.order.capture().then(() => mintNft());
                }}
			/>
		</>
	);
};

export default PaypalButton;

import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import Environnement from 'src/env/env';

export const environnement = applicationConfiguration.environnement;
const immutableEnvironnement = applicationConfiguration.immutableEnv;

export const getBackEndUrl = (): string => {
	switch (environnement) {
		case Environnement.Prod:
			return applicationConfiguration.backendProd;
		case Environnement.Dev:
			return applicationConfiguration.backendDev;
		case Environnement.Local:
			return applicationConfiguration.localBackend;
		default: { 
				throw new Error("Environnement undefined");
			break; 
		}
	}
};

export const getImmutableAPI = (): string => {
	switch (immutableEnvironnement) {
		case Environnement.Prod:
			return applicationConfiguration.immutableApiLive;
		case Environnement.Dev:
			return applicationConfiguration.immutableApiSandbox;
		case Environnement.Local:
			return applicationConfiguration.immutableApiSandbox;
		default: { 
				throw new Error("Environnement undefined");
			break; 
		} 
	}
};

export const getImmutableLink = (): string => {
	switch (immutableEnvironnement) {
		case Environnement.Prod:
			return applicationConfiguration.immutableLinkLive;
		case Environnement.Dev:
			return applicationConfiguration.immutableLinkSandbox;
		case Environnement.Local:
			return applicationConfiguration.immutableLinkSandbox;
		default: { 
				throw new Error("Environnement undefined");
			break; 
		} 
	}
};

export const getCollectionAddress = (): string => {
	switch (environnement) {
		case Environnement.Prod:
			return applicationConfiguration.prodCollection;
		case Environnement.Dev:
			return applicationConfiguration.collectionAddress;
		case Environnement.Local:
			return applicationConfiguration.collectionAddress;
		default: { 
				throw new Error("Environnement undefined");
			break; 
		} 
	}
};


export const getStripePK = (): string => {
	switch (process.env.REACT_APP_STRIPPE_ENV) {
		case Environnement.Prod:
			return applicationConfiguration.stripeProd;
		case Environnement.Dev:
			return applicationConfiguration.stripeTest;
		case Environnement.Local:
			return applicationConfiguration.stripeTest;
		default: { 
				throw new Error("Environnement undefined");
			break; 
		} 
	}
};

// export const getRecaptchaInformation = () => {
// 	if (process.env.REACT_APP_ENV === Environnement.Dev) {
// 		return {
// 			sitekey: applicationConfiguration.recaptchaLiveKey,
// 			privateKey: applicationConfiguration.recaptchaLivePrivateKey,
// 		};
// 	}

// 	return {
// 		sitekey: applicationConfiguration.recaptchaLocalKey,
// 		privateKey: applicationConfiguration.recaptchaLocalPrivateKey,
// 	};
// };

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from 'src/presentation/components/Footer/Footer';
import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import { getStripePK } from 'src/env/SaasEnvironnement';
import { Address } from 'src/business/entities/Address';
import { PaymentMethod } from 'src/business/entities/PaymentMethod';
import PurchaseSummary from 'src/presentation/components/WalletConfirmation/PurchaseSummary';
import Wallet from 'src/business/entities/Wallet';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { NFT } from 'src/business/entities/NFT';

interface PaymentConfirmationPageProps extends RouteComponentProps  {
	location: {
		key: string;
		pathname: string;
		state: {
			address: Address;
			wallet: Wallet;
			paymentMethod: PaymentMethod;
			nft: NFT;
		};
		search: string,
		hash: string
	};
}


const PaymentConfirmationPage:FC<PaymentConfirmationPageProps> = (props: PaymentConfirmationPageProps): JSX.Element => {

	const stripePromise = loadStripe(
		//'pk_test_51LSV1SGtRHpLMmLmulnwvDxVOAQxA5B9espV21nbIwxxARq1LP5HJSxDtUMqZ6hErI9IVvAZA5tZCXETdl4Xlb6D00o3FTfmEf'   //stripe gab
		// 'pk_test_51Lwqk2BWT9DM9ccj0KQ7oNjcU10gDyt1vBOyma1qfTkFqCRzvCPaFfHmNERkE2mvb99cPKP1kIBteur9P0CduVlu004P9MGu9b'  //stripe etienne
		getStripePK()
	);
	return (
		<div className="main">
			<Header />
			<Elements stripe={stripePromise}>
				<PurchaseSummary {...props} />
			</Elements>
			<Footer />
			<ModalMenu />
			<Scrollup />
		</div>
	);
};

export default PaymentConfirmationPage;

import i18n from "src/i18n";

interface Props {
	history: {
		push: Function;
	};
	connectWallet: () => void;
}

const NoWallet = (props: Props): JSX.Element => {
	return (
		<div>
			<div>
				<h4> {i18n.t<string>('payment.noWalletConnected')} </h4>
			</div>
			<div></div>
			<div className="col-12">
				<button
					className="btn w-100 mt-3 mt-sm-4"
					type="submit"
					onClick={props.connectWallet}
				>
					{i18n.t<string>('payment.connectWallet')}
				</button>
			</div>
		</div>
	);
};

export default NoWallet;

import { immutableProvider, storageService } from "src/ApplicationContext";
import Wallet from "src/business/entities/Wallet";
import { RouteComponentProps } from "react-router";
import TokiesService from "src/service/TokiesService";
import { useState } from "react";
import { toast } from "react-toastify";

export function PaymentWalletConnectUIController(props: RouteComponentProps) {
	const [showIosModal, setShowIosModal] = useState(false);

	const walletData = [
		{
			img: 'https://link.x.immutable.com/static/media/metamask-logo.45038d58.svg',
			title: 'Metamask',
			content:
				"Une extension de navigateur d'une grande souplesse. Le porte-monnaie le plus populaire du web",
			connect: () => {
				const promise = connectWallet();
				toast.promise(promise, {
					pending: {
						render: 'Connexion...',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					success: {
						render: 'Metamask connecté !',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					error: {
						render({ data }: any) {
							return `${data.message}`;
						},
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
				});
			},
		},
	];

	const walletDataMobile = [
		{
			img: 'https://link.x.immutable.com/static/media/metamask-logo.45038d58.svg',
			title: 'WalletConnect',
			content:
				'Fonctionne avec tous types de portefeuille. (Utilisez cette méthode de connexion si vous êtes sur le mobile)',
			connect: () => {
				const promise = connectWallet();
				toast.promise(promise, {
					pending: {
						render: 'Connexion...',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					success: {
						render: 'Wallet connecté !',
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
					error: {
						render({ data }: any) {
							return `${data.message}`;
						},
						position: toast.POSITION.BOTTOM_LEFT,
						pauseOnHover: false,
						theme: 'dark',
					},
				});
			},
		},
	];
	const saveData = async () => {
		const newWallet:any= {
			publicKey: storageService.get_wallet_address(),
			type: storageService.get_connection_type(),
			userId: +storageService.get_user_id(),
			connected: true,
		};
		await TokiesService.connectWallet(newWallet);

		// go to next method payment
		goToAdress(newWallet, '', '', props);
	};

	const goToAdress = (wallet: Wallet, nft: string, paymentMethod: string, props: RouteComponentProps) => {
		props.history.push('/address', {
			wallet,
			nft,
			paymentMethod,
		});
	};

    const connectWallet = async () => {
		await immutableProvider.connectMetamask(true).then(async () => saveData());
	}

	return {walletData, walletDataMobile, showIosModal, setShowIosModal}
}
import i18n from 'src/i18n';
import { handleDate } from 'src/business/controller/logic/formatDate';
import { getMediumAddress } from 'src/business/controller/logic/ShortWalletAdress';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { isMobile } from 'react-device-detect';
import NftTransactionTracking from 'src/business/entities/NftTransactionTracking';

interface Props {
	transactions: NftTransactionTracking[];
}

export default function NftTransaction({ transactions }: Props) {
	const shorterAddress = getMediumAddress;

	const data = {
		columns: [
			{
				label: i18n.t<string>('nft_marketplace_details.id'),
				field: 'id',
				sort: 'asc',
			},
			{
				label: i18n.t<string>('nft_marketplace_details.buyer'),
				field: "buyer",
				sort: 'asc',
			},
			{
				label: i18n.t<string>('nft_marketplace_details.seller'),
				field: "seller",
				sort: 'asc',
			},
			{
				label: i18n.t<string>('nft_marketplace_details.onlyPrice'),
				field: 'price',
				sort: 'asc',
			},
			{
				label: i18n.t<string>('nft_marketplace_details.date'),
				field: 'date',
				sort: 'asc',
			},
		],
		rows: transactions.map((transaction) => ({
			"id": transaction?.transactionId ? shorterAddress(transaction.transactionId) : "null",
			"buyer": shorterAddress(transaction.buyerAddress),
			"seller": shorterAddress(transaction.sellerAddress),
			"price": transaction.priceETH,
			"date": handleDate(transaction.updatedAt)
		}))
	};

	return (
		<div style={{ 'width': isMobile ? window.innerWidth * 0.8 : undefined}}>
		<MDBTable style={{ 'borderColor': 'var(--primary-color)'}} borderless responsive={true}>
			<MDBTableHead style={{ 'color': 'white'}} columns={data.columns} />
			<MDBTableBody style={{ 'color': 'white'}} rows={data.rows} />
		</MDBTable>
		</div>
	);
}

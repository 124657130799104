import { getShortAdress,getMediumAddress } from 'src/business/controller/logic/ShortWalletAdress'; 
import User from 'src/business/entities/User';
import Wallet from 'src/business/entities/Wallet';
import WalletType from 'src/business/entities/WalletType';
import i18n from 'src/i18n';

interface Props {
	walletAddress: string;
	walletType: string;
	walletId: number;
	solde: string;
	user:User;
	disconnectWallet: Wallet[];
	onChangeConnectedWallet: (walletId: number,user:User) => void;
	onRemoveWallet: (walletId: number) => void;
}

const Wallets = ({
	walletAddress,
	walletType,
	walletId,
	solde,
	user,
	disconnectWallet,
	onChangeConnectedWallet,
	onRemoveWallet,
}: Props): JSX.Element => {
	const isNotCustodial = (wallet: Wallet): boolean => {
		return wallet.type !== WalletType.Custodial;
	};

	return (
		<>
			<div className="d-flex flex-wrap align-items-center">
				<div
					className="container card m-2"
					style={{
						width: '620px',
						height: '250px',
						backgroundImage:
							'repeating-linear-gradient( 135deg, var(--primary-color) 85%, var(--secondary-color) 180% )',
					}}
				>
					<div className="card-title">
						<div className="row">
							<div className="col">
								<h5
									style={{ color: "var(--primary-p-color)" }}
									className="m-2 d-flex align-items-center"
								>
									{i18n.t<string>('profil.tab_2.currentWallet')}
								</h5>
							</div>
							{walletType !== WalletType.Custodial && (
								<div className="col d-flex flex-row-reverse pr-0">
									<button
										className="btn btn-bordered-white btn-smaller d-flex justify-content-center align-items-center my-auto"
										style={{ width: '40px', height: '40px', color: "var(--primary-p-color)" }}
										onClick={() => {
											onRemoveWallet(walletId);
										}}
									>
										<i className="fas fa-times"></i>
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="row justify-content-between align-items-center h-100">
						<div className="col">
							<p style={{ margin: '1.5rem 0', color: "var(--primary-p-color)" }}>
								<>
									<img
										className="pr-2"
										style={{ height: '40px' }}
										src={
											walletType === 'MetaMask'
												? 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
												: './img/tokies_wallet.png'
										}
									></img>
									<span style={{ color: "var(--primary-p-color)" }}>
										{walletType === 'MetaMask' ? walletType : i18n.t<string>('profil.tab_2.tokiesWallet')}{' '}
									</span>
								</>
							</p>
						</div>
						<div className="col">
							<h5 style={{ color: "var(--primary-p-color)" }}>
								<>
									{solde} {i18n.t<string>('profil.tab_2.currency')}
								</>
							</h5>
						</div>
					</div>
					<div className="row justify-content-between align-items-center h-100">
						<div style={{ color: "var(--primary-p-color)" }} className="col">
							{getShortAdress(walletAddress)}
						</div>
					</div>
					<div className="d-flex justify-content-center">
						<a
							className="btn btn-bordered-white btn-smaller m-2"
							style={{ visibility: 'hidden' }}
						>
							<>{i18n.t<string>('profil.tab_2.connect')}</>
						</a>
					</div>
				</div>
				{/* <hr style={{border: "1px solid var(--card-bg-color)", borderColor: "var(--card-bg-color) !important", backgroundColor: "var(--card-bg-color)"}}></hr> */}
				{disconnectWallet.map((wallet, idx) => (
					<div
						key={idx}
						className="container card m-2"
						style={{
							marginTop: 10,
							transition: '0.3s',
							width: '300px',
						}}
					>
						<div className="card-title">
							<div className="row">
								<div className="col pl-0">
									<h5 className="m-2 d-flex align-items-center">
										<img
											className="pr-2"
											style={{ height: '40px' }}
											src={
												wallet.type === 'MetaMask'
													? 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
													: './img/tokies_wallet.png'
											}
										></img>
										<span style={{ color: "var(--primary-p-color)" }}>
											{wallet.type === 'MetaMask'
												? wallet.type
												: i18n.t<string>('profil.tab_2.tokiesWallet')}{' '}
										</span>
									</h5>
									{getMediumAddress(wallet.publicKey)}

								</div>

								{isNotCustodial(wallet) && (
									<div className="col d-flex flex-row-reverse pr-0">
										<button
											className="btn btn-bordered-white btn-smaller d-flex justify-content-center align-items-center my-auto"
											style={{ width: '40px', height: '40px' }}
											onClick={() => {
												onRemoveWallet(wallet.id);
											}}
										>
											<i
												style={{ color: "var(--primary-p-color)" }}
												className="fas fa-times"
											></i>
										</button>
									</div>
								)}
							</div>
						</div>
						<div>
							<h5
								style={{ color: "var(--primary-p-color)" }}
								className="m-2 d-flex align-items-center"
							>
								{getShortAdress(wallet.publicKey)}
							</h5>
						</div>
						<div className="row justify-content-between">
							<div className="col">
								<h5 style={{ color: "var(--primary-p-color)" }}>
									<>
										{wallet.solde} {i18n.t<string>('profil.tab_2.currency')}
									</>
								</h5>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<a
								style={{ color: "var(--primary-p-color)" }}
								className="btn btn-bordered-white btn-smaller m-2"
								onClick={() => onChangeConnectedWallet(wallet.id,user)}
							>
								<>{i18n.t<string>('profil.tab_2.connect')}</>
							</a>
						</div>
					</div>
				))}
				<div className="d-flex justify-content-center">
					<a
						className="btn btn-smaller m-4 d-flex justify-content-center align-items-center"
						style={{ height: '50px', width: '50px', color: "var(--primary-p-color)" }}
						href="/wallet-connect"
					>
						<i className="fas fa-plus"></i>
					</a>
				</div>
			</div>
		</>
	);
};

export default Wallets;

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/App';
import './i18n';
import reportWebVitals from 'src/reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';

require('dotenv').config({ path: '../.env' });

ReactDOM.render(
	<GoogleOAuthProvider clientId={applicationConfiguration.googleClientId}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</GoogleOAuthProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import { useTranslation } from 'react-i18next';
import CardGeneral from '../Cards/CardGeneral';
import { useState } from 'react';
import DemandeModal from './DemandeModal';
import { RouteComponentProps } from 'react-router';

export interface GallerieCardProps extends RouteComponentProps {
	item: any ;
	idx: any
	key:any
}

const GallerieCard = (props: GallerieCardProps) => {
	const { t } = useTranslation();
	const [showLogin, setShowLogin] = useState(false);
	const [galleryId, setGalleryId] = useState<number>();
	const sendDemande= async () =>{
		setGalleryId(props.item.id)
		setShowLogin(true)
	}
	return (
		<CardGeneral 
			index={props.idx}
			titre={props.item.galleryName} 
			imageUrl={{info:'round',url:props.item.image_url? props.item.image_url: 'https://img.freepik.com/premium-vector/emblem-art-gallery_658131-1.jpg?w=2000'}}

			description={
				<>
					<div className="seller d-flex justify-content-start my-2">
						<span className='col-4 p-0 m-0'>{t<string>('zone_admin.gallerie.email')} </span>
						<a className='col-8 p-0 m-0'><b>{props.item.email}</b></a>
						
					</div>
					<div className="seller d-flex justify-content-start my-2">
						<span className='col-4 p-0 m-0'>{t<string>('zone_admin.gallerie.contact')}</span> 
						<a className='col-8 p-0 m-0'><b>{props.item.ownerName}</b></a>
						
					</div>
					<div className="seller d-flex justify-content-start align-items-center my-2">
						<span className='col-4 p-0 m-0'>{t<string>('zone_admin.gallerie.phoneNumber')}</span> 
						<a className='col-8 p-0 m-0'><b>{props.item.phoneNumber} </b></a>
						
					</div>
					<button 
						className="btn w-100 mt-3 mt-sm-4"
						onClick={sendDemande}
					>
						<span className='col-4 p-0 m-0'>{t<string>('zone_admin.gallerie.demands.sendDemand')}</span> 
					</button>
					<DemandeModal
						{...props}
						show={showLogin}
						galleryId={galleryId}
						onShowDemandModal={(value) => {
							setShowLogin(value);
						}}
					/>
				</>
			}	
		/>
	);
};

export default GallerieCard;

import i18n from "src/i18n";

const PoweredByTokies = (): JSX.Element => {
	const onClick = () => {
		window.location.replace('https://tokies.io/');
	};

	return (
		<div className="intro text-center">
			<h3 className="powerLink" style={{ color: 'var(--primary-color)' }} onClick={onClick}>
				{i18n.t<string>('header.powered')}
			</h3>
		</div>
	);
};

export default PoweredByTokies;

import i18n from 'src/i18n';
import { NFT } from 'src/business/entities/NFT';
import DnaLoader from 'src/presentation/components/loader/DNALoader';

interface Props {
	purchaseInProgress: boolean;
	makeCryptoPayment: () => Promise<void>;
	canBuy: boolean;
	nft: NFT;
	taxeProv: Number;
	taxeGouv: Number;
	totalPrice: Number;
	shipping_price: number;
	nftPriceCan: number;
}

const CryptoPaymentConfirmation = ({
	purchaseInProgress,
	makeCryptoPayment,
	canBuy,
	nft,
	taxeGouv,
	taxeProv,
	totalPrice,
	shipping_price,
	nftPriceCan
}: Props) => {
	return (
		<div className="card p-4 d-flex flex-column justify-content-center h-100">
			{!purchaseInProgress ? (
				<div className="px-4 pb-2">
					<button
						type="submit"
						onClick={makeCryptoPayment}
						color="dark"
						className="btn w-100"
					>
						{i18n.t<string>('payment.confirm.confirm')}
					</button>
					{canBuy === false && (
						<div
							style={{
								marginTop: 20,
								textAlign: 'center',
							}}
						>
							{' '}
							<span className="text-danger"> Not enoug fund</span>{' '}
						</div>
					)}
				</div>
			) : (
				<div className="d-flex align-items-center justify-content-center">
					<DnaLoader />
				</div>
			)}
			{i18n.t<string>('payment.policy_agreement').toString()}

			<div
				className="my-4"
				style={{
					width: '100%',
					border: 'solid var(--primary-color)',
					borderWidth: '1px',
					opacity: '0.3',
				}}
			></div>

			<div className="row m-2">
				<div className="col-5 text-left">
					{i18n.t<string>('payment.price').toString() + ' : '}
				</div>
				<div className="col-7 text-right  d-flex align-items-center">
					{nftPriceCan.toFixed(2)} $CAD
				</div>
				{' ( '}
				<img
					style={{
						width: '25px',
						height: '25px',
						objectFit: 'contain',
					}}
					src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png"
				/>
				{nft.price + 'ETH )'}
			</div>
			<div className="row m-2">
				<div className="col-5 text-left">
					{i18n.t<string>('payment.shipping').toString() + ' : '}
				</div>
				<div className="col-7 text-right  d-flex align-items-center">
					{shipping_price.toFixed(2)} $CAD
				</div>
			</div>
			<div
				className="my-2 w-50 ml-auto"
				style={{
					border: 'solid var(--primary-color)',
					borderWidth: '1px',
					opacity: '0.3',
				}}
			></div>
			<div className="row m-2">
				<div className="col-5 text-left">
					{i18n.t<string>('payment.before_taxes').toString() + ' : '}
				</div>
				<div className="col-7 text-right  d-flex align-items-center">
					{nftPriceCan.toFixed(2) + shipping_price.toFixed(2)} $CAD
				</div>
			</div>
			<div className="row m-2">
				<div className="col-5 text-left">
					{i18n.t<string>('payment.tvq').toString() + ' : '}
				</div>
				<div className="col-7 text-right  d-flex align-items-center">
					{taxeProv.toFixed(2) + ' '} $CAD
				</div>
			</div>
			<div className="row m-2">
				<div className="col-5 text-left">
					{i18n.t<string>('payment.tps').toString() + ' : '}
				</div>
				<div className="col-7 text-right  d-flex align-items-center">
					{taxeGouv.toFixed(2) + ' '} $CAD
				</div>
			</div>
			<div
				className="my-2"
				style={{
					width: '100%',
					border: 'solid var(--primary-color)',
					borderWidth: '1px',
					opacity: '0.3',
				}}
			></div>
			<div className="row m-2">
				<h4 className="col-5 text-left my-2 p-0">
					{i18n.t<string>('payment.total') + ' : '}
				</h4>
				<div className="col-7 text-right  d-flex align-items-center">
					{totalPrice.toFixed(2) + ' $CAD'}
				</div>
				{' ( '}
				<img
					style={{
						width: '25px',
						height: '25px',
						objectFit: 'contain',
					}}
					src="https://blog.logomyway.com/wp-content/uploads/2021/11/Ethereum-logo.png"
				/>
				{nft.price + 'ETH* )'}
				<div
					className="d-flex my-2 pl-4"
					style={{
						fontSize: '0.8rem',
						textDecoration: 'underline',
					}}
				>
					{i18n.t<string>('nft_marketplace_details.priceDisclaimerText')}
				</div>
			</div>
		</div>
	);
};

export default CryptoPaymentConfirmation;

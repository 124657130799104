
import { isMobile } from 'react-device-detect';
import { GallerieScreenUIController } from 'src/business/controller/ui/GallerieScreenUIController';
import {  useEffect } from 'react';
import FiltersModal from '../ZoneAdmin/FiltersModal';
import { RouteComponentProps } from 'react-router';
import GallerieCard from './GallerieCard';

interface GalerieProps extends RouteComponentProps{
	title?:string
}

const GallerieTabs = ({history,location,match,title}: GalerieProps) => {
    const {galleries,updateElement,setShowLogin,showLogin} = GallerieScreenUIController();
	
	useEffect(()=>{
		updateElement(galleries)
	},[])
	return (
		<>
			{title?
				<div
					style={{
						paddingLeft: isMobile ? undefined : '38%',
						alignItems: 'center',
					}}
				>
					<h3> {title} </h3>
				</div>
			:
				<></>
			}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				{galleries.map((item, idx) => {return <GallerieCard history={history} location={location} match={match} item={item} idx={idx} key={idx}/>})}
			</div>
			<FiltersModal
				remaining={10}
				show={showLogin}
				onShowIdleModal={(value) => {
					setShowLogin(value);
				}}
			/>
		</>
	);
};

export default GallerieTabs;

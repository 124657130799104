import { useEffect, useState } from "react";
import { adressService, immutableProvider, storageService, walletService } from "src/ApplicationContext";
import { Address } from "src/business/entities/Address";
import { PaymentMethod } from "src/business/entities/PaymentMethod";
import Wallet from "src/business/entities/Wallet";
import WalletType from "src/business/entities/WalletType";
import { NFT } from "src/business/entities/NFT";

export interface PaymentInformationProps {
	nft: NFT;
	history: { push: Function };
}

export function PaymentInformationUIController (props: PaymentInformationProps) {
	const { nft } = props;
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
	const [userAdress, setUserAdress] = useState<Address[]>([]);
	const [userWallet, setUserWallet] = useState<Wallet>();
	const getUserAdress = async (): Promise<void> => {
		const userAdress = await adressService.getUserAdress();
		setUserAdress(userAdress);
	};

	const getUserWallet = async (): Promise<void> => {
        const userId = storageService.get_user_id();

        const wallets = (
			await walletService.getWalletByUser(parseInt(userId ?? ''))
		).filter((wallet) => wallet.type !== WalletType.Custodial);

		const userWallet = wallets.find(
			(wallet) => wallet.publicKey == storageService.get_wallet_address()
		);

		if (userWallet) {
			const balance = await immutableProvider
				.getBalance(userWallet.publicKey ?? '')
				.catch((err) => console.error(err));
			userWallet.solde = balance;
			setUserWallet(userWallet);
		}
	};

    const onSelectCreditCard = (): void  => {
		if (paymentMethod === PaymentMethod.CreditCard) {
			setPaymentMethod(undefined);
			return;
		}

		setPaymentMethod(PaymentMethod.CreditCard);
	};

	const onSelectCrypto = (): void => {
		if (paymentMethod === PaymentMethod.Crypto) {
			setPaymentMethod(undefined);
			return;
		}

		setPaymentMethod(PaymentMethod.Crypto);
	};

	const onSelectPaypal = (): void => {
		if (paymentMethod === PaymentMethod.Paypal) {
			setPaymentMethod(undefined);
			return;
		}

		setPaymentMethod(PaymentMethod.Paypal);
	};

	const onProceedToPayment = (): void => {
		if (!paymentMethod) {
			alert('Please select a payment method');
			return;
		}

		const wallet = userWallet;
		const address = userAdress[0];

		if (address && wallet) {
			props.history.push('/paymentConfirmation', {
				address,
				wallet,
				nft,
				paymentMethod,
			});
			return;
		} else {
			if (paymentMethod === PaymentMethod.Crypto) {
				props.history.push('/cryptoPayment', {
					nft,
					paymentMethod,
				});
				return;
			} else {
				props.history.push('/select-wallet-payment', {
					nft,
					paymentMethod,
				});
				return;
			}
		}
	};

	const isCreditCardSelected = (): boolean =>
		paymentMethod === PaymentMethod.CreditCard;

	const isCryptoSelected = (): boolean =>
		paymentMethod === PaymentMethod.Crypto;

	const isPaypalSelected = (): boolean =>
		paymentMethod === PaymentMethod.Paypal;

	useEffect(() => {
		getUserAdress();
		getUserWallet();
	}, []);

	return { nft, isCryptoSelected, onProceedToPayment, isPaypalSelected, isCreditCardSelected, onSelectPaypal, onSelectCreditCard, onSelectCrypto}
}
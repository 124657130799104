import axios from 'axios';

const apiKey =
	'360ea2e8b37705150005fa45a8a51b157716f1c804259f1840d4c33056a6cb56';

const requestURL =
	'https://min-api.cryptocompare.com/data/price?fsym=CAD&tsyms=ETH&api_key' +
	apiKey;

export default class ConversionService {
	async getRateETH() {
		return (await axios.get(requestURL)).data.ETH;
	}
}

import { useEffect, useState } from 'react';
import { Gallery } from 'src/business/entities/Gallery';
import TokiesService from 'src/service/TokiesService';

export function GallerieScreenUIController() {
	const [sortedElement, setSortedElement] = useState<Gallery[]>([]);
	const [searchByName, setSearchByName] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [galleries, setGalleries] = useState<Gallery[]>([]);
	const [showLogin, setShowLogin] = useState(false);

	const updateElement = (asset:Gallery[]) => {
		setSortedElement(asset)
	}
	
	const getData = async () => {
		const gallery= await TokiesService.getGallery()
		setGalleries(gallery)
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	return {
		loading,
		searchByName,
		setSearchByName,
		updateElement,
		sortedElement,
		galleries,
		showLogin,
		setShowLogin
	};
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import i18n from 'src/i18n';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserLogicController } from 'src/business/controller/logic/UserLogicController';
import { RouteComponentProps } from 'react-router';
import EnableMFAModal from './EnabledMFAModal';
import { useTranslation } from 'react-i18next';

interface SettingButtonProps extends RouteComponentProps {
	googleAuth: boolean;
}

export default function SettingButton(props: SettingButtonProps) {
	const { t } = useTranslation();
	const userController = new UserLogicController();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [showEnabledMFAModal, setShowEnabledMFAModal] =
		React.useState<boolean>(false);

	return (
		<>
			<EnableMFAModal
				show={showEnabledMFAModal}
				handleClose={() => setShowEnabledMFAModal(false)}
			/>
			<div>
				<Button
					startIcon={<SettingsIcon />}
					variant="contained"
					style={{ backgroundColor: 'var(--primary-color)', borderRadius: 10 }}
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					{i18n.t<string>('profil.settings').toString()}
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					{!props.googleAuth && (
						<MenuItem
							onClick={() => {
								setShowEnabledMFAModal(true);
								handleClose();
							}}
						>
							{t('profil.enabledMFA')}
						</MenuItem>
					)}
					{!props.googleAuth && (
						<MenuItem onClick={() => userController.changePassword(props)}>
							{i18n.t<string>('header.updatePassword')}{' '}
						</MenuItem>
					)}
					<MenuItem onClick={() => userController.logout(props)}>
						{i18n.t<string>('header.deconnexion')}{' '}
					</MenuItem>
				</Menu>
			</div>
		</>
	);
}

import { useState } from "react";
import { Address } from "src/business/entities/Address";
import i18n from "src/i18n";
import Wallet from "src/business/entities/Wallet";
import { isMobile } from "react-device-detect";
import Viewer from "src/presentation/components/Viewer/Viewer";
import ConfirmWallet from "src/presentation/components/WalletConfirmation/ConfirmWallet";
import { NFT } from "src/business/entities/NFT";

interface Props {
    history: { push: Function };
    location: {
        key: string;
        pathname: string;
        state: {
            address: Address;
            wallet: Wallet;
            nft: NFT;
        };
    };
}

const PurchaseSummary = (props: Props): JSX.Element => {
    const { nft, address, wallet } = props?.location?.state;

    const [showViewer, setShowViewer] = useState<boolean>(false);

    return (
        <section className="author-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="intro text-center">
                            <span>
                                <>{" "}{i18n.t<string>("payment.confirm.success")}{" "}</>
                            </span>
                            <h3 className="mt-3 mb-0">
                                {i18n.t<string>("payment.confirm.successText")}
                            </h3>
                        </div>
                        <h3 className="text-center">
                            <>
                                {" "}{i18n.t<string>("payment.confirm.summary")}{" "}
                            </>
                        </h3>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <ConfirmWallet wallet={wallet} />
                                <div
                                    className="card pb-auto w-100 h-100 d-flex m-2"
                                    style={{
                                        minWidth: "360px",
                                        maxWidth: "49%",
                                    }}
                                >
                                    <div className="p-0">
                                        <h3 className="mt-0">
                                            {" "}
                                            {i18n
                                                .t("payment.confirm.address")
                                                .toString()}{" "}
                                        </h3>
                                    </div>
                                    <div className="">
                                        <p className="mb-0">
                                            {address.address}
                                        </p>
                                        <p className="mb-0">
                                            {`${address.zip}, ${address.city}, ${address.state}`}
                                        </p>
                                        <p className="mb-0">
                                            {address.country}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="card p-0 mt-3 mx-auto"
                                style={{
                                    width: `${isMobile ? "360px" : "100%"}`,
                                    overflow: "hidden",
                                }}
                            >
                                <div className="d-flex h-100 flex-wrap w-100">
                                    <div
                                        className="card p-0 d-flex justify-content-center align-items-center"
                                        style={{
                                            overflow: "hidden",
                                            width: "30%",
                                            minWidth: `${
                                                isMobile ? "360px" : ""
                                            }`,
                                            maxHeight: `${
                                                isMobile ? "360px" : ""
                                            }`,
                                        }}
                                        onClick={() => setShowViewer(true)}
                                    >
                                        <img
                                            style={{
                                                objectFit: "cover",
                                                minHeight: "100%",
                                                minWidth: "100%",
                                            }}
                                            src={nft.image}
                                        />
                                        <div
                                            className="card-inner d-flex align-items-center justify-content-center"
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                position: "absolute",
                                                background: "rgba(0,0,0,0.4)",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <i className="fas fa-eye fa-lg"></i>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            overflow: "hidden",
                                            width: "70%",
                                        }}
                                    >
                                        <div style={{ marginLeft: 20 }}>
                                            <h3 className="text-effect">
                                                {nft.name}
                                            </h3>
                                            <h5>
                                                {i18n
                                                    .t("payment.description")
                                                    .toString()}{" "}
                                                :{" "}
                                                <span
                                                    className="w-75"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    {" "}
                                                    {nft.description}
                                                </span>
                                            </h5>
                                            <h5>
                                                {i18n
                                                    .t(
                                                        "nft_marketplace_details.address"
                                                    )
                                                    .toString()}{" "}
                                                : {nft.token_address}
                                            </h5>
                                            <h5>
                                                {i18n
                                                    .t(
                                                        "nft_marketplace_details.tokenId"
                                                    )
                                                    .toString()}{" "}
                                                :  {nft.token_id}
                                            </h5>
                                            <h5>
                                                {i18n
                                                    .t(
                                                        "nft_marketplace_details.network"
                                                    )
                                                    .toString()}{" "}
                                                : {nft.status}
                                            </h5>
                                            <h5>
                                                {i18n
                                                    .t("payment.price")
                                                    .toString()}{" "}
                                                : {nft.priceCan} $CAD
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 25,
                        }}
                    ></div>
                </div>
            </div>
            <Viewer
                imageURL={nft.image}
                isShowed={showViewer}
                showViewer={setShowViewer}
            />
        </section>
    );
};

export default PurchaseSummary;


import i18n from 'src/i18n';
import ExploreAutoSellOrders from 'src/presentation/components/Explore/ExploreAutoSellOrders';
import ExploreP2P from 'src/presentation/components/Explore/ExploreP2P';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import { RouteComponentProps } from 'react-router';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	dir: string;
}

export interface MarketplaceProps extends RouteComponentProps {}

const Marketplace = (_props: MarketplaceProps) => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, dir, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				dir={dir}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index: number) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	}
		return (
			<section className="activity-area">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 col-lg-7">
							{/* Intro */}
							<div className="intro text-center mb-4">
								<span>{i18n.t<string>('marketplace.pre_heading')}</span>
								<h3 className="mt-3 mb-0">{i18n.t<string>('marketplace.heading')}</h3>
								<p>{i18n.t<string>('marketplace.content')}</p>
							</div>
						</div>
					</div>
					<div className="item-info-list mt-4">
					<Box sx={{ bgcolor: 'transparent' }}>
							<AppBar position="static">
								<Tabs
									style={{ background: 'var(--primary-color)'}}
									value={value}
									onChange={handleChange}
									textColor="inherit"
									variant="fullWidth"
								>
									<Tab
										label={i18n.t<string>('marketplace.tab_1')}
										{...a11yProps(0)}
									/>
									{/* <Tab
										label={i18n.t<string>('marketplace.tab_2')}
										{...a11yProps(1)}
									/> */}
								</Tabs>
							</AppBar>
							<SwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={value}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={value} index={0} dir={theme.direction}>
								<ExploreAutoSellOrders />
								</TabPanel>
								<TabPanel value={value} index={1} dir={theme.direction}>
								<ExploreP2P />
								</TabPanel>
							</SwipeableViews>
						</Box>
				</div>
				</div>
			</section>
		);
	}

export default Marketplace;
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import SoldeETH from 'src/presentation/components/SoldeETH/SoldeETH';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import i18n from 'src/i18n';
import LoginModal from 'src/presentation/components/Modals/LoginModal/LoginModal';
import MessageModal from 'src/presentation/components/Modals/LoginModal/MessageModal';
import RegisterModal from 'src/presentation/components/Modals/RegisterModal/RegisterModal';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import ForgotPasswordModal from 'src/presentation/components/Modal/ForgotPasswordModal';

import IdleModal from 'src/presentation/components/Modals/IdleModal/IdleModal';
import { storageService } from 'src/ApplicationContext';
import { HeaderUIController } from 'src/business/controller/ui/HeaderUIController';
import { useIdleTimer } from 'react-idle-timer';
import WalletType from 'src/business/entities/WalletType';

const timeout = 600_000; //10 min
const promptBeforeIdle = 300_000; //5 min
const throttle = 0.2 * 1000;

const Header = (props: RouteComponentProps) => {
	const {
		onActive,
		onIdle,
		onPrompt,
		verifyInterval,
		t,
		isAdmin,
		marketId,
		changeLanguage,
		setShowLogin,
		handleLogout,
		setShowRegister,
		showRegister,
		setShowForgotPassword,
		showForgotPassword,
		showMessage,
		setShowMessage,
		showLogin,
		showIdle,
		setShowIdle,
		remaining,
	} = HeaderUIController(timeout);

	const { getRemainingTime, reset } = useIdleTimer({
		onIdle: () => onIdle(props),
		onActive: onActive,
		onPrompt: () => onPrompt(reset),
		timeout: timeout,
		promptBeforeIdle: promptBeforeIdle,
		throttle: throttle,
	});

	useEffect(() => {
		verifyInterval(getRemainingTime, throttle);
	});
	
	const color =
		applicationConfiguration.saasName === 'Boutet' ? 'black' : 'white';

	return (
		<header id="header">
			{/* Navbar */}
			<nav
				data-aos="zoom-out"
				data-aos-delay={800}
				className="navbar navbar-expand"
			>
				<div className="container header">
					{/* Navbar Brand*/}
					<a className="navbar-brand mx-0" href="/">
						<div
							className="d-flex justify-content-center align-items-center"
							style={{
								height: 100,
								width: '100%',
								overflow: 'hidden',
							}}
						>
							<img
								className="card-img-top"
								style={{
									height: 100,
									width: '100%',
									objectFit: 'contain',
								}}
								src={t<string>('img.header')}
								alt=""
							/>
						</div>
					</a>
					{/* Navbar */}
					<ul className="navbar-nav items">
						<li className="nav-item">
							<a href="/marketplace" className="nav-link">
								{t<string>('header.boutique').toString()}
							</a>
						</li>

						{isAdmin && marketId === applicationConfiguration.marketId && (
							<li className="nav-item dropdown">
								<a className="nav-link">
									{t('header.admin').toString()}
									<i className="ml-2 fas fa-angle-down" />
								</a>
								<ul className="dropdown-menu">
									<li className="nav-item">
										<a 
										className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:0})
											}}
										href="/zone-admin" >
												{t<string>('zone_admin.tableaux.title')}
										</a>

									</li>
								<a className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:1})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.tab_3.title')}
								</a>
								{/* { storageService.get_artist_id() &&
								<a className="nav-link" 
										onClick={()=>{
												props.history.push("/zone-admin",{tab:2})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.entente.title')}
								</a>}
								{storageService.get_artist_id() && <a className="nav-link"  
										onClick={()=>{
												props.history.push("/zone-admin",{tab:3})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.gallerie.title')}
								</a>}
								<a className="nav-link"  
										onClick={()=>{
												props.history.push("/zone-admin",{tab:4})
											}}
										href="/zone-admin" >
										{t<string>('zone_admin.artistProfile.title')}
								</a> */}
								</ul>
							</li>
						)}
						{storageService.get_connexion() && (
							<li className="nav-item dropdown">
								{/* <a href="#" className='nav-link dropdown'>Balance <i className="fas fa-angle-down ml-1" /></a> */}
								<a className="nav-link">
									{' ' + t<string>('header.balance') + ' '}
									<i className="ml-2 fas fa-angle-down" />
								</a>
								<ul className="dropdown-menu">
									<li className="mx-2 nav-item">
										<div className="nav-link">
											<SoldeETH />{' '}
										</div>
									</li>
									{storageService.get_wallet_address() !== 'null' &&
										storageService.get_connected_wallet_type() ===
											WalletType.MetakMask.toString() && (
											<li className="nav-item">
												<a href="/deposit" className="nav-link">
													{t<string>('header.depot').toString()}
												</a>
											</li>
										)}
								</ul>
							</li>
						)}
					</ul>
					<div className="ml-auto" />
					<ul className="navbar-nav toggle">
						<li className="nav-item">
							<a
								href="test"
								className="nav-link"
								data-toggle="modal"
								data-target="#menu"
							>
								<i className="fas fa-bars fa-lg toggle-icon m-0" />
							</a>
						</li>
					</ul>
					<ul className="navbar-nav items">
						<li className="nav-item">
							<a
								className="nav-link text-secondary"
								style={{ cursor: 'pointer' }}
								onClick={changeLanguage}
							>
								{i18n.language.includes('fr') ? 'English' : 'Français'}
							</a>
						</li>
						<li aria-label="ContactHeader" className="nav-item">
							<a
								href="/contact"
								aria-label="Contact-Header"
								className="nav-link"
								referrerPolicy="origin"
							>
								{t<string>('header.contact')}
							</a>
						</li>
					</ul>

					<ul className="navbar-nav items">
						<li className="nav-item dropdown">
							<a
								className="nav-link dropdown"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (!storageService.get_connexion()) setShowMessage(true);
								}}
							>
								<i
									aria-label="Login-Icon"
									style={{ color: color }}
									className="icon-user mr-md-2"
								/>{' '}
								{storageService.get_connexion() && (
									<i className="ml-2 fas fa-angle-down" />
								)}
							</a>

							{storageService.get_connexion() && (
								<ul className="dropdown-menu">
									{JSON.parse(storageService.get_connexion() ?? '') ? (
										<div>
											<li className="nav-item">
												<a className="nav-link" href="/profil">
													{t<string>('header.profil').toString()}
												</a>
											</li>
											<li className="nav-item">
												<a
													href="/"
													onClick={(e: any) => handleLogout(e, props)}
													className="nav-link"
												>
													{t<string>('header.deconnexion').toString()}
												</a>
											</li>
										</div>
									) : (
										<div></div>
									)}
								</ul>
							)}
						</li>
					</ul>
				</div>
			</nav>
			<ToastContainer />
			<ForgotPasswordModal
				show={showForgotPassword}
				onShowForgotPasswordModal={setShowForgotPassword}
			/>
			<LoginModal
				{...props}
				onShowForgotPasswordModal={() => {
					setShowForgotPassword(true);
				}}
				show={showLogin}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				returnProfil={true}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
			></LoginModal>
			<MessageModal
				{...props}
				show={showMessage}
				onShowLogin={(value) => {
					setShowLogin(value);
				}}
				onShowMessage={(value) => {
					setShowMessage(value);
				}}
				onShowRegister={(value) => {
					setShowRegister(value);
				}}
			></MessageModal>
			<RegisterModal
				{...props}
				show={showRegister}
				onShowRegister={(value) => {
					reset();
					setShowRegister(value);
				}}
				onShowLogin={(value) => {
					reset();
					setShowLogin(value);
				}}
			></RegisterModal>
			<IdleModal
				{...props}
				show={showIdle}
				remaining={remaining}
				onShowIdleModal={(value) => {
					setShowIdle(value);
					reset();
				}}
			></IdleModal>
		</header>
	);
};

export default withRouter(Header);

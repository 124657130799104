import NFTAsset from 'src/business/entities/NFTAsset';
import { immutableProvider, storageService } from 'src/ApplicationContext';
import TokiesService from 'src/service/TokiesService';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { NFTLogicController, NFTProps } from '../logic/NFTLogicController';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function NFTPeerToPeerDetailsUIController(props: NFTProps) {
	const nftLogicController = new NFTLogicController();

	const { t } = useTranslation();
	const [asset, setAsset] = useState<NFTAsset>(null);
	const [priceToCAD, setPriceToCAD] = useState<number>(0);
	const [userAddress, setUserAddress] = useState<string>('');
	const [insufficientFunds, setInsufisantFunds] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [missingAmount, setMissingAmount] = useState<number>(0);
	const [priceToETH, setPriceToETH] = useState<number>(0);
	const [showViewer, setShowViewer] = useState(false);

	useEffect(() => {
		getNFTData(props.tokenAddress, props.tokenId);
	}, []);

	let points = asset?.description.split('\n');

	const getNFTData = async (
		tokenAddress: string,
		tokenId: string
	): Promise<void> => {
		await immutableProvider.init();

		const order = await immutableProvider.getOrders({
			sell_token_address: tokenAddress,
			sell_token_id: tokenId,
			status: 'active',
		});

		const asset = await immutableProvider.getAssetDetails(
			tokenAddress,
			tokenId
		);

		const conversionCAD: number = await TokiesService.getConversionRateCAD();

		setUserAddress(immutableProvider.address);
		immutableProvider.addressSubject.subscribe({
			next: (address) => setUserAddress(address),
		});

		const priceToETH = await immutableProvider.weiToEth(
			order[0].buy.data.quantity
		);
		const priceToCAD = priceToETH * (1 / conversionCAD);
		setAsset(asset);
		setPriceToCAD(priceToCAD);
		setPriceToETH(priceToETH);

		if (immutableProvider.address) {
			const wallet_address = storageService.get_wallet_address();
			const balance = await immutableProvider.getBalance(wallet_address);
			setInsufisantFunds(balance < priceToETH);
			setMissingAmount(priceToETH - balance);
		}
	};

	const handleInsufficientMetamask = async (): Promise<void> => {
		const promise1 = async () => await immutableProvider.depositMetaMask();
		const closeModal = () => setShowModal(true);

		toast.promise(promise1, {
			pending: {
				render: 'Achat en cours...',
				position: toast.POSITION.BOTTOM_RIGHT,
				pauseOnHover: false,
				theme: 'dark',
			},
			success: {
				render() {
					closeModal();
					return 'Transaction réussie';
				},
				position: toast.POSITION.BOTTOM_RIGHT,
				pauseOnHover: false,
				theme: 'dark',
			},
			error: {
				render(data: any) {
					return `${data.message}`;
				},
				position: toast.POSITION.BOTTOM_RIGHT,
				pauseOnHover: false,
				theme: 'dark',
			},
		});
	};

	const proceedToPayment = (
		_: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		asset: NFTAsset,
		priceToETH: number,
		priceToCAD: number,
		props: RouteComponentProps
	): void => {
		const isFree = false;
		nftLogicController.proceedToPayment(
			asset,
			priceToETH,
			priceToCAD,
			isFree,
			props
		);
	};

	return {
		points,
		proceedToPayment,
		handleInsufficientMetamask,
		asset,
		priceToCAD,
		userAddress,
		insufficientFunds,
		showModal,
		missingAmount,
		priceToETH,
		showViewer,
		setShowViewer,
		t,
		setShowModal,
	};
}

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EntenteGallerieTabUIController } from 'src/business/controller/ui/EntenteGallerieTabUIController';
import DemandCard from 'src/presentation/components/GallerieTabs/DemandCard';
import DnaLoader from 'src/presentation/components/loader/DNALoader';

const EntenteGallerieTab = () => {
    const {sentDemands, receivedDemands, artistIdNotSet, loading, acceptDemand, refuseDemand } = EntenteGallerieTabUIController()
	const { t } = useTranslation();
	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center mt-4">
				<DnaLoader />
			</div>
		);
    }

    if (artistIdNotSet) {								
	return <Link className="nav-link" to={{
								pathname: "/zone-admin", 
								state:{tab:4}
								}} >
				<button className="btn btn-primary"> Voir profil d'artiste </button>
			</Link>
    }
	return (

		<>
            <div
                style={{
                    // paddingLeft: isMobile ? undefined : '38%',
                    textAlign: 'center',
                    alignItems: 'center',
                }}
            >
                <h3> {t<string>('zone_admin.entente.receivedDemands')} </h3>
            </div>
			{
				receivedDemands.length === 0 ? 
				<div className="d-flex justify-content-center align-items-center mt-4">
					<br/>
					<h4 style={{textAlign:'center'}}> {t<string>('zone_admin.entente.noReceivedDemands')} </h4>
				</div>
				:
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				{receivedDemands.map((item, idx) => {return <DemandCard demand={item} idx={idx} received={true} acceptDemand={acceptDemand} refuseDemand={refuseDemand}/>})}
			</div>
			}

            <div
                style={{
                    // paddingLeft: isMobile ? undefined : '38%',
                    textAlign: 'center',
                    alignItems: 'center',
                }}
            >
                <h3> {t<string>('zone_admin.entente.titleSentDemands')} </h3>
            </div>
			{
				sentDemands.length === 0 ? 
				<div className="d-flex justify-content-center align-items-center mt-4">
					<br/>
					<h4 style={{textAlign:'center'}}> {t<string>('zone_admin.entente.noSentDemands')} </h4>
				</div>
				:
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				{sentDemands.map((item, idx) => {return <DemandCard demand={item} idx={idx} received={false} acceptDemand={acceptDemand} refuseDemand={refuseDemand}/>})}
			</div>
			}
		</>
	);
};

export default EntenteGallerieTab;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
	authenticationService,
	metamaskAuthentication,
} from 'src/ApplicationContext';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import { MetaMaskProps } from 'src/presentation/components/Modals/LoginModal/MetaMaskComponent';
import { CreateMetaMaskUserRequest } from 'src/service/dto/request/CreateMetamaskUserRequest';
import { LoginMetamaskRequest } from 'src/service/dto/request/LoginMetamaskRequest';
import { SignatureRequest } from 'src/service/dto/Signature';

export const MetaMaskComponentController = (props: MetaMaskProps) => {
	const { message, public_key, email, handleClose } = props;

	const { t } = useTranslation();

	const [readyToCreateMetaMaskAccount, setReadyToCreateMetaMaskAccount] =
		useState<boolean>(false);
	const [signatureError, setSignatureError] = useState<string>();
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [emailvalidationError, setEmailvalidationError] = useState<string>('');
	const [validationError, setValidationError] = useState<string>('');
	const [signature, setSignature] = useState<string>('');

	const confirmSignature = async () => {
		const signatureResponse = await metamaskAuthentication.signMessage(message);
		const signatureRequest: SignatureRequest = {
			public_key: public_key,
			message: message,
			signature: signatureResponse.signature,
		};
		const signatureVerify = await metamaskAuthentication.verifySignature(
			signatureRequest
		);

		if (signatureVerify) {
			// Try login user
			await tryLoginUser(signatureRequest);
		} else {
			setSignatureError('Signature is not valid');
			setValidationError('Error while verifying signature');
		}
	};

	const tryLoginUser = async (signatureRequest: SignatureRequest) => {
		const loginWithMetamaskRequest: LoginMetamaskRequest = {
			metamaskCredentials: signatureRequest.public_key,
			signature: signatureRequest.signature,
			message: signatureRequest.message,
		};
		const loginRequest = await metamaskAuthentication.loginWithMetamask(
			loginWithMetamaskRequest
		);

		if (loginRequest.isLogin) {
			await authenticationService.postConnectionSessionStorageSetup(
				loginRequest,
				'metamask'
			);
			handleClose();
			toast.promise(async () => {}, {
				success: {
					render: t<string>('login.userLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
		} else {
			setSignature(signatureRequest.signature);
			setReadyToCreateMetaMaskAccount(true);
		}
	};
	const createMetaMaskUser = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		const createMetaMaskUser: CreateMetaMaskUserRequest = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			message: message,
			signature: signature,
			metamaskCredentials: public_key,
			role: 'Client',
			marketId: parseInt(applicationConfiguration.marketId) || 0,
			google_auth: false,
			isMetamask: true,
		};

		const registerUserResponse = await metamaskAuthentication.createMetaMaskAccount(
			createMetaMaskUser
		);

		if (registerUserResponse) {
			await authenticationService.postConnectionSessionStorageSetup(registerUserResponse,'metamask');
			handleClose();
			toast.promise(async () => {}, {
				success: {
					render: t<string>('login.userLogin'),
					position: toast.POSITION.BOTTOM_LEFT,
					pauseOnHover: false,
					theme: 'dark',
				},
			});
		} else {
			setEmailvalidationError(t('register.accountAlreadyExistError'));
		}
	};

	return {
		createMetaMaskUser,
		confirmSignature,
		lastName,
		setFirstName,
		firstName,
		setLastName,
		emailvalidationError,
		email,
		setEmailvalidationError,
		validationError,
		readyToCreateMetaMaskAccount,
		signatureError,
	};
};

import i18n from 'src/i18n';
import { getBackEndUrl } from 'src/env/SaasEnvironnement';
import { TokenResponse } from '@react-oauth/google';
import axios, { AxiosRequestConfig } from 'axios';
import { applicationConfiguration } from 'src/env/ApplicationConfiguration';
import Wallet from 'src/business/entities/Wallet';
import TokiesService from 'src/service/TokiesService';
import { LoginReponse } from 'src/service/dto/response/LoginResponse';
import { immutableProvider, storageService } from 'src/ApplicationContext';
import { RegisterUserRequest } from 'src/service/dto/request/RegisterUserRequest';
import { ChangePasswordRequest } from 'src/service/dto/request/ChangePasswordRequest';
import { TokiesGoogleAuthResponse } from 'src/service/dto/response/TokiesGoogleAuthResponse';
class AuthenticationService {
	backendUrl: string = getBackEndUrl();

	// TODO extraire ça dans un controller wtf
	passwordRegex: RegExp =
		/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

	passwordValidation(password: string, confirmPassword: string): string {
		if (password === '') {
			return i18n.t<string>('register.enterPassword');
		}
		if (password !== confirmPassword) {
			return i18n.t<string>('register.messageNotSamePassword');
		}

		if (!password.match(this.passwordRegex)) {
			return i18n.t<string>('register.passwordTooShort');
		}
		return '';
	}

	async connect_with_google(tokenResponse: TokenResponse) {
		const data = JSON.stringify({
			tokenAccess: tokenResponse.access_token,
			marketId: applicationConfiguration.marketId,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/google`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios(config);

			const results: TokiesGoogleAuthResponse = request?.data;

			if (results?.accessToken === undefined) {
				return false;
			}


			this.postGoogleConnectionSessionStorageSetup(results)
			return true;
		} catch {
			return false;
		}
	}

	async register_user(registerUserDto: RegisterUserRequest) {
		const data = JSON.stringify(registerUserDto);

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/register`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			await axios(config);
			return true;
		} catch (e) {
			return false;
		}
	}

	async resend_confirmation(email: string) {
		const data = JSON.stringify({
			email: email,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/resendConfirmation`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			await axios(config);
			return true;
		} catch (e) {
			return false;
		}
	}

	async confirm_user(email: string, code: string) {
		const data = JSON.stringify({
			email,
			code,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/confirmUser`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios(config);
			return request.data === 'SUCCESS';
		} catch (_e) {
			return false;
		}
	}

	async login_with_email(email: string, password: string) {
		const data = JSON.stringify({
			email: email,
			password: password,
		});

		const config: AxiosRequestConfig = {
			method: 'put',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/login`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios(config);
			const loginResponse: LoginReponse = request.data;

			this.postConnectionSessionStorageSetup(loginResponse)


			return true;
		} catch (e) {
			return false;
		}
	}

	async trigger_mfa_sign_in(email: string, password: string): Promise<boolean> {
		const data = JSON.stringify({
			email: email,
			password: password,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/mfa/login`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const response = await axios(config);
			return response.data.mfaRequired;
		} catch (e) {
			return false;
		}
	}

	async confirm_mfa_sign_in(email: string, code: string) {
		const data = JSON.stringify({
			email: email,
			code: code,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/mfa/confirm`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios(config);
			const loginResponse: LoginReponse = request.data;

			this.postConnectionSessionStorageSetup(loginResponse)


			return true;
		} catch (e) {
			return false;
		}
	}

	async logoutUser() {
		storageService.clear();
	}

	async sendCodeForForgetPassword(email: string) {
		const data = JSON.stringify({
			email,
		});

		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${this.backendUrl}/auth/aws/forgetPassword`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: data,
			};

			await axios.request(config);
			return true;
		} catch (e) {
			return false;
		}
	}

	async confirm_forgot_password(email: string, code: string, password: string) {
		const data = JSON.stringify({
			email,
			code,
			password,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/confirmForgetPassword`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			await axios.request(config);
			return true;
		} catch (e) {
			return false;
		}
	}

	async change_password(changePasswordDto: ChangePasswordRequest) {
		const { email, oldPassword, newPassword } = changePasswordDto;

		const data = JSON.stringify({
			email,
			oldPassword,
			newPassword,
		});

		const config: AxiosRequestConfig = {
			method: 'put',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/changePassword`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const response = await axios.request(config);
			return response.data === 'SUCCESS';
		} catch (e) {
			return false;
		}
	}

	public async postConnectionSessionStorageSetup(loginResponse: LoginReponse,connexionType:string='email') {
			const user_id = loginResponse.id;

			storageService.set_access_token(loginResponse.token);
			storageService.set_user_email(loginResponse.email);
			storageService.set_user_id(user_id.toString());
			storageService.set_connection_type(connexionType)
			await TokiesService.matchAndSetArtistId();
			
			const wallets: Wallet[] = await TokiesService.getWalletByUser(user_id.toString());
			if(wallets.length == 0 && connexionType=='metamask'){
				await immutableProvider.init();
				immutableProvider.connectMetamask(true).then(async () => 
				{
					const newWallet:any= {
						publicKey: storageService.get_wallet_address(),
						type: storageService.get_connection_type(),
						userId: +storageService.get_user_id(),
						connected: true,
					};
					await TokiesService.connectWallet(newWallet);
				});
			}
			const currentWallet = wallets.find((wallet: Wallet) => wallet.connected);
			if (currentWallet) {
				storageService.set_wallet_id(currentWallet.id.toString());
				storageService.set_wallet_address(currentWallet.publicKey);
				storageService.set_connected_wallet_type(currentWallet.type)
			}

	}

	private async postGoogleConnectionSessionStorageSetup(results: TokiesGoogleAuthResponse) {
			const time = parseInt(results.expiresIn) + new Date().getTime();
			const userId = results.id;

			storageService.set_access_token(results.accessToken);
			storageService.set_session_expire(time.toString());
			storageService.set_user_email(results.email);
			storageService.set_user_id(userId.toString());
			await TokiesService.matchAndSetArtistId();
			
			const wallets: Wallet[] = await TokiesService.getWalletByUser(userId.toString());

			const currentWallet = wallets.find((wallet: Wallet) => wallet.connected);
			if (currentWallet) {
				storageService.set_wallet_id(currentWallet.id.toString());
				storageService.set_wallet_address(currentWallet.publicKey);
				storageService.set_connected_wallet_type(currentWallet.type)
			}	

		}
	async enabled_mfa(email: string, password: string) {
		const data = JSON.stringify({
			email,
			password,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/mfa/enabled`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios.request(config);
			return request.data === 'SUCCESS';
		} catch {
			return false;
		}
	}

	async confirm_enabled_mfa(email: string, password: string, code: string) {
		const data = JSON.stringify({
			email,
			password,
			code,
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${this.backendUrl}/auth/aws/mfa/enabled/confirm`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		};

		try {
			const request = await axios.request(config);
			return request.data === 'SUCCESS';
		} catch {
			return false;
		}

	}
}

export default AuthenticationService;

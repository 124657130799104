import Header from 'src/presentation/components/Header/Header';
import ModalMenu from 'src/presentation/components/Modal/ModalMenu';
import Scrollup from 'src/presentation/components/Scrollup/Scrollup';
import ExploreP2P from 'src/presentation/components/Explore/ExploreP2P';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

const PeerToPeerScreen:FC<RouteComponentProps> = (): JSX.Element => {
		return (
			<div className="main">
				<Header />
				<ExploreP2P />
				<Scrollup />
				<ModalMenu />
			</div>
		);
}

export default PeerToPeerScreen;

interface Props {
    imageURL: string;
    isShowed: boolean;
    showViewer: (value: boolean) => void;
}

function Viewer({ imageURL, isShowed, showViewer }: Props): JSX.Element {
    return (
        <div
            className="align-items-center justify-content-center"
            style={{
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                zIndex: 20,
                position: "fixed",
                background: "rgba(0,0,0, 0.4)",
                backdropFilter: "blur(15px)",
                display: `${isShowed ? "flex" : "none"}`,
            }}
            onClick={() => {
                showViewer(false);
            }}
        >
            <img
                src={imageURL}
                style={{ height: "80%", maxWidth: "90%", objectFit: "contain" }}
            />
        </div>
    );
}

export default Viewer;

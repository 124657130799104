
interface Props {
	imageUrl?: {info?:string,url:string}
	titre: string,
	description: JSX.Element,
	onclick?: () => void
	index: any
}

const CardGeneral = ({ imageUrl,titre,description,onclick,index }: Props) => {
	return (
		<div key={`edth_${index}`}
			className="col-12 col-sm-6 col-lg-4 explore-item p-3"
		>
			<div className="card p-0">
				{(imageUrl.info!=='none') ?
				<a
					onClick={(e) => {
						e.preventDefault();
						onclick?  onclick() : console.log('ClICK on '&&{titre});
					}}
				>
					<div
						className="m-auto p-0 m-0"
						style={{
							height: '100%',
							width: '100%',
							overflow: 'hidden',
							position: 'relative',
						}}
					>
						<a>
							<img
								className="card-img-top"
								src={
									imageUrl.url ??
									'https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png'
								}
								style={{
									height: '100%',
									width: '100%',
									objectFit: 'cover',
									borderRadius: `${(imageUrl.info=='round')? '50%':''}`
								}}
								alt=""
							/>
						</a>
					</div>
				</a>
				:<></>

				}	
				<div className="card-caption col-12 p-0">
					<div className="card-body p-4">
						<h3 className="my-0 text-center ">
							{titre ? titre : 'null'}
						</h3>
						{description}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardGeneral;
